import React from 'react';
import {Form, Formik, FormikHelpers, useFormikContext} from 'formik';
import {Dialog, Transition} from '@headlessui/react';
import Scrollbars from 'react-custom-scrollbars';
import axios from 'axios';
import {uniq} from 'lodash';

import {tagSphere} from '../services/entry';
import {Alert} from './Alert';
import {Button} from './Button';
import {listSpheres} from '../services/sphere';
import {useSignedInAuth} from '../context/AuthProvider';
import {LoadingSpinner} from './LoadingSpinner';
import {CheckBoxInput} from './Inputs';

const collectSpheres = (spheres: AccessibleSphere[], result: Sphere[]) => {
  spheres.forEach((el) => {
    result.push(el);

    if (el.children.length > 0) {
      collectSpheres(el.children, result);
    }
  });
};

const PublicityAlert: React.FC<{
  availableSpheres: Sphere[] | null;
}> = ({availableSpheres}) => {
  const {initialValues, values} = useFormikContext<SphereTagsFormValues>();

  let spheres: Sphere[] = [];
  collectSpheres(availableSpheres as AccessibleSphere[], spheres);

  const initialSpheres = initialValues.sphereIds.map(
    (id) => spheres.find((sphere) => sphere.id.toString() === id)!,
  );
  const selectedSpheres = values.sphereIds.map(
    (id) => spheres.find((sphere) => sphere.id.toString() === id)!,
  );

  const changingPublicViewable =
    initialSpheres.filter((sphere) => sphere?.public).length === 0 &&
    selectedSpheres.filter((sphere) => sphere?.public).length > 0;

  return changingPublicViewable ? (
    <Alert type="info">
      This update will allow this entry to be displayed in public spheres.
    </Alert>
  ) : null;
};

const renderSphereItem = (
  profile: CurrentProfile,
  sphere: AccessibleSphere,
  parentSphereId: Id,
) => {
  const isParentSphere = parentSphereId === sphere.id;

  return (
    <li className="ml-4 divide-y" key={`sphere-${sphere.id}`}>
      {isParentSphere ? (
        <div className="mb-2">
          <label
            className={`flex items-center space-x-2 text-gray-500 leading-8 font-medium`}>
            <input type="checkbox" checked={true} disabled={true} />
            <span>{sphere.name}</span>
          </label>
        </div>
      ) : (
        <CheckBoxInput
          name="sphereIds"
          label={sphere.name}
          labelClasses="text-gray-500 leading-8 font-medium"
          className="mb-2"
          value={sphere.id.toString()}
        />
      )}

      {sphere.children.length > 0 && (
        <ul className="mb-8 divide-y">
          {sphere.children.map((childSphere) =>
            renderSphereItem(profile, childSphere, parentSphereId),
          )}
        </ul>
      )}
    </li>
  );
};

type SphereTagsModalProps = {
  entry: Entry;
  open: boolean;
  onClose: () => void;
  onCommit: (sphereTags: SphereTag[]) => void;
};

type SphereTagsFormValues = {
  sphereIds: string[];
};

export const SphereTagsModal: React.FC<SphereTagsModalProps> = ({
  entry,
  open,
  onClose,
  onCommit,
}) => {
  const [error, setError] = React.useState<string | null>(null);
  const [availableSpheres, setAvailableSpheres] = React.useState<
    Sphere[] | null
  >(null);
  const [sphereTags, setSphereTags] = React.useState<SphereTag[]>(
    entry.sphereTags,
  );
  const [initialValues, setInitialValues] = React.useState<{
    sphereIds: string[];
  }>({sphereIds: []});

  const {profile} = useSignedInAuth();

  const fetchAvailableSpheres = async () => {
    const response = await listSpheres();
    if (response.success) {
      setAvailableSpheres(response.spheres);
    } else {
      setError(response.errors[0].messages[0]);
    }
  };

  React.useEffect(() => {
    setError(null);
    setAvailableSpheres(null);
    setSphereTags(entry.sphereTags);
    fetchAvailableSpheres();
  }, [entry.id]);

  React.useEffect(() => {
    setInitialValues({
      sphereIds: sphereTags.map((tag) => tag.sphere.id.toString()),
    });
  }, [sphereTags]);

  const handleSubmit = async (
    values: SphereTagsFormValues,
    {setSubmitting}: FormikHelpers<SphereTagsFormValues>,
  ) => {
    setSubmitting(true);
    setError(null);

    const successes: SphereTag[] = [];
    for (let sphereId of values.sphereIds) {
      try {
        const response = await tagSphere(entry.id, parseInt(sphereId));
        if (response.success) {
          successes.push(response.sphereTag);
        } else {
          // TODO: better error handling
          console.log(response.errors);
          setError(response.errors[0].messages[0]);
          break;
        }
      } catch (error) {
        // TODO: better error handling
        if (axios.isAxiosError(error) && error.response) {
          setError(error.response.data.errors.messages[0]);
          break;
        } else {
          setError('Something went wrong. Please try again later.');
          break;
        }
      }
    }

    onCommit(successes);
    setSubmitting(false);
    setSphereTags(uniq([...sphereTags, ...successes]));
  };

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog onClose={() => onClose()} className="fixed z-50 inset-0">
        <Scrollbars>
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="w-full relative bg-white rounded-lg max-w-lg w-full mx-4 sm:mx-auto">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize>
                  <Form>
                    <div className="flex items-start space-x-4 pt-6 px-6 pb-4">
                      <div className="flex-grow">
                        <Dialog.Title className="text-lg mb-2">
                          Edit Sphere Connections
                        </Dialog.Title>

                        {error !== null && <Alert type="error">{error}</Alert>}

                        {availableSpheres === null ? (
                          <LoadingSpinner />
                        ) : (
                          <ul className="mb-8">
                            {availableSpheres?.map((sphere) =>
                              renderSphereItem(
                                profile,
                                sphere as AccessibleSphere,
                                entry.sphereId,
                              ),
                            )}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="mx-6">
                      <PublicityAlert availableSpheres={availableSpheres} />
                    </div>

                    <div className="flex justify-end rounded-b-lg bg-gray-50 py-2 px-6 space-x-3">
                      <Button
                        type="button"
                        style="default"
                        onClick={() => onClose()}>
                        Close
                      </Button>

                      <Button type="submit" style="root">
                        Save Sphere Connection
                      </Button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Scrollbars>
      </Dialog>
    </Transition>
  );
};
