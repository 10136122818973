import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ComponentBlock} from './ComponentBlock';
import {SphereList} from './SphereList';
import {Button} from './Button';
import {leaveSphere} from '../services/sphere';
import {pluralize} from '../utils';
import {useSignedInAuth} from '../context/AuthProvider';
import {useNav} from '../context/NavProvider';
import {useSphereRole} from '../context/SphereProvider';
import {SphereButton} from './SphereButton';
import Scrollbars from 'react-custom-scrollbars';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

const MyNavigatorToggle: React.FC<{
  icon: IconProp;
  label: string;
  roles: SphereAccessRole[];
}> = ({icon, label, roles}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <div className="flex flex-col space-y-2">
      <div
        className="flex flex-row justify-between space-x-4 items-center font-medium text-gray-400 cursor-pointer"
        onClick={() => {
          setOpen(!open);
        }}>
        <div className="flex flex-row items-center space-x-1">
          <FontAwesomeIcon className="w-5" icon={icon} />
          <span>{label}</span>
          <span>-</span>
          <span>{roles.length}</span>
        </div>

        {open ? (
          <FontAwesomeIcon icon="angle-up" />
        ) : (
          <FontAwesomeIcon icon="angle-down" />
        )}
      </div>

      {open && (
        <div className="flex flex-col h-60 space-y-2 py-2 pb-4 md:py-0 md:space-y-4 md:max-w-lg">
          <Scrollbars height="100%">
            <div className="flex flex-col space-y-2">
              {roles.map((role) => (
                <SphereButton
                  key={`role-${role.id}-sphere-list-${role.sphere.id}`}
                  sphere={role.sphere}
                />
              ))}
            </div>
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

const MySpheresNavigator: React.FC = () => {
  const {profile} = useSignedInAuth();
  const {navigatorOpen} = useNav();

  const mobileClass = navigatorOpen ? 'flex' : 'hidden';

  const joinedSpheres = profile.spheres.filter(
    (r) => r.name !== 'Owner' && r.sphere.public,
  );
  const mySpheres = profile.spheres.filter(
    (r) => r.name === 'Owner' && r.sphere.public,
  );
  const personalSpheres = profile.spheres.filter(
    (r) => r.name === 'Owner' && !r.sphere.public,
  );
  const sharedSpheres = profile.spheres.filter(
    (r) => r.name !== 'Owner' && !r.sphere.public,
  );

  return (
    <div
      className={`${mobileClass} fixed h-full inset-0 pt-16 md:pt-20 lg:static md:w-auto md:flex flex-col md:space-y-6`}>
      <ComponentBlock className="flex-grow pb-32 lg:pb-5 overflow-y-auto">
        <h2 className="flex space-x-2 text-xl font-semibold items-center">
          <span>My Spheres</span>
        </h2>

        <div className="flex flex-col space-y-3">
          <MyNavigatorToggle
            icon="right-to-bracket"
            label="Joined Spheres"
            roles={joinedSpheres}
          />
          <MyNavigatorToggle
            icon="home"
            label="My Public Spheres"
            roles={mySpheres}
          />
          <MyNavigatorToggle
            icon="lock"
            label="My Personal Spheres"
            roles={personalSpheres}
          />
          <MyNavigatorToggle
            icon="users"
            label="Shared With Me"
            roles={sharedSpheres}
          />
        </div>
      </ComponentBlock>

      <div className="h-5"></div>
    </div>
  );
};

const AtSphereNavigator: React.FC<{sphere: AccessibleSphere}> = ({sphere}) => {
  const navigate = useNavigate();
  const {profile, setProfile} = useSignedInAuth();
  const {navigatorOpen} = useNav();

  const {getRole, showJoinModal, getJoinRequest} = useSphereRole();

  const mobileClass = navigatorOpen ? 'flex' : 'hidden';

  return (
    <div
      className={`${mobileClass} fixed h-full inset-0 pt-16 md:pt-20 lg:static md:w-auto md:flex flex-col md:space-y-6`}>
      <ComponentBlock className="flex flex-col flex-grow pb-32 lg:pb-5 space-y-3 overflow-y-auto pr-8">
        <h2 className="flex space-x-2 text-xl font-semibold items-center">
          <span>{sphere.name}</span>
        </h2>

        <div className="flex flex-row text-gray-400 text-sm leading-5 font-medium space-x-3 no-break">
          <div className="space-x-1">
            <FontAwesomeIcon icon="users" />
            <span>
              {sphere.memberCount}{' '}
              {pluralize('Collaborator', sphere.memberCount)}
            </span>
          </div>

          <div className="space-x-1">
            <FontAwesomeIcon icon="file" />
            <span>
              {sphere.entryCount} {pluralize('Entry', sphere.entryCount)}
            </span>
          </div>
        </div>

        {sphere.parentId && (
          <div className="flex flex-row space-x-2 text-xs">
            <span>Categorized under</span>
            <Link
              className="flex flex-row space-x-1 items-center font-bold text-gray-500"
              to={`/spheres/${sphere.parentSlug}`}>
              <span className="underline">{sphere.parentName}</span>
              <FontAwesomeIcon icon="right-from-bracket" />
            </Link>
          </div>
        )}

        <div className="flex space-x-4">
          {getJoinRequest(sphere.id)?.status === 'approved' ? (
            getRole(sphere.id) !== undefined &&
            sphere.id !== 1 &&
            getRole(sphere.id)?.name !== 'Owner' && (
              <Button
                type="button"
                style="problem"
                onClick={async () => {
                  if (
                    window.confirm(
                      'Are you sure you want to leave this sphere?',
                    )
                  ) {
                    await leaveSphere(sphere.slug);
                    setProfile({
                      ...profile,
                      spheres: profile.spheres.filter(
                        (r) => r.resourceId !== sphere.id,
                      ),
                      joinRequests: profile.joinRequests.filter(
                        (r) => r.sphereId !== sphere.id,
                      ),
                    });
                  }
                }}>
                Leave
              </Button>
            )
          ) : (
            <Button
              type="button"
              style={
                getJoinRequest(sphere.id) !== undefined
                  ? getJoinRequest(sphere.id)?.status === 'pending'
                    ? 'default'
                    : 'problem'
                  : 'root'
              }
              disabled={getJoinRequest(sphere.id) !== undefined}
              onClick={async () => {
                showJoinModal(sphere.slug);
              }}>
              {getJoinRequest(sphere.id) !== undefined
                ? getJoinRequest(sphere.id)?.status === 'pending'
                  ? 'Pending'
                  : 'Rejected'
                : 'Join'}
            </Button>
          )}

          <div className="flex flex-row space-x-4">
            {(getRole(sphere.id)?.name === 'Moderator' ||
              getRole(sphere.id)?.name === 'Owner') && (
              <Button
                type="button"
                style="default"
                onClick={() => {
                  navigate(`/spheres/${sphere.slug}/manage`);
                }}>
                Manage Sphere
              </Button>
            )}
          </div>
        </div>

        {(!sphere.exclusive || getRole(sphere.id) !== undefined) && (
          <SphereList sphere={sphere} />
        )}
      </ComponentBlock>

      <div className="h-5"></div>
    </div>
  );
};

export const SphereNavigator: React.FC<{sphere?: AccessibleSphere}> = ({
  sphere,
}) => {
  return sphere ? (
    <AtSphereNavigator sphere={sphere} />
  ) : (
    <MySpheresNavigator />
  );
};
