import React from 'react';
import {Base} from './base';

interface ListSpheresResponse {
  success: true;
  spheres: AccessibleSphere[];
}

interface AccessibleSphereResponse {
  success: true;
  sphere: AccessibleSphere;
}

interface RestrictedSphereresponse {
  success: true;
  sphere: RestrictedSphere;
}

interface DeleteSphereResponse {
  success: boolean;
}

interface RoleResponse {
  success: true;
  role: SphereAccessRole;
}

export const listSpheres = async () =>
  (await Base.get<ListSpheresResponse | ErrorResponse>(`/spheres`)).data;

export const fetchSphere = async (slug: string) => {
  const data = (
    await Base.get<
      AccessibleSphereResponse | RestrictedSphereresponse | ErrorResponse
    >(`/spheres/${slug}?public=true`)
  ).data;
  if (!data.success) {
    return data;
  }

  return data;
};

interface CreateSphereInput {
  name: string;
  description: string;
  parentId?: Id;
  public: boolean;
}

export const createSphere = async (sphere: CreateSphereInput) =>
  (
    await Base.post<AccessibleSphereResponse | ErrorResponse>('/spheres', {
      sphere,
    })
  ).data;

interface UpdateSphereInput {
  name?: string;
  featured?: boolean;
  description?: string | null;
  autoApproveUsers?: boolean;
}

export const updateSphere = async (id: Id, sphere: UpdateSphereInput) =>
  (
    await Base.patch<AccessibleSphereResponse | ErrorResponse>(
      `/spheres/${id}`,
      {sphere},
    )
  ).data;

export const deleteSphere = async (id: Id) =>
  (await Base.delete<DeleteSphereResponse>(`/spheres/${id}`)).data;

interface InviteSphereUserInput {
  email: string;
  role: AccessRole;
}

export const inviteSphereUser = async (
  id: Id,
  invitation: InviteSphereUserInput,
) =>
  (
    await Base.post<AccessibleSphereResponse | ErrorResponse>(
      `/spheres/${id}/invitations`,
      {
        invitation,
      },
    )
  ).data;

export const deleteSphereInvitation = async (id: Id, invitationId: Id) =>
  (
    await Base.delete<AccessibleSphereResponse | ErrorResponse>(
      `/spheres/${id}/invitations/${invitationId}`,
    )
  ).data;

export const removeSphereUser = async (id: string, userId: Id) =>
  (
    await Base.delete<AccessibleSphereResponse | ErrorResponse>(
      `/spheres/${id}/users/${userId}`,
    )
  ).data;

export const approveJoinRequest = async (sphereId: string, joinRequestId: Id) =>
  (
    await Base.patch<SphereUserResponse | ErrorResponse>(
      `/spheres/${sphereId}/join_requests/${joinRequestId}`,
      {
        joinRequest: {
          status: 'approved',
        },
      },
    )
  ).data;

export const rejectJoinRequest = async (sphereId: string, joinRequestId: Id) =>
  (
    await Base.patch<SphereUserResponse | ErrorResponse>(
      `/spheres/${sphereId}/join_requests/${joinRequestId}`,
      {
        joinRequest: {
          status: 'declined',
        },
      },
    )
  ).data;

export const leaveSphere = async (sphereId: string) =>
  (
    await Base.delete<RoleResponse | ErrorResponse>(
      `/spheres/${sphereId}/sphere_user`,
    )
  ).data;

interface SphereUsersResponse {
  success: true;
  users: SphereProfile[];
}

export const fetchSphereUsers = async (sphereSlug: string) =>
  (
    await Base.get<SphereUsersResponse | ErrorResponse>(
      `/spheres/${sphereSlug}/users`,
    )
  ).data;

export const useSphereUsers = (sphereSlug: string) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<Profile[]>([]);

  React.useEffect(() => {
    setLoading(true);

    fetchSphereUsers(sphereSlug)
      .then((response) => {
        if (response.success) {
          setUsers(response.users);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setUsers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sphereSlug]);

  return {loading, users};
};

interface SphereUserResponse {
  success: true;
  user: SphereProfile;
}

export const updateProfileSphereRole = async (
  sphereSlug: string,
  userId: Id,
  roleId: Id,
) =>
  (
    await Base.patch<SphereUserResponse | ErrorResponse>(
      `/spheres/${sphereSlug}/users/${userId}`,
      {
        roleId,
      },
    )
  ).data;
