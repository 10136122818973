import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useSignedInAuth} from '../context/AuthProvider';
import {fetchSphere} from '../services/sphere';
import {getSphereRole} from '../utils';
import {Button} from '../components/Button';
import {LoadingSpinner} from '../components/LoadingSpinner';
import {CreateRoleModal} from '../components/CreateRoleModal';
import {EditableRoleList} from '../components/EditableRoleList';

export const ManageSphereRolesScreen: React.FC = () => {
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const {slug} = useParams<{slug: string}>();

  const [sphere, setSphere] = React.useState<AccessibleSphere | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [createModalOpen, setCreateModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (slug === undefined) {
      setLoading(false);
      return;
    }

    setLoading(true);
    fetchSphere(slug)
      .then((response) => {
        if (response.success) {
          const sphere = response.sphere as AccessibleSphere;
          setSphere(sphere);
        } else {
          // TODO: gracefully handle errors
          setSphere(null);
        }
      })
      .catch(() => {
        // TODO: gracefully handle errors
        setSphere(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);

  if (loading || !sphere) {
    return <LoadingSpinner />;
  }

  const sphereRole = getSphereRole(profile, sphere.id);
  if (sphereRole?.name !== 'Owner') {
    navigate(`/spheres/${slug}/manage`);
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row space-x-4 items-center justify-between">
        <h3 className="text-lg mb-4">Sphere Roles</h3>
        <Button
          type="button"
          onClick={() => {
            setCreateModalOpen(true);
          }}>
          New Role
        </Button>
      </div>

      <EditableRoleList sphere={sphere} />

      <CreateRoleModal
        open={createModalOpen}
        sphere={sphere}
        onCommit={(newRole) => {
          const withUsersCount = newRole as AccessRole & {usersCount: number};
          withUsersCount.usersCount = 0;
          setSphere({...sphere, roles: [...sphere.roles, withUsersCount]});
          navigate({hash: newRole.name});
          setCreateModalOpen(false);
        }}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      />
    </div>
  );
};
