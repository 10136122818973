import React from 'react';

import {useSignedInAuth} from '../context/AuthProvider';
import {LoadingSpinner} from './LoadingSpinner';
import {pluralize} from '../utils';
import {Avatar} from './Avatar';
import {followProfile, unfollowProfile} from '../services/follow';
import Scrollbars from 'react-custom-scrollbars';
import {Button} from './Button';

const CollaboratorListItem: React.FC<{profile: Profile}> = ({profile}) => {
  const {
    profile: currentProfile,
    setProfile: setCurrentProfile,
  } = useSignedInAuth();
  const following = currentProfile.followings.find((f) => f.id === profile.id);

  return (
    <div className="flex flex-row items-center justify-between space-x-1 lg:space-x-4 p-3 border-b border-gray-400">
      <Avatar profile={profile} size="md" />

      <div className="self-start">
        <span>{profile.username}</span>
      </div>

      <Button
        type="button"
        style="default"
        className={`py-1 px-2 text-white text-xs rounded cursor-pointer`}
        onClick={async () => {
          try {
            if (following !== undefined) {
              const response = await unfollowProfile(
                profile.id,
                following.followId,
              );
              setCurrentProfile(response.profile);
            } else {
              const response = await followProfile(profile.id);
              setCurrentProfile(response.profile);
            }
          } catch (err) {
            console.log(err);
          }
        }}>
        {following !== undefined ? 'Unfollow' : 'Follow'}
      </Button>
    </div>
  );
};

export const CollaboratorList: React.FC<{
  loading: boolean;
  profiles: Profile[];
}> = ({loading, profiles}) => {
  return (
    <div className="flex flex-col space-y-2 h-full py-2 md:py-0 md:space-y-4 md:max-w-lg">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h2 className="text-block-900 font-bold text-lg">
            {profiles.length} {pluralize('Collaborator', profiles.length)}
          </h2>

          <Scrollbars>
            <div className="space-y-6 pr-4">
              {profiles.map((other) => (
                <CollaboratorListItem profile={other} key={other.username} />
              ))}
            </div>
          </Scrollbars>
        </>
      )}
    </div>
  );
};
