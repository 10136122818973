import {Link} from 'react-router-dom';
import {Section} from './Section';

export const ManageSphereSidebar: React.FC<{sphereSlug: string}> = ({
  sphereSlug,
}) => {
  return (
    <Section>
      <ul className="divide-y -my-2">
        <li className="py-2">
          <Link to={`/spheres/${sphereSlug}/manage`}>General</Link>
        </li>
        <li className="py-2">
          <Link to={`/spheres/${sphereSlug}/manage/roles`}>Permissions</Link>
        </li>
        <li className="py-2">
          <Link to={`/spheres/${sphereSlug}/manage/users`}>Collaborators</Link>
        </li>
        {sphereSlug === 'enolve' && (
          <li className="py-2">
            <Link to={`/spheres/${sphereSlug}/manage/plans`}>Plans</Link>
          </li>
        )}
        <li className="py-2">
          <Link to={`/spheres/${sphereSlug}/manage/subspheres`}>
            Subspheres
          </Link>
        </li>
      </ul>
    </Section>
  );
};
