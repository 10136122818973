import {Base} from './base';

interface ListPlansResponse {
  plans: Plan[];
  success: true;
}

interface PlanResponse {
  plan: Plan;
  success: true;
}

export const listPlans = async (sphereSlug: string) =>
  (await Base.get<ListPlansResponse | ErrorResponse>(`/plans`)).data;

export const createPlan = async (plan: {
  name: string;
  description: string;
  amount: string;
}) =>
  (
    await Base.post<PlanResponse | ErrorResponse>('/plans', {
      plan,
    })
  ).data;

export const updatePlan = async (id: Id, plan: Plan) =>
  (await Base.patch<PlanResponse | ErrorResponse>(`/plans/${id}`, {plan})).data;
