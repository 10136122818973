import React from 'react';

import {useSignedInAuth} from '../context/AuthProvider';
import {Section} from '../components/Section';
import {Button} from '../components/Button';
import {
  createPaymentMethodToken,
  unsubscrtibe,
  updatePaymentMethod,
} from '../services/subscription';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import toast from 'react-hot-toast';

const PaymentUpdateForm: React.FC<{provideToken: (token: string) => {}}> = ({
  provideToken,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [error, setError] = React.useState<string | null>(null);

  const onSubmit = async () => {
    if (elements === null || stripe === null) {
      return;
    }

    setError(null);

    try {
      const token = await createPaymentMethodToken(stripe, elements);
      provideToken(token);
    } catch (error) {
      setError(error as string);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      {error && <p className="text-problem text-sm">{error}</p>}

      <CardElement />

      <Button type="button" style="root" onClick={onSubmit}>
        Update Payment Method
      </Button>
    </div>
  );
};

export const ManageSubscriptionScreen: React.FC = () => {
  const {profile, setProfile, showSubscriptionModal} = useSignedInAuth();

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

  const onSubmitPaymentUpdate = async (token: string) => {
    try {
      const res = await updatePaymentMethod(token);
      if (res.success) {
        toast.success('Your payment method has been updated.');
      } else {
        // todo: handle errors
      }
    } catch (err) {
      // todo: handle errors
    }
  };

  return (
    <Section className="mx-auto w-1/3 space-y-6 mt-20">
      {profile.subscription?.active ? (
        <>
          <Elements stripe={stripe}>
            <PaymentUpdateForm provideToken={onSubmitPaymentUpdate} />
          </Elements>

          <Button
            type="button"
            style="problem"
            onClick={async () => {
              if (
                window.confirm(
                  'Are you sure you want to cancel your subscription?',
                )
              ) {
                const response = await unsubscrtibe();
                if (response.success) {
                  setProfile(response.profile);
                }
              }
            }}>
            Cancel Subscription
          </Button>
        </>
      ) : (
        <Button
          type="button"
          style="root"
          onClick={() => {
            showSubscriptionModal();
          }}>
          Subscribe
        </Button>
      )}
    </Section>
  );
};
