import {Base} from './base';

interface CheckUsernameResponse {
  success: true;
  available: boolean;
}

interface CurrentProfileResponse {
  success: true;
  profile: CurrentProfile;
}

export interface UserInput {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password?: string;
}

export const checkUsername = async (username: string) =>
  (await Base.get<CheckUsernameResponse>(`/usernames/${username}`)).data;

export const createUser = async (user: UserInput, firebaseToken?: string) =>
  (
    await Base.post<CurrentProfileResponse>('/users', {
      user,
      firebaseToken,
    })
  ).data;

export const updateUser = async (user: UserInput) =>
  await Base.patch<CurrentProfileResponse>('/current/user', {user});
