import {Base} from './base';

interface ListEntriesResponse {
  success: true;
  entries: Entry[];
  currentPage: number;
  pages: number;
  count: number;
}

interface EntryResponse {
  success: true;
  entry: Entry;
}

interface ViewResponse {
  success: true;
}

const buildEntryIndexUrl = (sphereId?: string, terminalGoalId?: Id) => {
  let url = '';
  if (sphereId !== undefined) {
    url += `/spheres/${sphereId}`;
  }

  if (terminalGoalId !== undefined) {
    url += `/terminal_goals/${terminalGoalId}`;
  }

  return `${url}/entries`;
};

export const listEntries = async (
  page: number,
  sphereId?: string,
  terminalGoalId?: Id,
  includePublic?: boolean,
) => {
  let pub =
    includePublic === undefined
      ? ''
      : includePublic
      ? '&public=true'
      : '&public=false';

  const response = await Base.get<ListEntriesResponse | ErrorResponse>(
    `${buildEntryIndexUrl(sphereId, terminalGoalId)}?page=${page}${pub}`,
  );

  return {
    ...response.data,
    currentPage: parseInt(response.headers['current-page']),
    pages: parseInt(response.headers['total-pages']),
    count: parseInt(response.headers['total-count']),
  };
};

export const searchEntries = async (
  sphereId: string,
  query: string,
  type?: EntryType | EntryType[],
) =>
  (
    await Base.get<ListEntriesResponse | ErrorResponse>(
      `/spheres/${sphereId}/entries`,
      {params: {query, type}},
    )
  ).data;

export const fetchEntry = async (entryId: number) =>
  (await Base.get<EntryResponse | ErrorResponse>(`/entries/${entryId}`)).data;

export interface CreateEntryInput {
  title: string;
  content: string;
  type: EntryType;
  parentsIds?: Id[];
  childrenIds?: Id[];
}

export const createEntry = async (
  sphereId: string,
  entry: CreateEntryInput,
) => {
  const response = await Base.post<EntryResponse | ErrorResponse>(
    `/spheres/${sphereId}/entries`,
    {entry},
  );
  return response.data;
};

export interface UpdateEntryInput {
  title?: string;
  content?: string;
  parentsIds?: Id[];
  childrenIds?: Id[];
  terminalGoalsIds?: Id[];
}

export const updateEntry = async (
  sphereId: string,
  entryId: Id,
  entry: UpdateEntryInput,
) => {
  const response = await Base.patch<EntryResponse | ErrorResponse>(
    `/spheres/${sphereId}/entries/${entryId}`,
    {entry},
  );
  return response.data;
};

export const deleteEntry = async (sphereId: string, entryId: Id) =>
  (await Base.delete(`/spheres/${sphereId}/entries/${entryId}`)).data;

export const createView = async (entryId: Id) =>
  (await Base.post<ViewResponse | ErrorResponse>(`/entries/${entryId}/view`))
    .data;

export const tagSphere = async (entryId: Id, sphereId: Id) =>
  (
    await Base.post<{sphereTag: SphereTag; success: true} | ErrorResponse>(
      '/sphere_tags',
      {
        sphereTag: {
          sphereId,
          entryId,
        },
      },
    )
  ).data;

export const assignSphereTags = async (entryId: Id, sphereIds: Id[]) =>
  (
    await Base.post<EntryResponse | ErrorResponse>(
      `/entries/${entryId}/sphere_tags`,
      {
        sphereIds,
      },
    )
  ).data;

interface EntryConnectionResponse {
  entryConnection: {
    id: number;
    parent: Entry;
    child: Entry;
    user: Profile;
  };
  success: true;
}

interface EntryConnectionsResponse {
  connections: EntryConnection[];
  success: true;
}

export const createEntryConnection = async (childId: Id, parentId: Id) =>
  (
    await Base.post<EntryConnectionResponse | ErrorResponse>(
      '/entry_connections',
      {
        parentId,
        childId,
      },
    )
  ).data;

export const removeEntryConnection = async (id: Id) =>
  (await Base.delete(`/entry_connections/${id}`)).data;

export const fetchEntryConnection = async (id: Id) =>
  (
    await Base.get<EntryConnectionResponse | ErrorResponse>(
      `/entry_connections/${id}`,
    )
  ).data;

export const listEntryConnections = async (entryId: Id) =>
  (
    await Base.get<EntryConnectionsResponse | ErrorResponse>(
      `/entries/${entryId}/connections`,
    )
  ).data;
