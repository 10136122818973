import React from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import logo from '../assets/img/logo-icon.png';
import {useAuth} from '../context/AuthProvider';
import {SignInForm} from '../components/SignInForm';
import {SignUpForm} from '../components/SignUpForm';
import {Button} from '../components/Button';
import {UserInput} from '../services/user';

export enum AuthFormState {
  SignIn,
  SignUp,
}

export interface AuthScreenLocationState {
  action?: AuthFormState;
  initialValues?: UserInput;
}

export const AuthScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = React.useState<String | null>(null);
  const [formState, setFormState] = React.useState<AuthFormState>(
    location.state?.action || AuthFormState.SignIn,
  );

  const toggleFormState = () => {
    setFormState(
      formState === AuthFormState.SignIn
        ? AuthFormState.SignUp
        : AuthFormState.SignIn,
    );
  };

  const {signInFacebook, signInGoogle} = useAuth();

  return (
    <div className="max-w-md w-full space-y-8">
      <div>
        <img className="mx-auto h-24 w-auto" src={logo} alt="enolve" />

        <h1 className="text-center text-2xl font-bold text-gray-900">
          {formState === AuthFormState.SignIn
            ? 'Sign in to your account'
            : 'Create Your Profile'}
        </h1>
      </div>

      <div className="border border-gray-200 p-8 rounded-lg shadow-lg">
        {formState === AuthFormState.SignIn ? (
          <SignInForm />
        ) : (
          <SignUpForm initialValues={location.state?.initialValues} />
        )}

        <hr className="my-4" />

        <div className="flex flex-col space-y-4">
          <Button
            style={formState === AuthFormState.SignIn ? 'problem' : 'solution'}
            type="button"
            onClick={() => toggleFormState()}>
            {formState === AuthFormState.SignIn
              ? 'Create an Account'
              : 'Sign In'}
          </Button>

          <button
            className="flex-1 items-center text-gray-400 border border-gray-300 px-4 py-2 space-x-2 text-white rounded"
            onClick={async () => {
              try {
                await signInFacebook();
                navigate('/');
              } catch (error: any) {
                setError(error.message);
              }
            }}>
            <FontAwesomeIcon icon={['fab', 'facebook']} />
            <span>Sign in with Facebook</span>
          </button>

          <button
            className="flex-1 items-center text-gray-400 border border-gray-300 px-4 py-2 space-x-2 text-white rounded"
            onClick={async () => {
              try {
                await signInGoogle();
                navigate('/');
              } catch (error: any) {
                setError(error.message);
              }
            }}>
            <FontAwesomeIcon icon={['fab', 'google']} />
            <span>Sign in with Google</span>
          </button>
        </div>

        <hr className="my-4" />

        <Link
          className="font-medium text-indigo-600 hover:text-indigo-500"
          to="/auth/forgot-password">
          Forgot your password?
        </Link>
      </div>
    </div>
  );
};
