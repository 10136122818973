import React from 'react';
import {Transition} from '@headlessui/react';

import {InputClassName} from '../components/Inputs';
import {
  updateProfileSphereRole,
  fetchSphereUsers,
  removeSphereUser,
  approveJoinRequest,
  rejectJoinRequest,
  updateSphere,
} from '../services/sphere';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {useSignedInAuth} from '../context/AuthProvider';
import {getSphereRole} from '../utils';
import {Button} from '../components/Button';

import solutionIcon from '../assets/img/solution-icon.svg';
import problemIcon from '../assets/img/problem-icon.svg';
import {ChangeToggle} from '../components/Inputs/Toggle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface RoleComponentProps {
  userId: Id;
  roleId: Id;
  sphereSlug: string;
  roles: AccessRole[];
  onUpdate: (user: SphereProfile) => void;
}

const RoleComponent: React.FC<RoleComponentProps> = ({
  userId,
  roleId,
  sphereSlug,
  roles,
  onUpdate,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean | null>(null);

  const updateRole = async (newRole: Id) => {
    setLoading(true);
    setSuccess(null);
    try {
      const response = await updateProfileSphereRole(
        sphereSlug,
        userId,
        newRole,
      );

      if (response.success) {
        onUpdate(response.user);
        setSuccess(true);
      }
    } catch (e) {
      // TODO: add error handling
      console.log(e);
      window.alert(
        'There was an error updating this users role. Please try again later.',
      );
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      <select
        className={InputClassName}
        value={roleId}
        onChange={async (evt) => {
          updateRole(parseInt(evt.target.value));
        }}>
        {roles.map((role, i) => (
          <option key={`${userId}-role-${i}`} value={role.id}>
            {role.name}
          </option>
        ))}
      </select>

      <div className="w-6">
        <Transition
          show={success === true}
          enter="transition-opacity duration-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={() => setSuccess(null)}>
          <img src={solutionIcon} className="w-6 h-6" />
        </Transition>
      </div>
    </div>
  );
};

export const ManageSphereCollaboratorsScreen: React.FC = () => {
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const [users, setUsers] = React.useState<SphereProfile[]>([]);
  const [joinRequests, setJoinRequests] = React.useState<JoinRequest[]>([]);
  const [autoApprove, setAutoApprove] = React.useState<boolean>(false);

  const [sphere] = useOutletContext<[AccessibleSphere]>();

  React.useEffect(() => {
    setJoinRequests([]);
    setUsers([]);
    setAutoApprove(false);

    if (sphere !== null && !sphere.restricted) {
      setJoinRequests(sphere.joinRequests);
      setAutoApprove(sphere.autoApproveUsers);
      fetchSphereUsers(sphere.slug).then((response) => {
        if (response.success) {
          setUsers(response.users);
        }
      });
    }
  }, [sphere]);

  const sphereRole = getSphereRole(profile, sphere.id);
  if (sphere.restricted || sphereRole?.name !== 'Owner') {
    navigate(`/spheres/${sphere.slug}/manage`);
    return null;
  }

  console.log(joinRequests);

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-4 md:justify-between md:items-center">
          <h3 className="text-lg">Join Requests</h3>

          <div className="flex flex-row space-x-2">
            <span className="text-sm">Auto approve new join requests?</span>
            <ChangeToggle
              value={autoApprove}
              onChange={(newValue) => {
                updateSphere(sphere.id, {autoApproveUsers: newValue});
                setAutoApprove(newValue);
              }}
            />
          </div>
        </div>

        <div className="shadow-md rounded-lg">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                  Username
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {joinRequests.length > 0 ? (
                joinRequests.map((joinRequest) => (
                  <tr key={`join-request-${joinRequest.id}`}>
                    <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {joinRequest.profile.username}
                    </td>
                    <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                      <div className="flex justify-end space-x-2">
                        <Button
                          type="button"
                          style="root"
                          onClick={async () => {
                            try {
                              const response = await approveJoinRequest(
                                sphere.slug,
                                joinRequest.id,
                              );
                              if (response.success) {
                                setUsers([response.user, ...users]);
                                setJoinRequests(
                                  joinRequests.filter(
                                    (jr) => jr.id !== joinRequest.id,
                                  ),
                                );
                              } else {
                                // TODO: handle errors
                              }
                            } catch (e) {
                              // TODO: handle errors
                            }
                          }}>
                          Approve
                        </Button>

                        <Button
                          type="button"
                          style="problem"
                          onClick={async () => {
                            try {
                              const response = await rejectJoinRequest(
                                sphere.slug,
                                joinRequest.id,
                              );
                              if (response.success) {
                                setJoinRequests(
                                  joinRequests.filter(
                                    (jr) => jr.id !== joinRequest.id,
                                  ),
                                );
                              } else {
                                // TODO: handle errors
                              }
                            } catch (e) {
                              // TODO: handle errors
                            }
                          }}>
                          Reject
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                    colSpan={2}>
                    No new join requests.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <h3 className="text-lg mb-4">Collaborators</h3>

        <div className="shadow-md rounded-lg">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                  Username
                </th>
                <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                  Role
                </th>
                <th className="w-20"></th>
              </tr>
            </thead>

            <tbody>
              {users.map((user) => {
                return (
                  <tr key={`sphere-user-${user.id}`}>
                    <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {user.username}
                    </td>
                    <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {sphereRole.name === 'Owner' && user.id !== profile.id ? (
                        <RoleComponent
                          userId={user.id}
                          roleId={user.roleId}
                          sphereSlug={sphere.slug}
                          roles={sphere.roles.filter(
                            (r) => r.name !== 'Visitor',
                          )}
                          onUpdate={(updatedUser) => {
                            setUsers(
                              users.map((u) => {
                                if (u.id === updatedUser.id) {
                                  return updatedUser;
                                }

                                return u;
                              }),
                            );
                          }}
                        />
                      ) : (
                        sphere.roles.find((r) => r.id === user.roleId)?.name
                      )}
                    </td>
                    <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap w-20">
                      {(sphereRole.name === 'Owner' ||
                        (sphereRole.name === 'Moderator' &&
                          sphere.roles.find((r) => r.id === user.roleId)
                            ?.name === 'User')) &&
                        user.id !== profile.id && (
                          <FontAwesomeIcon
                            icon="close"
                            className="w-8 h-8 cursor-pointer text-red-400"
                            onClick={async () => {
                              if (
                                window.confirm(
                                  'Are you sure you want to remove this user from the sphere?',
                                )
                              ) {
                                try {
                                  const response = await removeSphereUser(
                                    sphere.slug,
                                    user.id,
                                  );

                                  if (response.success) {
                                    // setSphere(response.sphere);
                                  } else {
                                    // TODO: error handling
                                    console.log(response.errors);
                                  }
                                } catch (e) {
                                  // TODO: error handling
                                  console.log(e);
                                }
                              }
                            }}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
