import React from 'react';
import {uniqBy} from 'lodash';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  createView,
  fetchEntry,
  listEntryConnections,
  removeEntryConnection,
} from '../services/entry';

import {useSphere} from '../context/SphereProvider';
import {LoadingSpinner} from '../components/LoadingSpinner';
import {pluralize} from '../utils';
import {Avatar} from '../components/Avatar';
import {
  EntryChildrenCreateLabels,
  EntryChildrenGuidingQuestions,
  EntryChildrenLabels,
  EntryClasses,
  EntryIcons,
} from '../utils/entry';
import {SphereTagsModal} from '../components/SphereTagsModal';
import {EntryContextMenu} from '../components/Entry/EntryContextMenu';
import {
  CreateEntryModal,
  CreateEntryModalState,
} from '../components/CreateEntryModal';

import logoProblem from '../assets/img/logo-problem.svg';
import logoSolution from '../assets/img/logo-solution.svg';
import logoTerminalGoal from '../assets/img/logo-terminal-goal.svg';
import solutionIcon from '../assets/img/solution-icon.svg';
import rootCauseIcon from '../assets/img/root-causes-icon.svg';
import {EntryChildren} from '../components/Entry/EntryChildren';
import Scrollbars from 'react-custom-scrollbars';
import {useVote} from '../hooks/votes';
import {fetchSphere} from '../services/sphere';
import {useSignedInAuth} from '../context/AuthProvider';

const EntryLogos = {
  problem: logoProblem,
  solution: logoSolution,
  terminal_goal: logoTerminalGoal,
};

const EntryTexts = {
  problem: 'text-problem',
  solution: 'text-solution',
  terminal_goal: 'text-terminal-goal',
};

const Entry: React.FC<{entry: Entry; sphere: AccessibleSphere}> = ({
  entry: providedEntry,
  sphere,
}) => {
  const [
    createEntryModalState,
    setCreateEntryModalState,
  ] = React.useState<CreateEntryModalState>({
    open: false,
    sphereId: providedEntry.sphereSlug,
    toConnect: providedEntry,
    initialType: 'problem',
    parent: false,
  });
  const [showSphereTagsModal, setShowSphereTagsModal] = React.useState(false);
  const [entry, setEntry] = React.useState<Entry>(providedEntry);
  const [connections, setConnections] = React.useState<EntryConnection[]>([]);
  const {
    upvoteCount,
    downvoteCount,
    upvote,
    downvote,
    currentUserVote,
  } = useVote(entry);

  const location = useLocation();
  const navigate = useNavigate();
  const {profile} = useSignedInAuth();

  React.useEffect(() => {
    setEntry(providedEntry);
    listEntryConnections(providedEntry.id).then((response) => {
      if (response.success) {
        setConnections(response.connections);
      }
    });
  }, [providedEntry.id]);

  const terminalGoals = connections
    .filter((conn) => {
      if (entry.type === 'terminal_goal') {
        return (
          conn.relationship === 'parent' && conn.entry.type === 'terminal_goal'
        );
      } else {
        return (
          conn.relationship === 'child' && conn.entry.type === 'terminal_goal'
        );
      }
    })
    .map((conn) => conn.entry);

  const rootCauses = connections
    .filter(
      (conn) =>
        conn.relationship === 'child' && conn.entry.type !== 'terminal_goal',
    )
    .map((conn) => conn.entry);

  const downsides = connections
    .filter(
      (conn) => conn.relationship === 'child' && conn.entry.type === 'problem',
    )
    .map((conn) => conn.entry);

  const greaterValues = connections
    .filter(
      (conn) =>
        conn.relationship === 'child' && conn.entry.type === 'terminal_goal',
    )
    .map((conn) => conn.entry);

  const subProblems = connections
    .filter(
      (conn) => conn.relationship === 'parent' && conn.entry.type == 'problem',
    )
    .map((conn) => conn.entry);

  const solutions = connections
    .filter(
      (conn) => conn.relationship === 'parent' && conn.entry.type == 'solution',
    )
    .map((conn) => conn.entry);

  return (
    <>
      <div className="flex flex-col bg-white h-screen">
        <nav className={`${EntryClasses[entry.type]} z-50`}>
          <div className="flex items-center justify-between h-16 px-2 md:px-4 space-x-2 sm:space-x-3">
            <FontAwesomeIcon
              icon="arrow-left"
              className={`cursor-pointer text-lg ${EntryTexts[entry.type]}`}
              onClick={() => {
                location.key !== 'default' ? navigate(-1) : navigate('/');
              }}
            />

            <Link to="/">
              <img
                className="h-10 w-auto m-auto"
                src={EntryLogos[entry.type]}
                alt="enolve"
              />
            </Link>

            <div className={`${EntryTexts[entry.type]}`}>
              <EntryContextMenu entry={entry} onEdit={() => null} />
            </div>
          </div>
        </nav>

        <div
          className={`flex flex-col ${
            EntryClasses[entry.type]
          }-light py-3 px-3 md:px-16 mb-4`}>
          <Scrollbars style={{height: '3rem'}} className="mb-8 py-2 px-4">
            <div className="flex flex-row space-x-2 md:justify-center items-center">
              {entry.sphereTags.map((tag) => (
                <Link
                  className="bg-white border border-gray-400 p-2 rounded-3xl text-sm whitespace-nowrap"
                  key={tag.sphere.slug}
                  to={`/spheres/${tag.sphere.slug}`}>
                  {tag.sphere.name}
                </Link>
              ))}

              <span
                className="cursor-pointer space-x-1 items-center bg-white border border-gray-400 p-2 rounded-3xl text-sm whitespace-nowrap"
                onClick={() => setShowSphereTagsModal(true)}>
                <FontAwesomeIcon icon="plus-circle" className="text-gray-400" />
                <span>Add Spheres</span>
              </span>
            </div>
          </Scrollbars>

          <div className="flex items-center space-x-4 mb-2 mx-auto">
            <div className="flex flex-col text-gray-900 text-sm text-right">
              <div>
                Problem reported by{' '}
                <span className="font-semibold">{entry.author.username}</span>
              </div>

              <div>
                developed by {sphere.memberCount}{' '}
                {pluralize('collaborator', sphere.memberCount)}
              </div>
            </div>

            <Avatar size="md" profile={entry.author} />
          </div>

          <div className="flex flex-row items-center justify-center space-x-10 mb-2 text-dark-gray text-xs">
            <div className="space-x-1">
              <FontAwesomeIcon icon="chart-bar" />
              <span>
                {entry.viewCount} {pluralize('View', entry.viewCount)}
              </span>
            </div>

            <div className="space-x-1">
              <FontAwesomeIcon icon="calendar" />
              <span>{entry.createdAt}</span>
            </div>
          </div>

          <div className="flex flex-row items-center space-x-2 mx-auto">
            <img src={EntryIcons[entry.type]} className="w-16 h-16" />
            <div className="text-3xl font-semibold">{entry.title}</div>
          </div>
        </div>

        <div
          className={`${
            EntryClasses[entry.type]
          } border-l-4 pt-4 pl-5 pr-5 pb-4 flex flex-col justify-between flex-grow shadow-sm mx-4 mb-4 md:w-full md:max-w-md md:mx-auto`}
          style={{minWidth: '20rem'}}>
          <div className="flex items-start flex-row space-x-5">
            <div className="flex flex-col bg-white divide-y py-2 px-1 items-center text-xs font-medium text-gray-500 border border-gray-300 rounded">
              <button
                className="cursor-pointer px-1"
                onClick={async (event) => {
                  event.preventDefault();
                  await upvote();
                }}>
                {currentUserVote?.upvote ? (
                  <FontAwesomeIcon icon="play" rotation={270} />
                ) : (
                  <FontAwesomeIcon icon="chevron-up" />
                )}

                <span>{upvoteCount}</span>
              </button>

              <button
                className="cursor-pointer px-1"
                onClick={async (event) => {
                  event.preventDefault();
                  await downvote();
                }}>
                {currentUserVote?.upvote === false ? (
                  <FontAwesomeIcon icon="play" rotation={90} />
                ) : (
                  <FontAwesomeIcon icon="chevron-down" />
                )}
                <span>{downvoteCount}</span>
              </button>
            </div>

            <div className="flex flex-col flex-grow text-gray-900 text-md whitespace-normal">
              {entry.content}
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col space-y-4 px-4 mx-auto mb-8 font-medium text-sm leading-5 text-dark-gray md:w-full md:max-w-md">
          <div className="flex flex-row space-x-4 justify-between items-center">
            <div className="flex flex-row space-x-1 items-center">
              <FontAwesomeIcon icon="comment-dots" />
              <span className="whitespace-nowrap">
                {entry.comments.length}{' '}
                {pluralize('Comment', entry.comments.length)}
              </span>
            </div>

            <div className="flex flex-row space-x-1 items-center">
              <FontAwesomeIcon icon="share-nodes" />
              <span className="whitespace-nowrap">14 Saves &amp; Shares</span>
            </div>
          </div>

          <div className="flex flex-row space-x-4 justify-between items-center">
            <div className="flex flex-row space-x-1 items-center">
              <img src={rootCauseIcon} className="h-4" />
              <span className="whitespace-nowrap">
                {
                  entry.connections.filter((c) => c.relationship === 'child')
                    .length
                }{' '}
                {pluralize(
                  'Root Cause',
                  entry.connections.filter((c) => c.relationship === 'child')
                    .length,
                )}
              </span>
            </div>

            <div className="flex flex-row space-x-1 items-center">
              <FontAwesomeIcon icon="scale-unbalanced" />
              <span className="whitespace-nowrap">
                {
                  entry.connections.filter((c) => c.relationship === 'parent')
                    .length
                }{' '}
                {pluralize(
                  'Values & Downside',
                  entry.connections.filter((c) => c.relationship === 'parent')
                    .length,
                )}
              </span>
            </div>
          </div>
        </div> */}

        <div className="flex flex-col space-y-9">
          {entry.type === 'solution' && (
            <EntryChildren
              entry={entry}
              connections={connections}
              title={`${rootCauses.length} ${pluralize(
                'Solved Problem',
                rootCauses.length,
              )}`}
              guidingQuestion={
                EntryChildrenGuidingQuestions[entry.type]['root_cause']
              }
              icon={rootCauseIcon}
              entries={rootCauses.filter((rc) => rc.type === 'problem')}
              createLabel={'Solved Problem'}
              create={() =>
                setCreateEntryModalState({
                  ...createEntryModalState,
                  initialType: 'problem',
                  open: true,
                  parent: true,
                })
              }
              onDisconnect={async (id) => {
                try {
                  await removeEntryConnection(id);
                } catch (error) {
                  // TODO: handle errors
                  console.log(error);
                }

                setEntry({
                  ...entry,
                  connections: connections.filter((c) => c.id !== id),
                });
              }}
            />
          )}

          {(entry.type === 'problem' || entry.type === 'terminal_goal') && (
            <EntryChildren
              entry={entry}
              connections={connections}
              title={EntryChildrenLabels[entry.type]['solution'](
                solutions.length,
              )}
              guidingQuestion={
                EntryChildrenGuidingQuestions[entry.type]['solution']
              }
              icon={solutionIcon}
              entries={solutions}
              createLabel={EntryChildrenCreateLabels[entry.type]['solution']}
              create={() =>
                setCreateEntryModalState({
                  ...createEntryModalState,
                  initialType: 'solution',
                  open: true,
                  parent: false,
                })
              }
              onDisconnect={async (id) => {
                try {
                  await removeEntryConnection(id);
                } catch (error) {
                  // TODO: handle errors
                  console.log(error);
                }

                setEntry({
                  ...entry,
                  connections: connections.filter((c) => c.id !== id),
                });
              }}
            />
          )}

          <EntryChildren
            entry={entry}
            connections={connections}
            title={EntryChildrenLabels[entry.type]['terminal_goal'](
              terminalGoals.length,
            )}
            guidingQuestion={
              EntryChildrenGuidingQuestions[entry.type]['terminal_goal']
            }
            entries={terminalGoals}
            createLabel={EntryChildrenCreateLabels[entry.type]['terminal_goal']}
            create={() => {
              setCreateEntryModalState({
                ...createEntryModalState,
                initialType: 'terminal_goal',
                parent: entry.type !== 'terminal_goal',
                open: true,
              });
            }}
            onDisconnect={async (id) => {
              try {
                await removeEntryConnection(id);
              } catch (error) {
                // TODO: handle errors
                console.log(error);
              }

              setEntry({
                ...entry,
                connections: connections.filter((c) => c.id !== id),
              });
            }}
          />

          <EntryChildren
            entry={entry}
            connections={connections}
            title={EntryChildrenLabels[entry.type]['problem'](
              subProblems.length,
            )}
            guidingQuestion={
              EntryChildrenGuidingQuestions[entry.type]['problem']
            }
            icon={EntryIcons['problem']}
            entries={subProblems}
            createLabel={EntryChildrenCreateLabels[entry.type]['problem']}
            create={() =>
              setCreateEntryModalState({
                ...createEntryModalState,
                initialType: 'problem',
                open: true,
                parent: false,
              })
            }
            onDisconnect={async (id) => {
              try {
                await removeEntryConnection(id);
              } catch (error) {
                // TODO: handle errors
                console.log(error);
              }

              setEntry({
                ...entry,
                connections: connections.filter((c) => c.id !== id),
              });
            }}
          />

          {entry.type === 'terminal_goal' && (
            <>
              <EntryChildren
                entry={entry}
                connections={connections}
                title={EntryChildrenLabels[entry.type]['root_cause'](
                  greaterValues.length,
                )}
                guidingQuestion={
                  EntryChildrenGuidingQuestions[entry.type]['root_cause']
                }
                icon={rootCauseIcon}
                entries={greaterValues}
                createLabel={
                  EntryChildrenCreateLabels[entry.type]['root_cause']
                }
                create={() =>
                  setCreateEntryModalState({
                    ...createEntryModalState,
                    initialType: 'terminal_goal',
                    open: true,
                    parent: true,
                  })
                }
                onDisconnect={async (id) => {
                  try {
                    await removeEntryConnection(id);
                  } catch (error) {
                    // TODO: handle errors
                    console.log(error);
                  }

                  setEntry({
                    ...entry,
                    connections: connections.filter((c) => c.id !== id),
                  });
                }}
              />

              <EntryChildren
                entry={entry}
                connections={connections}
                title={EntryChildrenLabels[entry.type]['root_cause_problem'](
                  downsides.length,
                )}
                guidingQuestion={
                  EntryChildrenGuidingQuestions[entry.type][
                    'root_cause_problem'
                  ]
                }
                icon={rootCauseIcon}
                entries={downsides}
                createLabel={
                  EntryChildrenCreateLabels[entry.type]['root_cause_problem']
                }
                create={() =>
                  setCreateEntryModalState({
                    ...createEntryModalState,
                    initialType: 'problem',
                    open: true,
                    parent: true,
                  })
                }
                onDisconnect={async (id) => {
                  try {
                    await removeEntryConnection(id);
                  } catch (error) {
                    // TODO: handle errors
                    console.log(error);
                  }

                  setEntry({
                    ...entry,
                    connections: connections.filter((c) => c.id !== id),
                  });
                }}
              />
            </>
          )}

          {entry.type === 'problem' && (
            <EntryChildren
              entry={entry}
              connections={connections}
              title={`${rootCauses.length} ${pluralize(
                'Root Cause',
                rootCauses.length,
              )}`}
              guidingQuestion={
                EntryChildrenGuidingQuestions[entry.type]['root_cause']
              }
              icon={rootCauseIcon}
              entries={rootCauses}
              createLabel={'Root Cause'}
              create={() =>
                setCreateEntryModalState({
                  ...createEntryModalState,
                  initialType: undefined,
                  open: true,
                  parent: true,
                })
              }
              onDisconnect={async (id) => {
                try {
                  await removeEntryConnection(id);
                } catch (error) {
                  // TODO: handle errors
                  console.log(error);
                }

                setEntry({
                  ...entry,
                  connections: connections.filter((c) => c.id !== id),
                });
              }}
            />
          )}
        </div>
      </div>

      <SphereTagsModal
        entry={entry}
        open={showSphereTagsModal}
        onClose={() => setShowSphereTagsModal(false)}
        onCommit={(newSphereTags) => {
          setShowSphereTagsModal(false);
          setEntry({
            ...entry,
            sphereTags: uniqBy(
              [...entry.sphereTags, ...newSphereTags],
              (el) => el.sphere.id,
            ),
          });
        }}
      />

      <CreateEntryModal
        state={createEntryModalState}
        onClose={() =>
          setCreateEntryModalState({...createEntryModalState, open: false})
        }
        onCommit={(newEntry, connectionId) => {
          setCreateEntryModalState({...createEntryModalState, open: false});
          let relationship: 'parent' | 'child' = 'parent';
          if (
            createEntryModalState.parent ||
            newEntry.type === 'terminal_goal'
          ) {
            relationship = 'child';
          } else {
            relationship = 'parent';
          }

          setConnections([
            ...connections,
            {
              entry: newEntry,
              id: connectionId!,
              connector: profile,
              relationship,
            },
          ]);
        }}
      />
    </>
  );
};

export const EntryScreen: React.FC = () => {
  const [entry, setEntry] = React.useState<Entry | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [sphere, setSphere] = React.useState<
    AccessibleSphere | RestrictedSphere | null
  >(null);

  const {slug, id} = useParams<{slug: string; id: string}>();

  React.useEffect(() => {
    setLoading(true);

    Promise.all([
      createView(parseInt(id as string)),
      fetchEntry(parseInt(id as string))
        .then((response) => {
          if (response.success) {
            setEntry(response.entry);
          } else {
            // TODO: gracefully handle errors
            setEntry(null);
          }
        })
        .catch(() => {
          // TODO: gracefully handle errors
          setEntry(null);
        }),
      fetchSphere(slug || 'enolve')
        .then((response) => {
          if (response.success) {
            setSphere(response.sphere);
          } else {
            setSphere(null);
          }
        })
        .catch(() => {
          setSphere(null);
        }),
    ]).finally(() => {
      setLoading(false);
    });
  }, [id]);

  if (loading || entry === null || sphere === null || sphere.restricted) {
    return (
      <div className="bg-white px-2 md:pr-10 py-20 space-y-8">
        <LoadingSpinner />
      </div>
    );
  }

  return <Entry entry={entry} sphere={sphere} />;
};
