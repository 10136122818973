import React from 'react';

import {createVote, deleteVote} from '../services/vote';

export const useVote = (voteable: Voteable) => {
  const [currentUserVote, setCurrentUserVote] = React.useState(
    voteable.currentUserVote,
  );
  const [upvoteCount, setUpvoteCount] = React.useState(voteable.upvoteCount);
  const [downvoteCount, setDownvoteCount] = React.useState(
    voteable.downvoteCount,
  );

  const upvote = async () => {
    if (currentUserVote !== undefined) {
      if (!currentUserVote.upvote) {
        await createVote(voteable.id, 1);

        setCurrentUserVote({upvote: true});
        setUpvoteCount(upvoteCount + 1);
        setDownvoteCount(downvoteCount - 1);
      } else {
        await deleteVote(voteable.id);

        setCurrentUserVote(undefined);
        setUpvoteCount(upvoteCount - 1);
      }
    } else {
      await createVote(voteable.id, 1);

      setCurrentUserVote({upvote: true});
      setUpvoteCount(upvoteCount + 1);
    }
  };

  const downvote = async () => {
    if (currentUserVote !== undefined) {
      if (currentUserVote.upvote) {
        await createVote(voteable.id, -1);

        setCurrentUserVote({upvote: false});
        setUpvoteCount(upvoteCount - 1);
        setDownvoteCount(downvoteCount + 1);
      } else {
        await deleteVote(voteable.id);

        setCurrentUserVote(undefined);
        setDownvoteCount(downvoteCount - 1);
      }
    } else {
      await createVote(voteable.id, -1);

      setCurrentUserVote({upvote: false});
      setDownvoteCount(downvoteCount + 1);
    }
  };

  return {upvoteCount, downvoteCount, upvote, downvote, currentUserVote};
};
