import {Base} from './base';

interface VoteResponse {
  success: true;
}

export const createVote = async (entryId: Id, delta: -1 | 1) =>
  (
    await Base.post<VoteResponse | ErrorResponse>(`/entries/${entryId}/vote`, {
      vote: {delta},
    })
  ).data;

export const deleteVote = async (entryId: Id) =>
  (await Base.delete<VoteResponse | ErrorResponse>(`/entries/${entryId}/vote`))
    .data;
