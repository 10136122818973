import React from 'react';
import {useNavigate} from 'react-router-dom';

type TagProps = {
  children: React.ReactNode;
  className?: string;
  href?: string;
};

export const Tag: React.FC<TagProps> = ({children, className, href}) => {
  const navigate = useNavigate();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (href !== undefined) {
      navigate(href);
    }
  };

  className = `${
    className || ''
  } py-1 px-3 rounded-full text-xs text-gray-400 whitespace-nowrap border border-gray-400 text-center ${
    href !== undefined && 'cursor-pointer'
  }`;

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};
