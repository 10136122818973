import {Link, useLocation} from 'react-router-dom';

import {
  EntryIcons,
  EntryClasses,
  EntryBorders,
  EntryBgDarkClasses,
} from '../../utils/entry';
import {EntryAuthor} from './EntryAuthor';
import {Tag} from '../Tag';
import {Metadatum} from './Metadatum';

import metaProblem from '../../assets/img/meta-problem.svg';
import metaRootCauses from '../../assets/img/meta-root-causes.svg';
import metaSolution from '../../assets/img/meta-solution.svg';
import metaSolvedProblems from '../../assets/img/meta-solved-problems.svg';
import metaTerminalGoals from '../../assets/img/meta-terminal-goals.svg';
import {pluralize} from '../../utils';
import Scrollbars from 'react-custom-scrollbars';
import React from 'react';

interface FeedEntryProps {
  entry: Entry;
  showSphereName?: boolean;
}

export const FeedEntry: React.FC<FeedEntryProps> = ({
  entry,
  showSphereName,
}) => {
  const location = useLocation();

  return (
    <Link
      className={`flex flex-col cursor-pointer shadow-lg max-w-full rounded-lg ${
        EntryBgDarkClasses[entry.type]
      } pl-2`}
      to={`/entries/${entry.id}`}
      state={{background: location}}>
      <div className="bg-white rounded-r-lg">
        <div className="flex flex-col space-y-4 py-3 px-5">
          <EntryAuthor entry={entry} />

          <Scrollbars style={{height: '2rem'}}>
            <div className="flex flex-row items-start justify-start space-x-1 md:space-x-2">
              {showSphereName === true &&
                entry.sphereTags
                  .slice(0, 3)
                  .map((tag) => <Tag key={tag.id}>{tag.sphere.name}</Tag>)}
            </div>
          </Scrollbars>
        </div>

        <div className={`${EntryClasses[entry.type]} mb-2 py-1 px-5`}>
          <div className="flex flex-row items-center space-x-3">
            <img
              src={EntryIcons[entry.type]}
              alt={entry.type}
              className="w-10"
            />
            <span className="text-gray-900 font-semibold text-xl">
              {entry.title}
            </span>
          </div>
        </div>

        <div className="flex flex-col py-3 px-5">
          <p className="text-gray-900 text-base mb-4">{entry.content}</p>

          <div className="flex justify-between items-center space-x-2 md:space-x-4 mb-3">
            {entry.type === 'problem' && (
              <Metadatum
                iconSrc={metaSolution}
                value={`${entry.solutionsCount} ${pluralize(
                  'Solution',
                  entry.solutionsCount,
                )}`}
              />
            )}
            {entry.type === 'solution' && (
              <Metadatum
                iconSrc={metaSolvedProblems}
                value={`${entry.rootProblemsCount} ${pluralize(
                  'Solved Problem',
                  entry.rootProblemsCount,
                )}`}
              />
            )}
            {entry.type === 'terminal_goal' && (
              <Metadatum
                iconSrc={metaSolution}
                value={`${entry.solutionsCount} ${pluralize(
                  'Solution',
                  entry.solutionsCount,
                )}`}
              />
            )}
            <Metadatum
              faIcon="comment-dots"
              value={`${entry.comments.length} ${pluralize(
                'Comment',
                entry.comments.length,
              )}`}
            />
          </div>

          <div className="flex justify-between items-center space-x-2 md:space-x-4">
            {entry.type === 'problem' && (
              <>
                <Metadatum
                  iconSrc={metaRootCauses}
                  value={`${
                    entry.rootProblemsCount + entry.rootSolutionsCount
                  } ${pluralize(
                    'Root Cause',
                    entry.rootProblemsCount + entry.rootSolutionsCount,
                  )}`}
                />
                <Metadatum
                  faIcon="scale-unbalanced"
                  value={`${
                    entry.valueGoalsCount + entry.problemsCount
                  } ${pluralize(
                    'Values & Downside',
                    entry.valueGoalsCount + entry.rootProblemsCount,
                  )}`}
                />
              </>
            )}
            {entry.type === 'solution' && (
              <>
                <Metadatum
                  iconSrc={metaTerminalGoals}
                  value={`${entry.valueGoalsCount} ${pluralize(
                    'Value Goal',
                    entry.valueGoalsCount,
                  )}`}
                />
                <Metadatum
                  faIcon="scale-unbalanced"
                  value={`${entry.problemsCount} ${pluralize(
                    'Downside',
                    entry.problemsCount,
                  )}`}
                />
              </>
            )}
            {entry.type === 'terminal_goal' && (
              <>
                <Metadatum
                  iconSrc={metaRootCauses}
                  value={`${entry.rootProblemsCount} ${pluralize(
                    'Needs & Obstacle',
                    entry.rootProblemsCount,
                  )}`}
                />
                <Metadatum
                  faIcon="scale-unbalanced"
                  value={`${
                    entry.valueGoalsCount + entry.problemsCount
                  } ${pluralize(
                    'Values & Downside',
                    entry.valueGoalsCount + entry.rootProblemsCount,
                  )}`}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
