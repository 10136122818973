import {Base} from './base';

export interface CreateRoleInput {
  name: string;
}

export interface UpdateRoleInput {
  name?: string;
  canModerateContent?: boolean;
  canModerateOwnContent?: boolean;
  canCreatePublicSpheres?: boolean;
  canCreatePrivateSpheres?: boolean;
}

interface AccessRoleResponse {
  role: AccessRole;
  success: true;
}

export const createRole = async (sphereSlug: string, role: CreateRoleInput) =>
  (
    await Base.post<AccessRoleResponse | ErrorResponse>(
      `/spheres/${sphereSlug}/roles`,
      {role},
    )
  ).data;

export const updateRole = async (
  sphereSlug: string,
  id: Id,
  role: UpdateRoleInput,
) =>
  (
    await Base.patch<AccessRoleResponse | ErrorResponse>(
      `/spheres/${sphereSlug}/roles/${id}`,
      {role},
    )
  ).data;

export const deleteRole = async (sphereSlug: string, id: Id) =>
  (await Base.delete(`/spheres/${sphereSlug}/roles/${id}`)).data;
