import React from 'react';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';

import {Button} from '../components/Button';
import {getSphereRole} from '../utils';
import {useSignedInAuth} from '../context/AuthProvider';
import {LoadingSpinner} from '../components/LoadingSpinner';
import {listPlans, updatePlan} from '../services/plan';
import {ChangeToggle} from '../components/Inputs/Toggle';
import {InputField, TextArea} from '../components/Inputs';
import {Form, Formik} from 'formik';
import {CreatePlanModal} from '../components/CreatePlanModal';
import toast from 'react-hot-toast';

export const ManageSpherePlansScreen: React.FC = () => {
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const {slug} = useParams<{slug: string}>();

  const [sphere] = useOutletContext<[AccessibleSphere]>();

  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (slug === undefined) {
      return;
    }

    setPlans([]);
    setLoading(true);

    listPlans(slug)
      .then((response) => {
        if (response.success) {
          setPlans(response.plans);
          if (selectedPlan === null) {
            setSelectedPlan(response.plans[0]);
          }
        } else {
          // TOOD: handle errors
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);

  const sphereRole = getSphereRole(profile, sphere.id);
  if (sphereRole?.name !== 'Owner') {
    navigate(`/spheres/${slug}/manage`);
    return null;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row space-x-4 items-center justify-between">
        <h3 className="text-lg mb-4">Sphere Plans</h3>
        <Button type="button" onClick={() => setShowCreateModal(true)}>
          New Plan
        </Button>
      </div>

      <div className="flex flex-row space-x-4">
        <div className="flex flex-col divide-y w-full md:w-1/4">
          {plans.map((plan) => (
            <div
              className={`flex justify-between p-2 cursor-pointer ${
                selectedPlan?.id === plan.id && 'bg-root text-white'
              }`}
              key={`plan-${plan.id}`}
              onClick={() => setSelectedPlan(plan)}>
              <span>{plan.name}</span>
              <span
                className={`${
                  selectedPlan?.id === plan.id
                    ? 'text-gray-100 font-mono'
                    : 'text-gray-400 font-mono'
                }`}>
                {plan.amount}
              </span>
            </div>
          ))}
        </div>

        <div className="flex flex-col w-full md:w-3/4 px-4 divide-y -mt-2">
          {selectedPlan && (
            <Formik
              initialValues={selectedPlan}
              enableReinitialize={true}
              onSubmit={async (values) => {
                // await submitForm(values);
                const res = await updatePlan(selectedPlan.id, values);
                if (res.success) {
                  setSelectedPlan(res.plan);
                  setPlans(
                    plans
                      .filter((p) => p.id !== res.plan.id)
                      .concat([res.plan]),
                  );
                } else {
                  // tooo: handle rerrors
                }
              }}>
              {({values, setFieldValue}) => (
                <Form>
                  <div className="flex flex-col space-y-4 mb-4">
                    <InputField type="text" name="name" label="Name" />

                    <TextArea name="description" label="Description" />
                  </div>

                  <div className="flex flex-row justify-between items-start space-x-2 py-2">
                    <div className="flex-grow">
                      <h3 className="font-bold">Create Personal Subspheres</h3>
                      <p>
                        User can create private spheres that are not listed on
                        the main sphere
                      </p>
                    </div>

                    {values.createPersonalSphere !== null ? (
                      <InputField
                        type="number"
                        label="10"
                        placeholder
                        name="createPersonalSphere"
                      />
                    ) : (
                      <div className="text-base font-italic">Unlimited</div>
                    )}

                    <ChangeToggle
                      onChange={() => {
                        setFieldValue(
                          'createPersonalSphere',
                          values.createPersonalSphere === null ? 0 : null,
                        );
                      }}
                      value={values.createPersonalSphere === null}
                    />
                  </div>

                  {/* <div className="flex flex-row justify-between items-start space-x-2 py-2">
                    <div className="flex-grow">
                      <h3 className="font-bold">
                        Collaborators On Personal Subspheres
                      </h3>
                      <p>User can invite others to their private sphere</p>
                    </div>

                    {values.personalSphereCollaborators !== null ? (
                      <InputField
                        type="number"
                        label="10"
                        placeholder
                        name="personalSphereCollaborators"
                      />
                    ) : (
                      <div className="text-base font-italic">Unlimited</div>
                    )}

                    <ChangeToggle
                      onChange={() => {
                        setFieldValue(
                          'personalSphereCollaborators',
                          values.personalSphereCollaborators === null
                            ? 0
                            : null,
                        );
                      }}
                      value={values.personalSphereCollaborators === null}
                    />
                  </div> */}

                  <div className="flex flex-row justify-between items-start space-x-2 py-2">
                    <div className="flex-grow">
                      <h3 className="font-bold">Create Exclusive Subspheres</h3>
                      <p>
                        User can create private spheres that are listed on the
                        main sphere
                      </p>
                    </div>

                    {values.createExclusiveSphere !== null ? (
                      <InputField
                        type="number"
                        label="10"
                        placeholder
                        name="createExclusiveSphere"
                      />
                    ) : (
                      <div className="text-base font-italic">Unlimited</div>
                    )}

                    <ChangeToggle
                      onChange={() => {
                        setFieldValue(
                          'createExclusiveSphere',
                          values.createExclusiveSphere === null ? 0 : null,
                        );
                      }}
                      value={values.createExclusiveSphere === null}
                    />
                  </div>

                  <div className="flex flex-row justify-between items-start space-x-2 py-2 mb-4">
                    <div className="flex-grow">
                      <h3 className="font-bold">Join Exclusive Subspheres</h3>
                      <p>
                        User can join private spheres that are listed on the
                        main sphere
                      </p>
                    </div>

                    {values.joinExclusiveSphere !== null ? (
                      <InputField
                        type="number"
                        label="10"
                        placeholder
                        name="joinExclusiveSphere"
                      />
                    ) : (
                      <div className="text-base font-italic">Unlimited</div>
                    )}

                    <ChangeToggle
                      onChange={() => {
                        setFieldValue(
                          'joinExclusiveSphere',
                          values.joinExclusiveSphere === null ? 0 : null,
                        );
                      }}
                      value={values.joinExclusiveSphere === null}
                    />
                  </div>

                  <div className="flex flex-row justify-end">
                    <Button type="submit" style="root">
                      Save Plan
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>

      <CreatePlanModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onCommit={(plan) => {
          setPlans([...plans, plan]);
          setSelectedPlan(plan);
          setShowCreateModal(false);
          toast.success('Your plan has been created.');
        }}
      />
    </div>
  );
};
