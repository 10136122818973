import React from 'react';

interface ChangeToggleProps {
  onChange: (value: boolean) => void;
  value: boolean;
  disabled?: boolean;
}

export const ChangeToggle: React.FC<ChangeToggleProps> = ({
  onChange,
  value,
  disabled,
}) => {
  const [checked, setChecked] = React.useState<boolean>(value);

  React.useEffect(() => {
    if (value !== checked) {
      onChange(checked);
    }
  }, [checked]);

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  const classNames = [];
  if (disabled) {
    classNames.push('bg-gray-500');
  } else if (checked) {
    classNames.push('bg-root');
  } else {
    classNames.push('bg-gray-200');
  }

  return (
    <div onClick={() => !disabled && setChecked(!checked)}>
      <label
        htmlFor="default-toggle"
        className={`inline-flex relative items-center ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}>
        <input type="checkbox" className="sr-only" checked={checked} readOnly />

        <div
          className={`w-11 h-6 rounded-full relative ${classNames.join(' ')}`}>
          <div
            className={`absolute top-0.5 left-0.5 bg-white border-gray-300 border rounded-full h-5 w-5 transform transition-all ${
              checked && 'border-white translate-x-full'
            } ${disabled && 'bg-gray-100'}`}></div>
        </div>
      </label>
    </div>
  );
};
