import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import {pluralize} from '../utils';
import {SphereButton} from './SphereButton';
import {ButtonLink} from './Button';
import {useNav} from '../context/NavProvider';
import Scrollbars from 'react-custom-scrollbars';

const SphereListChildItem: React.FC<{sphere: AccessibleSphere}> = ({
  sphere,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const location = useLocation();

  return (
    <div className="flex flex-col">
      <div className="flex flex-row space-x-3 items-start justify-between">
        <div className="flex flex-row space-x-3">
          <div className="rounded-full bg-gray-300 w-4 h-4 overflow-hidden flex-shrink-0">
            {sphere.icon && (
              <img src={sphere.icon} alt={sphere.name} className="w-4 h-4" />
            )}
          </div>

          <span className="text-sm leading-5 font-medium mb-1">
            {sphere.name}
          </span>
        </div>

        <div className="flex space-x-2">
          {sphere.children.length > 0 && !expanded && (
            <a
              className="flex space-x-1 items-center text-xs font-medium text-gray-400 cursor-pointer"
              onClick={() => setExpanded(true)}>
              <FontAwesomeIcon icon="angle-down" />
              <span>{sphere.children.length}</span>
            </a>
          )}

          <Link
            to={`/spheres/${sphere.slug}`}
            className="py-1 px-2 text-gray-400 text-xs rounded cursor-pointer flex-shrink-0">
            <FontAwesomeIcon icon="right-from-bracket" />
          </Link>
        </div>
      </div>

      {expanded && (
        <div className="pt-2">
          {sphere.children.length > 0 && (
            <div className="space-y-4 pl-4">
              {sphere.children.map((childSphere) => (
                <SphereListChildItem
                  key={childSphere.slug}
                  sphere={childSphere}
                />
              ))}
            </div>
          )}

          <Link
            className="flex items-center space-x-2 pl-4 pr-4 cursor-pointer mb-2"
            to="/spheres/new"
            state={{
              parentId: sphere.id,
              parentName: sphere.name,
              background: location,
            }}>
            <div className="flex items-center justify-center w-4 h-4 bg-gray-300 rounded-full">
              <FontAwesomeIcon icon="plus" className="text-white text-sm" />
            </div>
            <span className="text-sm leading-5 font-medium">Add Subsphere</span>
          </Link>

          <a
            className="flex space-x-1 items-center text-xs font-medium text-gray-400 cursor-pointer"
            onClick={() => setExpanded(false)}>
            <span>Hide</span>
            <FontAwesomeIcon icon="angle-up" />
          </a>
        </div>
      )}
    </div>
  );
};

export const SphereList: React.FC<{
  sphere: AccessibleSphere;
}> = ({sphere}) => {
  const location = useLocation();
  const {setNavigatorOpen} = useNav();

  return (
    <div className="flex flex-col flex-grow space-y-2 py-2 pb-4 md:py-0 md:space-y-4 md:max-w-lg">
      <h3 className="text-lg font-bold">
        {sphere.children.length}{' '}
        {pluralize('Subsphere', sphere.children.length)}
      </h3>

      {/* <div className="flex-grow"> */}
      <Scrollbars height="100%">
        <div className="flex flex-col space-y-2 pr-4">
          {sphere.children.map((sphere) => (
            <SphereButton key={`sphere-list-i-${sphere.id}`} sphere={sphere} />
          ))}

          <Link
            className="flex flex-col border border-gray-300 rounded-md space-y-2"
            to="/spheres/new"
            state={{
              parentId: sphere.id,
              parentName: sphere.name,
              background: location,
            }}>
            <div className="flex flex-row space-x-4 items-center font-bold px-2 py-1 cursor-pointer">
              <div className="flex items-center justify-center bg-root-dark text-white rounded-full w-10 h-10 overflow-hidden flex-shrink-0">
                <FontAwesomeIcon icon="plus" className="w-8 h-8 " />
              </div>
              <span className="text-ellipsis w-full overflow-x-hidden whitespace-nowrap">
                Add Subsphere
              </span>
            </div>
          </Link>
        </div>

        <div className="h-4"></div>
      </Scrollbars>
      {/* </div> */}

      <Link
        className="flex items-center space-x-2 py-2 px-4 cursor-pointer"
        to="/spheres/enolve"
        onClick={() => setNavigatorOpen(false)}>
        <FontAwesomeIcon
          icon="globe"
          className="bg-root-dark text-white rounded-full h-4 w-4 p-2"
        />
        <span className="font-bold md:text-black">Explore All Spheres</span>
      </Link>

      <Link
        className="flex items-center space-x-2 py-2 px-4 cursor-pointer"
        to="/home"
        onClick={() => setNavigatorOpen(false)}>
        <FontAwesomeIcon
          icon="lock"
          className="bg-root-dark text-white rounded-full h-4 w-4 p-2"
        />
        <span className="font-bold md:text-black">My Spheres</span>
      </Link>

      <div className="hidden md:block">
        <ButtonLink
          theme="root"
          to="/spheres/new"
          state={{
            background: location,
          }}>
          Create Your Own Sphere
        </ButtonLink>
      </div>
    </div>
  );
};
