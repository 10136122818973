import React from 'react';
import {Section} from '../components/Section';

export const NotificationsScreen: React.FC = () => {
  return (
    <Section>
      <h3 className="text-lg">Coming Soon!</h3>
    </Section>
  );
};
