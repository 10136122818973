import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface MetadatumProps {
  iconSrc?: string;
  faIcon?: IconProp;
  value: string | number;
}

export const Metadatum: React.FC<MetadatumProps> = ({
  iconSrc,
  faIcon,
  value,
}) => {
  return (
    <div className="text-xs text-gray-500">
      {faIcon && <FontAwesomeIcon icon={faIcon} />}
      {iconSrc && <img src={iconSrc} className="inline w-4 h-4" />}
      <span className="ml-1">{value}</span>
    </div>
  );
};
