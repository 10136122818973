import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import 'firebase/auth';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {configureGTag, trackPageView} from './utils';
import {AuthProvider, useAuth} from './context/AuthProvider';
import {NavProvider} from './context/NavProvider';
import {SphereProvider} from './context/SphereProvider';
import {Layout, UnauthenticatedLayout} from './components/Layout';
import {EntryModal} from './components/EntryModal';
import {HomeFeedScreen} from './screens/HomeFeedScreen';
import {SphereScreen} from './screens/SphereScreen';
import {ManageSphereLayout} from './components/ManageSphereLayout';
import {ManageSphereRolesScreen} from './screens/ManageSphereRolesScreen';
import {ManageSpherePlansScreen} from './screens/ManageSpherePlansScreen';
import {ManageSphereCollaboratorsScreen} from './screens/ManageSphereCollaboratorsScreen';
import {ManageSubspheresScreen} from './screens/ManageSubspheresScreen';
import {ConnectionModal, ConnectionScreen} from './components/ConnectionModal';
import {AuthScreen} from './screens/AuthScreen';
import {ForgotPasswordScreen} from './screens/ForgotPasswordScreen';
import {CreateProfileScreen} from './screens/CreateProfileScreen';
import {AcceptInvitationScreen} from './screens/AcceptInvitationScreen';
import {ManageSubscriptionScreen} from './screens/ManageSubscriptionScreen';
import {NotificationsScreen} from './screens/NotificationsScreen';
import {EditProfileScreen} from './screens/EditProfileScreen';
import {CreateSphereModal} from './components/CreateSphereModal';
import {EntryScreen} from './screens/EntryScreen';
import {ManageSphereScreen} from './screens/ManageSphereScreen';
import {ProfileScreen} from './screens/ProfileScreen';

library.add(fab, fas);

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  configureGTag();
}

const AuthenticatedRoute: React.FC = ({children}) => {
  const {signedIn} = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!signedIn) {
      navigate('/auth');
    }
  }, [signedIn]);

  if (!signedIn) {
    return <></>;
  }

  return (
    <NavProvider>
      <SphereProvider>{children}</SphereProvider>
    </NavProvider>
  );
};

const routes = (
  <>
    <Route
      path="/"
      element={
        <AuthProvider>
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        </AuthProvider>
      }>
      <Route index element={<Navigate to="/spheres/enolve" />} />
      <Route path="all" element={<Navigate to="/spheres/enolve" />} />
      <Route path="home" element={<HomeFeedScreen includePublic={true} />} />
      <Route path="notifications" element={<NotificationsScreen />} />
      <Route path="invitations" element={<AcceptInvitationScreen />} />
      <Route path="subscription" element={<ManageSubscriptionScreen />} />
      <Route path="profile/edit" element={<EditProfileScreen />} />
      <Route path="p/:pslug" element={<ProfileScreen />} />
      <Route path="spheres">
        <Route path=":slug" element={<SphereScreen />} />
        <Route path=":slug/manage" element={<ManageSphereLayout />}>
          <Route index element={<ManageSphereScreen />} />
          <Route path="roles" element={<ManageSphereRolesScreen />} />
          <Route path="subspheres" element={<ManageSubspheresScreen />} />
          <Route path="plans" element={<ManageSpherePlansScreen />} />
          <Route path="users" element={<ManageSphereCollaboratorsScreen />} />
        </Route>
      </Route>
    </Route>
    <Route
      path="/entries/:id"
      element={
        <AuthProvider>
          <AuthenticatedRoute>
            <EntryScreen />
          </AuthenticatedRoute>
        </AuthProvider>
      }
    />
    <Route
      path="/connections/:id"
      element={
        <AuthProvider>
          <AuthenticatedRoute>
            <ConnectionScreen />
          </AuthenticatedRoute>
        </AuthProvider>
      }
    />
    <Route
      path="/auth"
      element={
        <AuthProvider>
          <UnauthenticatedLayout />
        </AuthProvider>
      }>
      <Route index element={<AuthScreen />} />
      <Route path="forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="profile/create" element={<CreateProfileScreen />} />
    </Route>
  </>
);

const modalRoutes = (
  <>
    <Route path="/entries/:id" element={<EntryModal />} />
    <Route path="/connections/:id" element={<ConnectionModal />} />
    <Route path="/spheres/new" element={<CreateSphereModal />} />
  </>
);

const App = () => {
  const location = useLocation();
  let state = location.state as {background?: Location};

  React.useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <>
      <Routes location={state?.background || location}>{routes}</Routes>

      {state?.background && (
        <AuthProvider>
          <AuthenticatedRoute>
            <Routes>{modalRoutes}</Routes>
          </AuthenticatedRoute>
        </AuthProvider>
      )}
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
