import {FormikProps, withFormik} from 'formik';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Scrollbars from 'react-custom-scrollbars';

import {
  EntryBorders,
  EntryClasses,
  EntryCreateBodyPlaceholder,
  EntryCreateTitlePlaceholder,
  EntryIcons,
  EntryLabels,
  useEntrySearch,
} from '../../utils/entry';
import {Button} from '../Button';
import {TextArea} from '../Inputs';
import {EntryContent} from './EntryContent';
import {LinkedEntryContent} from './LinkedEntryContent';
import {
  CreateEntryInput,
  createEntry,
  createEntryConnection,
} from '../../services/entry';
import {pluralize} from '../../utils';

interface EntryFormProps {
  id?: Id;
  initialType?: EntryType;
  parent?: boolean;
  toConnect?: Entry;
  initialSphereSlug: string;
  onCommit: (entry: Entry, connectionId?: Id) => void;
  onCancel: () => void;
}

interface EntryFormValues {
  title: string;
  content: string;
  sphereSlug: string;
  type: EntryType;
  relatedEntry: Entry | null;
}

const Page1: React.FC<{
  onNext: () => void;
  onClose: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}> = ({onNext, onClose, setFieldValue}) => {
  return (
    <div className="flex flex-col p-6 pb-4 relative">
      <FontAwesomeIcon
        icon="arrow-left"
        className="cursor-pointer absolute left-3 top-5 w-10 h-10"
        onClick={onClose}
      />

      <h3 className="text-center font-bold text-2xl mb-8">Create Your Own</h3>

      <button
        className={`flex flex-row mb-5 space-x-4 justify-between items-center cursor-pointer rounded border-l-4 py-2 px-5 ${EntryBorders['problem']} focus:outline-none`}
        style={{boxShadow: '0 0 7px 3px rgba(0,0,0,0.1)'}}
        onClick={() => {
          setFieldValue('type', 'problem');
          onNext();
        }}>
        <div className="flex flex-col items-start">
          <h4 className="text-sm font-bold mb-1">Problem</h4>
          <span className="text-sm text-left">
            Add a New Challenge or Difficulty
          </span>
        </div>

        <img src={EntryIcons['problem']} alt="problem" className="w-10 h-10" />
      </button>

      <button
        className={`flex flex-row mb-5 space-x-4 justify-between items-center cursor-pointer rounded border-l-4 py-2 px-5 ${EntryBorders['solution']}`}
        style={{boxShadow: '0 0 7px 3px rgba(0,0,0,0.1)'}}
        onClick={() => {
          setFieldValue('type', 'solution');
          onNext();
        }}>
        <div className="flex flex-col items-start">
          <h4 className="text-sm font-bold mb-1">Solution</h4>
          <span className="text-sm text-left">
            Add a New Action Item or S.M.A.R.T. Goal
          </span>
        </div>

        <img
          src={EntryIcons['solution']}
          alt="solution"
          className="w-10 h-10"
        />
      </button>

      <button
        className={`flex flex-row mb-5 space-x-4 justify-between items-center cursor-pointer rounded border-l-4 py-2 px-5 ${EntryBorders['terminal_goal']}`}
        style={{boxShadow: '0 0 7px 3px rgba(0,0,0,0.1)'}}
        onClick={() => {
          setFieldValue('type', 'terminal_goal');
          onNext();
        }}>
        <div className="flex flex-col items-start">
          <h4 className="text-sm font-bold mb-1">Value Goal</h4>
          <span className="text-sm text-left">
            Add a New Need, Want, or Desire
          </span>
        </div>

        <img
          src={EntryIcons['terminal_goal']}
          alt="terminal_goal"
          className="w-10 h-10"
        />
      </button>
    </div>
  );
};

const Page2: React.FC<{
  isRootCause?: boolean;
  parent?: Entry;
  values: EntryFormValues;
  onNext: () => void;
  onBack: () => void;
  onCancel: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}> = ({
  isRootCause,
  parent,
  values,
  onNext,
  onBack,
  onCancel,
  setFieldValue,
}) => {
  const {
    results: relatedEntriesResults,
    loading: relatedEntriesLoading,
    search: searchEntries,
  } = useEntrySearch({
    sphereSlug: values.sphereSlug,
    types: isRootCause ? ['problem', 'solution', 'terminal_goal'] : values.type,
  });

  React.useEffect(() => {
    if (values.relatedEntry === null && parent !== undefined) {
      searchEntries(parent.id, `${values.title} ${values.content}`);
    }
  }, [values.title, values.content]);

  return (
    <>
      <div
        className={`flex flex-row space-x-6 justify-between mb-4 px-4 py-3 items-center md:min-w-96 ${
          EntryClasses[values.type]
        }`}>
        <FontAwesomeIcon
          icon="angle-down"
          onClick={onBack}
          className="w-10 h-10"
        />

        <h3 className="flex flex-col items-center text-center font-bold text-xl">
          <span>New {EntryLabels[values.type]}</span>
          {/* <span className="text-xs font-normal">
            {EntryCreateBodyTip[values.type]}
          </span> */}
        </h3>

        <img
          src={EntryIcons[values.type]}
          alt={values.type}
          className="w-10 h-10"
        />
      </div>

      <TextArea
        containerClassName="flex-grow"
        labelClassName="h-full"
        className={`sm:text-base border-0 h-full min-h-[20rem]`}
        name="content"
        label={EntryCreateBodyPlaceholder[values.type]}
        placeholder
      />

      <div className="flex justify-between py-2 px-6 space-x-3">
        <Button type="button" style="default" onClick={onCancel}>
          Cancel
        </Button>

        <Button type="button" style={values.type} onClick={onNext}>
          Next
        </Button>
      </div>

      {relatedEntriesResults.length > 0 && values.relatedEntry === null && (
        <div>
          <div className="font-semibold text-center text-sm mb-2 p-4">
            Avoid Duplication - Review {relatedEntriesResults.length} similar{' '}
            {pluralize(EntryLabels[values.type], relatedEntriesResults.length)}:
          </div>

          <Scrollbars style={{height: '10.5rem'}}>
            <div className="flex flex-row items-stretch whitespace-nowrap space-x-4">
              {relatedEntriesResults.map((entry) => (
                <LinkedEntryContent
                  key={`related-entry-${entry.id}`}
                  entry={entry}
                  className="w-80 cursor-pointer"
                  includeAvatar
                  onClick={() => {
                    setFieldValue('relatedEntry', entry);
                    onNext();
                  }}
                />
              ))}
            </div>
          </Scrollbars>
        </div>
      )}
    </>
  );
};

const Page3: React.FC<{
  parent?: Entry;
  values: EntryFormValues;
  onBack: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isSubmitting: boolean;
}> = ({values, parent, onBack, setFieldValue, isSubmitting: submitting}) => {
  return values.relatedEntry && parent ? (
    <div className="flex flex-col p-6 pb-4 relative items-center">
      <h3 className="text-center font-bold text-2xl mb-8">Review Connection</h3>

      <h4 className="mb-4 text-center font-bold text-xl">
        {EntryLabels[parent.type]}
      </h4>
      <EntryContent entry={parent} className="mb-5" />

      <h4 className="mb-4 text-center font-bold text-xl">
        {EntryLabels[values.relatedEntry.type]}
      </h4>
      <EntryContent entry={values.relatedEntry} className="mb-5" />

      <Button type="submit" style="root" className="mb-4">
        Connect Now!
      </Button>

      <a
        className="text-gray-400 font-medium text-xs"
        onClick={() => {
          setFieldValue('relatedEntry', null);
          onBack();
        }}>
        {'<<'} go back
      </a>
    </div>
  ) : (
    <>
      <div
        className={`flex flex-row space-x-5 justify-between mb-4 px-4 py-3 items-center md:min-w-[384px] ${
          EntryClasses[values.type]
        }`}>
        <img
          src={EntryIcons[values.type]}
          alt={values.type}
          className="w-10 h-10"
        />

        <TextArea
          name="title"
          label={EntryCreateTitlePlaceholder[values.type]}
          containerClassName="sm:text-base flex-grow"
          className={`${
            EntryClasses[values.type]
          } sm:text-base border-0 font-medium text-lg`}
          placeholder
        />
      </div>

      <p
        className="flex-grow px-4 py-3 mb-6"
        dangerouslySetInnerHTML={{
          __html: values.content.replaceAll(/\n/g, '<br />'),
        }}
      />

      <div className="bg-gray-200 flex flex-row space-x-4 px-5 py-2">
        <div className="px-3 py-1 bg-white rounded-full text-sm">
          {values.sphereSlug}
        </div>
      </div>

      <div className="flex justify-between py-2 px-6 space-x-3">
        <Button type="button" style="default" onClick={onBack}>
          Go Back & Edit
        </Button>

        <Button type="submit" style={values.type} disabled={submitting}>
          Publish
        </Button>
      </div>
    </>
  );
};

const InnerForm = ({
  handleSubmit,
  values,
  parent: isRootCause,
  id,
  toConnect: parent,
  isSubmitting,
  submitForm,
  onCancel,
  setFieldValue,
  initialType,
}: EntryFormProps & FormikProps<EntryFormValues>) => {
  const [step, setStep] = React.useState(initialType === undefined ? 0 : 1);

  const prevStep = React.useCallback(() => {
    if (step - 1 >= 0) {
      setStep(step - 1);
    }
  }, [step]);

  const nextStep = React.useCallback(async () => {
    if (step == pages.length - 1) {
      await submitForm();
    } else {
      setStep(step + 1);
    }
  }, [step]);

  const pages = React.useMemo(() => {
    return [
      <Page1
        onNext={nextStep}
        onClose={onCancel}
        setFieldValue={setFieldValue}
      />,
      <Page2
        parent={parent}
        isRootCause={isRootCause}
        values={values}
        onNext={nextStep}
        onBack={prevStep}
        onCancel={onCancel}
        setFieldValue={setFieldValue}
      />,
      <Page3
        parent={parent}
        values={values}
        onBack={prevStep}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
      />,
    ];
  }, [values, parent, isRootCause, prevStep]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col h-full">
      <div className="flex flex-col flex-grow">{pages[step]}</div>
    </form>
  );
};

export const EntryForm = withFormik<EntryFormProps, EntryFormValues>({
  displayName: 'EntryForm',
  // enableReinitialize: true,
  mapPropsToValues: (props) => {
    return {
      title: '',
      content: '',
      type: props.initialType || 'problem',
      sphereSlug: props.initialSphereSlug,
      relatedEntry: null,
    };
  },
  handleSubmit: async (values, {setSubmitting, props}) => {
    if (!props.toConnect) {
      const response = await createEntry(values.sphereSlug, values);

      if (response.success) {
        props.onCommit(response.entry);
      } else {
        // TODO: handle errors
        console.log(response);
      }
    } else {
      if (!values.relatedEntry) {
        let payload: CreateEntryInput = {
          title: values.title,
          content: values.content,
          type: values.type,
        };

        const response = await createEntry(props.initialSphereSlug, payload);

        if (response.success) {
          const connectionResponse = props.parent
            ? await createEntryConnection(props.toConnect.id, response.entry.id)
            : await createEntryConnection(
                response.entry.id,
                props.toConnect.id,
              );

          if (connectionResponse.success) {
            props.onCommit(
              response.entry,
              connectionResponse.entryConnection.id,
            );
          }
        } else {
          // TODO: handle errors
          console.log(response);
        }
      } else {
        const response = props.parent
          ? await createEntryConnection(
              props.toConnect.id,
              values.relatedEntry.id,
            )
          : await createEntryConnection(
              values.relatedEntry.id,
              props.toConnect.id,
            );

        if (response.success) {
          props.onCommit(
            props.parent
              ? response.entryConnection.child
              : response.entryConnection.parent,
            response.entryConnection.id,
          );
        } else {
          // TODO: handle errors
          console.log(response);
        }
      }
    }

    setSubmitting(false);
  },
})(InnerForm);
