import axios, {AxiosTransformer} from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import {API_BASE_URL} from '../config';

export const Base = axios.create({
  baseURL: `${API_BASE_URL}/v1`,
  transformRequest: [
    (data: any, headers: any) => {
      headers['Accept'] = 'application/json';
      headers['Content-Type'] = 'application/json';

      return data === undefined ? data : snakecaseKeys(data, {deep: true});
    },
  ].concat(axios.defaults.transformRequest as AxiosTransformer[]),
  transformResponse: (axios.defaults
    .transformResponse as AxiosTransformer[]).concat([
    (data) => {
      return camelcaseKeys(data, {deep: true});
    },
  ]),
});
