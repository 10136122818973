import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import {EntryBorders, EntryClasses, EntryIcons} from '../../utils/entry';
import {Avatar} from '../Avatar';
import {Metadatum} from './Metadatum';

import metaProblem from '../../assets/img/meta-problem.svg';
import metaRootCauses from '../../assets/img/meta-root-causes.svg';
import metaSolution from '../../assets/img/meta-solution.svg';
import metaSolvedProblems from '../../assets/img/meta-solved-problems.svg';
import metaTerminalGoals from '../../assets/img/meta-terminal-goals.svg';
import {useSphere} from '../../context/SphereProvider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Menu, Transition} from '@headlessui/react';

interface LinkedEntryContentProps {
  entry: Entry;
  onClick?: () => void;
  onDisconnect?: (connectionId: Id) => void;
  includeAvatar?: boolean;
  className?: string;
  entryConnection?: EntryConnection;
}

export const LinkedEntryContent: React.FC<LinkedEntryContentProps> = ({
  entry,
  className,
  includeAvatar,
  onClick,
  onDisconnect,
  entryConnection,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const background = location.state?.background || location;

  return (
    <Link
      to={`/entries/${entry.id}`}
      state={{background}}
      className={`${EntryBorders[entry.type]} ${
        className || ''
      } relative cursor-pointer shadow-md border-l-8 rounded-lg bg-white w-80 flex-shrink-0 whitespace-normal text-ellipsis`}
      onClick={(event) => {
        if (onClick !== undefined) {
          event.preventDefault();
          onClick();
        }
      }}>
      <div className="flex flex-col justify-between space-y-4">
        <div
          className={`${
            EntryClasses[entry.type]
          } flex items-center space-x space-x-2 rounded-r-lg overflow-hidden whitespace-nowrap h-10 pr-2 pl-4`}
          style={{textOverflow: 'ellipsis'}}>
          <img src={EntryIcons[entry.type]} alt={entry.type} className="h-4" />
          <span className=" text-gray-700 text-sm">{entry.title}</span>
        </div>

        <div className="text-gray-400 text-sm pr-2 pl-4 h-10 overflow-hidden">
          {entry.content}
        </div>

        <div className="flex flex-row justify-between items-center space-x-2 pr-2 pl-4 pb-2">
          <div className="flex flex-row items-center space-x-2 md:space-x-4">
            {entry.type === 'problem' && (
              <Metadatum
                iconSrc={metaSolution}
                value={`${entry.solutionsCount}`}
              />
            )}
            {entry.type === 'solution' && (
              <Metadatum
                iconSrc={metaSolvedProblems}
                value={`${entry.rootProblemsCount}`}
              />
            )}
            {entry.type === 'terminal_goal' && (
              <Metadatum
                iconSrc={metaSolution}
                value={`${entry.solutionsCount}`}
              />
            )}
            <Metadatum
              faIcon="comment-dots"
              value={`${entry.comments.length}`}
            />
            {entry.type === 'problem' && (
              <>
                <Metadatum
                  iconSrc={metaRootCauses}
                  value={`${entry.rootProblemsCount}`}
                />
                <Metadatum
                  iconSrc={metaTerminalGoals}
                  value={`${entry.valueGoalsCount + entry.problemsCount}`}
                />
              </>
            )}
            {entry.type === 'solution' && (
              <>
                <Metadatum
                  iconSrc={metaTerminalGoals}
                  value={`${entry.valueGoalsCount}`}
                />
                <Metadatum
                  iconSrc={metaProblem}
                  value={`${entry.problemsCount}`}
                />
              </>
            )}
            {entry.type === 'terminal_goal' && (
              <>
                <Metadatum
                  iconSrc={metaRootCauses}
                  value={`${entry.rootProblemsCount}`}
                />
                <Metadatum
                  iconSrc={metaTerminalGoals}
                  value={`${entry.valueGoalsCount + entry.problemsCount}`}
                />
              </>
            )}
          </div>
          {includeAvatar && <Avatar size="sm" profile={entry.author} />}
        </div>
      </div>

      {entryConnection && (
        <Menu as="div" className="absolute right-2 top-2">
          <Menu.Button
            className={`text-${entry.type.replace(
              '_',
              '-',
            )} cursor-pointer w-3`}>
            <FontAwesomeIcon icon="ellipsis-v" />
          </Menu.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {() => (
                    <button
                      className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                      onClick={(event) => {
                        event.preventDefault();
                        navigate(`/connections/${entryConnection?.id}`, {
                          state: {background},
                        });
                      }}>
                      View Connection
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {() => (
                    <button
                      className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                      onClick={(event) => {
                        event.preventDefault();
                        navigate(`/entries/${entry.id}`, {
                          state: {background},
                        });
                      }}>
                      View Entry
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {() => (
                    <button
                      className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                      onClick={async (event) => {
                        event.preventDefault();
                        if (onDisconnect) {
                          onDisconnect(entryConnection.id);
                        }
                      }}>
                      Disconnect
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </Link>
  );
};
