import React from 'react';
import {Outlet, useParams} from 'react-router-dom';
import {fetchSphere} from '../services/sphere';
import {LoadingSpinner} from './LoadingSpinner';
import {ButtonLink} from './Button';
import {ManageSphereSidebar} from './ManageSphereSidebar';
import {Section} from './Section';

export const ManageSphereLayout: React.FC = () => {
  const {slug} = useParams<{slug: string}>();
  const [sphere, setSphere] = React.useState<Sphere | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (slug === undefined) {
      setLoading(false);
      return;
    }

    setLoading(true);
    fetchSphere(slug)
      .then((response) => {
        if (response.success) {
          const sphere = response.sphere as AccessibleSphere;
          setSphere(sphere);
        } else {
          // TODO: gracefully handle errors
          setSphere(null);
        }
      })
      .catch(() => {
        // TODO: gracefully handle errors
        setSphere(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);

  if (loading || !sphere) {
    return (
      <div className="mt-20">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="mt-20">
      <div className="flex justify-between items-center space-x-4 mb-4">
        <h2 className="text-xl">Manage Sphere</h2>
        <ButtonLink to={`/spheres/${sphere.slug}`} theme="default">
          Back
        </ButtonLink>
      </div>

      <div className="flex flex-col space-y-4 md:flex-row md:items-start md:space-x-4 md:space-y-0">
        <ManageSphereSidebar sphereSlug={sphere.slug} />

        <Section className="flex flex-col flex-grow">
          <Outlet context={[sphere]} />
        </Section>
      </div>
    </div>
  );
};
