import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {PaginatedEntryList} from '../components/PaginatedEntryList';
import {
  CreateEntryModal,
  CreateEntryModalState,
} from '../components/CreateEntryModal';
import {ComponentBlock} from '../components/ComponentBlock';

import {SphereNavigator} from '../components/SphereNavigator';
import {Outlet} from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';

export const HomeFeedScreen: React.FC<{includePublic: boolean}> = ({
  includePublic,
}) => {
  const [createdEntries, setCreatedEntries] = React.useState<Entry[]>([]);
  const [
    createEntryModalState,
    setCreateEntryModalState,
  ] = React.useState<CreateEntryModalState>({
    open: false,
    parent: false,
  });

  return (
    <div className="flex flex-row lg:space-x-4 h-full lg:items-start lg:justify-between">
      <div className="flex flex-col h-full pt-20">
        <ComponentBlock className="hidden lg:block h-full"></ComponentBlock>
        <div className="h-10"></div>
      </div>

      <Scrollbars
        height={'100%'}
        className="flex-grow px-0 lg:max-w-3xl"
        renderTrackVertical={({style, ...props}) => (
          <div
            style={{
              ...style,
              right: 2,
              bottom: 2,
              top: 2,
              borderRadius: 3,
              paddingTop: '5rem',
            }}
            {...props}
          />
        )}
        renderView={() => (
          <div
            className="md:-mr-4 pt-20 pr-4"
            style={{
              position: 'absolute',
              inset: '0px',
              overflowY: 'scroll',
            }}
            id="scrollTarget"></div>
        )}>
        <div className="h-full space-y-6">
          <div
            className="flex flex-row space-x-2 bg-white border-l-8 border-dark-gray py-6 px-5 rounded-lg items-center cursor-pointer shadow-lg"
            onClick={() =>
              setCreateEntryModalState({...createEntryModalState, open: true})
            }>
            <div className="bg-dark-gray w-8 h-8 flex items-center justify-center rounded-full">
              <FontAwesomeIcon icon="plus" className="text-white" />
            </div>
            <span className="font-bold text-base">Create a new entry</span>
          </div>

          <PaginatedEntryList
            includePublic={includePublic}
            createdEntries={createdEntries}
          />
        </div>
      </Scrollbars>

      <SphereNavigator />

      <CreateEntryModal
        state={createEntryModalState}
        onClose={() =>
          setCreateEntryModalState({...createEntryModalState, open: false})
        }
        onCommit={(entry: Entry) => {
          setCreateEntryModalState({...createEntryModalState, open: false});
          setCreatedEntries([entry, ...createdEntries]);
        }}
      />

      <Outlet />
    </div>
  );
};
