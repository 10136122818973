import React from 'react';

import {useSignedInAuth} from '../context/AuthProvider';
import {ProfileForm} from '../components/ProfileForm';
import {Alert} from '../components/Alert';
import {Section} from '../components/Section';
import {useNavigate} from 'react-router-dom';

export const EditProfileScreen: React.FC = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<boolean | null>(null);

  const {profile, updateProfile} = useSignedInAuth();
  const navigate = useNavigate();

  const EditProfileForm = ProfileForm({
    mapPropsToValues: (props) => {
      return props.initialValues;
    },
    handleSubmit: async (values) => {
      setSuccess(null);
      try {
        await updateProfile(values);
        navigate(`/p/${profile.username}`);
      } catch (error) {
        setError('An error has occurred. Please try again.');
      }
    },
  });

  return (
    <div className="w-full max-w-md mx-auto pt-20">
      <h2 className="text-xl mb-4 text-center">Manage Your Public Profile</h2>

      <Section>
        {success === true && (
          <Alert type="success">Your profile has been udpated!</Alert>
        )}

        <EditProfileForm
          initialValues={{
            description: profile.description,
            tagline: profile.tagline,
            username: profile.username,
            firstName: profile.firstName,
            lastName: profile.lastName,
          }}
          error={error}
          profile={profile}
          submitText="Publish Profile"
        />
      </Section>
    </div>
  );
};
