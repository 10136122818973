import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

type ButtonStyle =
  | 'default'
  | 'problem'
  | 'solution'
  | 'root'
  | 'terminal_goal'
  | 'link';

interface ButtonOptions {
  type: 'button' | 'reset' | 'submit';
  className?: string;
  style?: ButtonStyle;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const ButtonClasses =
  'group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none whitespace-nowrap';

export const DefaultButtonClasses =
  'bg-white border-gray-300 border-1 text-gray-700';
export const RootButtonClasses =
  'bg-root hover:bg-root-dark focus:bg-root-dark';
export const ProblemButtonClasses =
  'bg-problem hover:bg-problem-dark focus:bg-problem-dark';
export const SolutionButtonClasses =
  'bg-solution hover:bg-solution-dark focus:bg-solution-dark';
export const TerminalGoalButtonClasses =
  'bg-terminal-goal hover:bg-terminal-goal-dark focus:bg-terminal-goal-dark';

const buildButtonClasses = (style: ButtonStyle) => {
  let classes = ButtonClasses;
  if (style === 'default') {
    classes += ` ${DefaultButtonClasses}`;
  } else if (style === 'problem') {
    classes += ` ${ProblemButtonClasses}`;
  } else if (style === 'solution') {
    classes += ` ${SolutionButtonClasses}`;
  } else if (style === 'terminal_goal') {
    classes += ` ${TerminalGoalButtonClasses}`;
  } else if (style === 'root') {
    classes += ` ${RootButtonClasses}`;
  } else if (style === 'link') {
    classes = 'text-root';
  }

  return classes;
};

export const Button: React.FC<ButtonOptions> = ({
  children,
  className,
  style: styleProp,
  ...buttonProps
}) => {
  const classes = buildButtonClasses(styleProp || 'default');

  return (
    <button {...buttonProps} className={`${classes} ${className} `}>
      {children}
    </button>
  );
};

type ButtonLinkOptions = LinkProps & {
  theme?: ButtonStyle;
};

export const ButtonLink: React.FC<ButtonLinkOptions> = ({
  children,
  theme,
  ...buttonProps
}) => {
  const classes = buildButtonClasses(theme || 'default');

  return (
    <Link {...buttonProps} className={classes}>
      {children}
    </Link>
  );
};
