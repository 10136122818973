import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import {useSphereRole} from '../../context/SphereProvider';
import {LinkedEntryContent} from './LinkedEntryContent';

interface EntryChildrenProps {
  entry: Entry;
  connections: EntryConnection[];
  title: string;
  createLabel: string;
  create: () => void;
  icon?: string;
  guidingQuestion: string | null;
}

const EntryChildrenWrapper: React.FC<EntryChildrenProps> = ({
  children,
  entry,
  createLabel,
  create,
  title,
  guidingQuestion,
}) => {
  const {getRole, showJoinModal} = useSphereRole();

  return (
    <div
      className={'flex flex-col space-y-4 overflow-hidden transition-height'}
      style={{marginBottom: '2rem'}}>
      <div className="flex flex-col justify-center items-center space-x-2">
        <h3 className="text-2xl text-center font-semibold">{title}</h3>
        {guidingQuestion && (
          <p className="text-center text-gray-700">{guidingQuestion}</p>
        )}
      </div>

      <Scrollbars style={{height: '10.5rem'}}>
        <div className="flex flex-row justify-start items-start h-full whitespace-nowrap space-x-4 px-2">
          {children}

          <div
            className="bg-gray-50 border-l-4 border-gray-300 px-10 py-4 flex flex-col justify-center w-80 text-center cursor-pointer shadow-sm flex-shrink-0"
            onClick={() => {
              if (getRole(entry.sphereId)) {
                create();
              } else {
                showJoinModal(entry.sphereSlug);
              }
            }}
            style={{minHeight: '9.5rem'}}>
            <p className="flex text-base whitespace-normal text-gray-500 items-center">
              <FontAwesomeIcon icon="plus" className="w-6 h-6" />
              <span className="ml-2">Add a {createLabel}</span>
            </p>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export const EntryChildren: React.FC<
  EntryChildrenProps & {
    entries: Entry[];
    onDisconnect: (connectionId: Id) => void;
  }
> = (props) => {
  const {entries, onDisconnect, ...rest} = props;

  return (
    <EntryChildrenWrapper {...rest}>
      {entries.map((child) => (
        <LinkedEntryContent
          key={`entry-${child.id}`}
          entry={child}
          includeAvatar
          entryConnection={rest.connections.find(
            (conn) => conn.entry.id === child.id,
          )}
          onDisconnect={onDisconnect}
          className="w-80"
        />
      ))}
    </EntryChildrenWrapper>
  );
};
