import React from 'react';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import debounce from 'debounce';

import {Button} from './Button';
import {InputField} from './Inputs';
import {Alert} from './Alert';
import {UserInput, checkUsername, createUser} from '../services/user';
import {useAuth} from '../context/AuthProvider';

interface SignUpFormProps {
  initialValues?: UserInput;
}

const debouncedCheckUsername = debounce(
  async (value: string | undefined, resolve: (res: boolean) => void) => {
    if (value === undefined || value.length === 0) {
      return resolve(false);
    }

    try {
      const data = await checkUsername(value);
      if (!data.success || !data.available) {
        return resolve(false);
      }
      return resolve(true);
    } catch (e) {
      return resolve(false);
    }
  },
  500,
);

const UserSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required.')
    .min(3, 'Username must be at least three (3) characters.')
    .max(15, 'Username must be shorter than fifteen (15) characters.')
    .matches(
      /^[a-zA-Z0-9]+$/,
      'Username may only contain alphanumeric (A-Z, 0-9) characters.',
    )
    .test(
      'username',
      'Username is not available.',
      (value) =>
        new Promise((resolve) => debouncedCheckUsername(value, resolve)),
    ),
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  email: Yup.string().email().required('Email address is required.'),
  password: Yup.string().required('Password is required.'),
});

const SsoUserSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required.')
    .min(3, 'Username must be at least three (3) characters.')
    .max(15, 'Username must be shorter than fifteen (15) characters.')
    .matches(
      /^[a-zA-Z0-9]+$/,
      'Username may only contain alphanumeric (A-Z, 0-9) characters.',
    )
    .test(
      'username',
      'Username is not available.',
      (value) =>
        new Promise((resolve) => debouncedCheckUsername(value, resolve)),
    ),
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
});

export const SignUpForm: React.FC<SignUpFormProps> = ({initialValues}) => {
  const [error, setError] = React.useState<String | null>(null);
  const navigate = useNavigate();

  const {signInEmail, firebaseUser} = useAuth();

  const [firstName, lastName] = firebaseUser?.displayName?.split(' ') || [
    '',
    '',
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValues || {
          email: firebaseUser?.email || '',
          password: '',
          username: '',
          firstName,
          lastName,
        }
      }
      validationSchema={firebaseUser ? SsoUserSchema : UserSchema}
      onSubmit={async (values: UserInput) => {
        try {
          if (firebaseUser) {
            const token = await firebaseUser.getIdToken();
            await createUser(values, token);
          } else {
            await createUser(values);
            await signInEmail(values.email, values.password as string);
          }
          navigate('/');
        } catch (error) {
          // TODO: handle errosr
          console.log(error);
        }
      }}>
      <Form className="flex flex-col space-y-4">
        {error && <Alert type="error">{error}</Alert>}

        <div className="flex justify-between spae-x-2">
          <InputField
            label="First Name"
            name="firstName"
            required
            placeholder
            type="text"
          />

          <InputField
            label="Last Name"
            name="lastName"
            required
            placeholder
            type="text"
          />
        </div>

        {!firebaseUser && (
          <InputField
            label="Email Address"
            name="email"
            required
            placeholder
            type="email"
          />
        )}

        <InputField
          label="Username"
          name="username"
          required
          placeholder
          type="text"
        />

        {!firebaseUser && (
          <InputField
            label="Password"
            name="password"
            required
            placeholder
            type="password"
          />
        )}

        <Button style="problem" type="submit">
          Create Your Profile
        </Button>
      </Form>
    </Formik>
  );
};
