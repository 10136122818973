import React from 'react';

import {EntryScreen} from '../screens/EntryScreen';
import {Modal} from './Modal';

export const EntryModal: React.FC = () => {
  return (
    <Modal className="p-0">
      <EntryScreen />
    </Modal>
  );
};
