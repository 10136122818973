import React from 'react';

import {useSignedInAuth} from '../context/AuthProvider';
import {Alert} from '../components/Alert';
import {Section} from '../components/Section';
import {useNavigate} from 'react-router-dom';
import {SignUpForm} from '../components/SignUpForm';

export const CreateProfileScreen: React.FC = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<boolean | null>(null);

  const {firebaseUser} = useSignedInAuth();
  const navigate = useNavigate();

  return (
    <div className="w-full max-w-md mx-auto">
      <h2 className="text-xl mb-4 text-center">Create Your Public Profile</h2>

      <Section>
        {success === true && (
          <Alert type="success">Your profile has been created!</Alert>
        )}

        <SignUpForm />
      </Section>
    </div>
  );
};
