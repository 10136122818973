import React from 'react';
import {useSphere} from './SphereProvider';

interface NavContextData {
  navigatorOpenable: boolean;
  setNavigatorOpenable: (open: boolean) => void;
  navigatorOpen: boolean;
  setNavigatorOpen: (open: boolean) => void;
}

const NavContext = React.createContext<NavContextData>({} as NavContextData);

export const NavProvider: React.FC = ({children}) => {
  const [navigatorOpenable, setNavigatorOpenable] = React.useState<boolean>(
    false,
  );
  const [navigatorOpen, setNavigatorOpen] = React.useState<boolean>(false);
  const [hideNav, setHideNav] = React.useState<boolean>(false);

  const {sphere} = useSphere()!;

  React.useEffect(() => {
    setNavigatorOpenable(sphere !== null);
  }, [sphere]);

  return (
    <NavContext.Provider
      value={{
        navigatorOpenable,
        setNavigatorOpenable,
        navigatorOpen,
        setNavigatorOpen,
      }}>
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const context = React.useContext(NavContext);
  if (context === undefined) {
    throw new Error('useNav must be within NavProvider');
  }

  return context;
};
