import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const SubsphereButton: React.FC<{sphere: AccessibleSphere}> = ({sphere}) => {
  return (
    <Link
      to={`/spheres/${sphere.slug}`}
      className="hover:bg-gray-100 cursor-pointer flex flex-row justify-between items-center space-x-2 pl-8 pr-4 py-1">
      <div className="flex flex-row space-x-2">
        <div className="rounded-full bg-gray-300 w-4 h-4 overflow-hidden flex-shrink-0">
          {sphere.icon && (
            <img src={sphere.icon} alt={sphere.name} className="w-4 h-4" />
          )}
        </div>
        <div className="text-sm leading-5 font-medium">{sphere.name}</div>
      </div>

      <div>
        <FontAwesomeIcon icon="right-from-bracket" className="text-gray-400" />
      </div>
    </Link>
  );
};

export const SphereButton: React.FC<{sphere: AccessibleSphere}> = ({
  sphere,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [featured, setFeatured] = React.useState<AccessibleSphere[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setFeatured(sphere.children.filter((sphere) => sphere.featured));
  }, [sphere.id]);

  return (
    <div className="flex flex-col border border-gray-300 rounded-md space-y-2">
      <div
        onClick={() => {
          if (expanded) {
            navigate(`/spheres/${sphere.slug}`);
          } else {
            setExpanded(true);
          }
        }}
        className={`flex flex-row space-x-4 items-center font-bold px-2 py-1 cursor-pointer ${
          expanded && 'bg-gray-100'
        }`}>
        <div className="rounded-full bg-gray-300 w-10 h-10 overflow-hidden flex-shrink-0">
          {sphere.icon && (
            <img src={sphere.icon} alt={sphere.name} className="w-10 h-10" />
          )}
        </div>

        <div className="text-ellipsis w-full overflow-x-hidden whitespace-nowrap">
          {sphere.name}
        </div>

        {expanded ? (
          <FontAwesomeIcon
            icon="right-from-bracket"
            className="text-gray-400"
          />
        ) : (
          <FontAwesomeIcon icon="angle-down" className="text-gray-400" />
        )}
      </div>

      {!expanded && featured.length > 0 && (
        <div className="flex flex-col space-y-2 pb-2">
          {featured.map((childSphere) => (
            <SubsphereButton key={childSphere.slug} sphere={childSphere} />
          ))}
        </div>
      )}

      {expanded && (
        <>
          <div className="flex flex-row text-gray-400 text-sm leading-5 font-medium space-x-3 self-center mb-2">
            <div className="space-x-1">
              <FontAwesomeIcon icon="users" />
              <span>{sphere.memberCount}</span>
            </div>

            <div className="space-x-1">
              <FontAwesomeIcon icon="file" />
              <span>{sphere.entryCount}</span>
            </div>
          </div>

          <div className="flex flex-col space-y-1 pb-2">
            {sphere.children
              .filter((sphere) => sphere.featured)
              .map((childSphere) => (
                <SubsphereButton key={childSphere.slug} sphere={childSphere} />
              ))}

            {sphere.children
              .filter((sphere) => !sphere.featured)
              .map((childSphere) => (
                <SubsphereButton key={childSphere.slug} sphere={childSphere} />
              ))}

            <Link
              className="hover:bg-gray-100 flex items-center space-x-2 cursor-pointer pl-8 pr-2 py-1"
              to="/spheres/new"
              state={{
                parentId: sphere.id,
                parentName: sphere.name,
                background: location,
              }}>
              <div className="flex items-center justify-center w-4 h-4 bg-gray-300 rounded-full">
                <FontAwesomeIcon icon="plus" className="text-white text-sm" />
              </div>
              <span className="text-sm leading-5 font-medium">
                Add Subsphere
              </span>
            </Link>

            <FontAwesomeIcon
              icon="angle-up"
              className="cursor-pointer self-end mr-2 w-5 text-gray-400"
              onClick={() => setExpanded(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};
