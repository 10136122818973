import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment} from 'react';
import Scrollbars from 'react-custom-scrollbars';

import {listSpheres} from '../services/sphere';
import {EntryForm} from './Entry/EntryForm';
import {LoadingSpinner} from './LoadingSpinner';

const collectSpheres = (spheres: AccessibleSphere[], result: Sphere[]) => {
  spheres.forEach((el) => {
    result.push(el);

    if (el.children.length > 0) {
      collectSpheres(el.children, result);
    }
  });
};

const fetchAvailableSpheres = async () => {
  const response = await listSpheres();
  const spheres: Sphere[] = [];
  if (response.success) {
    collectSpheres(response.spheres as AccessibleSphere[], spheres);
    return spheres;
  } else {
    throw response.errors[0].messages[0];
  }
};

export interface CreateEntryModalState {
  open: boolean;
  sphereId?: string;
  toConnect?: Entry;
  initialType?: EntryType;
  parent?: boolean;
}

interface CreateEntryModalProps {
  state: CreateEntryModalState;
  onClose: () => void;
  onCommit: (entry: Entry, connectionId?: Id) => void;
}

export const CreateEntryModal: React.FC<CreateEntryModalProps> = ({
  state,
  onClose,
  onCommit,
}) => {
  const [sphereSlug, setSphereSlug] = React.useState(state.sphereId);

  React.useEffect(() => {
    if (state.sphereId === undefined) {
      fetchAvailableSpheres()
        .then((spheres) => {
          setSphereSlug(spheres[0]!.slug);
        })
        .catch((error) => console.log(error));
    } else {
      setSphereSlug(state.sphereId);
    }
  }, [state.open]);

  return (
    <Transition show={state.open} as={Fragment}>
      <Dialog onClose={() => onClose()} className="fixed z-50 inset-0">
        <Scrollbars>
          <div className="flex min-h-screen md:items-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div
                className={`flex flex-col w-full h-[100dvh] md:h-auto relative bg-white md:rounded-lg md:max-w-lg md:mx-auto`}>
                {sphereSlug === undefined ? (
                  <LoadingSpinner />
                ) : (
                  <EntryForm
                    initialSphereSlug={sphereSlug}
                    parent={state.parent}
                    toConnect={state.toConnect}
                    initialType={state.initialType}
                    onCommit={onCommit}
                    onCancel={onClose}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Scrollbars>
      </Dialog>
    </Transition>
  );
};
