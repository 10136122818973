import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {SphereRoleProvider} from '../context/SphereProvider';
import {usePaginatedEntries} from '../hooks/entries';
import {FeedEntry} from './Entry';
import {LoadingSpinner} from './LoadingSpinner';

interface PaginatedEntryListProps {
  sphereId?: string;
  terminalGoalId?: number;

  includePublic?: boolean;
  createdEntries?: Entry[];
}

export const PaginatedEntryList: React.FC<PaginatedEntryListProps> = ({
  sphereId,
  terminalGoalId,
  includePublic,
  createdEntries,
}) => {
  const {
    entries,
    loadEntries,
    currentPage,
    pages,
    initialLoading,
  } = usePaginatedEntries(sphereId, terminalGoalId, includePublic);

  if (initialLoading) {
    return (
      <div className="py-6 space-y-6">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SphereRoleProvider>
      <InfiniteScroll
        dataLength={entries.length}
        next={() => loadEntries(currentPage + 1)}
        loader={<LoadingSpinner />}
        hasMore={currentPage < pages}
        className="space-y-6 pb-40 md:pb-0"
        scrollableTarget="scrollTarget">
        {createdEntries && (
          <>
            {createdEntries.map((entry) => (
              <FeedEntry
                key={`entry-${entry.id}`}
                entry={entry}
                showSphereName={true}
              />
            ))}
          </>
        )}
        {entries.length > 0 ? (
          entries.map((entry) => (
            <FeedEntry
              key={`entry-${entry.id}`}
              entry={entry}
              showSphereName={true}
            />
          ))
        ) : (
          <p>There are no entries.</p>
        )}
      </InfiniteScroll>
    </SphereRoleProvider>
  );
};
