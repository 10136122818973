import React from 'react';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';

import {useSignedInAuth} from '../context/AuthProvider';
import {deleteSphere} from '../services/sphere';
import {EditSphereForm} from '../components/SphereForm';
import {getSphereRole} from '../utils';
import {Button} from '../components/Button';

export const ManageSphereScreen: React.FC = () => {
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const {slug} = useParams<{slug: string}>();

  const [invitations, setInvitations] = React.useState<Invitation[]>([]);

  const [sphere] = useOutletContext<[AccessibleSphere]>();

  const destroy = async () => {
    if (sphere === null) {
      return;
    }

    if (
      window.confirm(
        'Are you sure you want to delete this sphere? This action is irreversible.',
      )
    ) {
      try {
        await deleteSphere(sphere.id);
        navigate(`/`);
      } catch (e) {
        // TODO: error handling
        alert('Error deleting sphere.');
      }
    }
  };

  const sphereRole = getSphereRole(profile, sphere.id);
  if (sphereRole?.name !== 'Owner' && sphereRole?.name !== 'Moderator') {
    navigate(`/spheres/${slug}`);
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      <h3 className="text-lg mb-4">Sphere Details</h3>
      <EditSphereForm sphere={sphere} />

      {sphereRole.name === 'Owner' && (
        <div>
          <h3 className="text-lg mb-4">Delete Sphere</h3>

          <p className="mb-2">
            Deleting this sphere will remove all associated content. This action
            is irreversible.
          </p>

          <Button type="button" style="problem" onClick={() => destroy()}>
            Delete
          </Button>
        </div>
      )}

      {/* <Section>
          <div className="flex flex-col mb-4">
            <InviteSphereUserForm
              sphere={sphere}
              profile={profile}
              updateInvitations={(newInvitations) => {
                setInvitations(newInvitations);
              }}
            />
          </div>

          <h3 className="text-lg mb-4">Invitations</h3>

          <div className="shadow-md rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Email
                  </th>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Role
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {invitations.map((invitation) => {
                  return (
                    <tr key={`sphere-user-${invitation.id}`}>
                      <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {invitation.email}
                      </td>
                      <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {invitation.role}
                      </td>
                      <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {(sphereRole === 'owner' ||
                          sphereRole === 'moderator') && (
                          <Button
                            type="button"
                            style="problem"
                            onClick={async () => {
                              try {
                                await deleteSphereInvitation(
                                  sphere.id,
                                  invitation.id,
                                );

                                setInvitations(
                                  invitations.filter(
                                    (i) => i.id !== invitation.id,
                                  ),
                                );
                              } catch (e) {
                                // TODO: error handling
                                alert(e);
                              }
                            }}>
                            Cancel
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Section> */}
    </div>
  );
};
