import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Button} from './Button';
import {
  createPaymentMethodToken,
  createSubscription,
  fetchProration,
} from '../services/subscription';
import toast from 'react-hot-toast';
import {useSignedInAuth} from '../context/AuthProvider';
import {AxiosError} from 'axios';

const PaymentMethodForm: React.FC<{
  plan: Plan;
  provideToken: (token: string) => {};
}> = ({plan, provideToken}) => {
  const {profile} = useSignedInAuth();
  const elements = useElements();
  const stripe = useStripe();
  const [error, setError] = React.useState<string | null>(null);

  const [proration, setProration] = React.useState<string | null>(null);

  React.useEffect(() => {
    fetchProration(plan.id).then((res) => {
      if (res.success) {
        setProration(`$${(res.amountDue / 100).toFixed(2)}`);
      }
    });
  }, [profile.subscription]);

  const onSubmit = async () => {
    if (elements === null || stripe === null) {
      return;
    }

    setError(null);

    try {
      if (profile.subscription) {
        provideToken('');
      } else {
        const token = await createPaymentMethodToken(stripe, elements);
        provideToken(token);
      }
    } catch (error) {
      setError(error as string);
    }
  };

  return (
    <>
      <h3 className="text-base font-bold flex flex-row justify-between">
        <span>{plan.name}</span>
        <span>{plan.amount} / mo</span>
      </h3>

      {plan.description && (
        <p
          className="text-sm"
          dangerouslySetInnerHTML={{
            __html: plan.description.replaceAll(/\n/g, '<br />'),
          }}
        />
      )}

      {error && <p className="text-problem text-sm">{error}</p>}

      {profile.subscription === undefined ? (
        <CardElement />
      ) : (
        <p>You will be charged {proration} today.</p>
      )}

      <Button
        type="button"
        style="root"
        onClick={async () => {
          await onSubmit();
        }}>
        Subscribe
      </Button>
    </>
  );
};

export const SubscribeModal: React.FC<{show: boolean; onClose: () => void}> = ({
  show,
  onClose,
}) => {
  const [plan, setPlan] = React.useState<Plan | null>(null);
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
  const {setProfile, profile, plans} = useSignedInAuth();
  const [error, setError] = React.useState<string | null>(null);

  const submitSubscription = async (token: string) => {
    if (plan === null) {
      return;
    }
    setError(null);

    const res = await createSubscription(plan.id, token);
    try {
      if (res.success) {
        toast.success(`You have subscribed to the ${plan.name} plan!`);
        setProfile(res.profile);
        onClose();
      } else {
        setError(res.errors[0].messages[0]);
        // TODO: handle errors
      }
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      setError(error.response?.data.errors[0].messages[0]!);
    }
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        onClose={() => {
          onClose();
        }}
        className="fixed z-50 inset-0">
        <div className="flex min-h-screen md:items-center">
          <div
            className="fixed inset-0 bg-black/30  "
            aria-hidden="true"
            onClick={() => {}}
          />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="flex flex-col w-full min-h-screen space-y-6 p-8 md:min-h-0 relative bg-white md:rounded-lg md:max-w-lg md:mx-auto">
              <button
                className=" absolute right-4 top-4 outline-0"
                onClick={() => onClose()}>
                <FontAwesomeIcon icon="close" className="w-10" />
              </button>

              {error && <p className="text-problem text-sm">{error}</p>}

              {plan ? (
                <Elements stripe={stripe}>
                  <PaymentMethodForm
                    plan={plan}
                    provideToken={submitSubscription}
                  />
                </Elements>
              ) : (
                plans.map((plan) => (
                  <div
                    className="flex flex-col space-y-2"
                    key={`plan-${plan.id}`}>
                    <h3 className="text-base font-bold">{plan.name}</h3>
                    {plan.description && (
                      <p
                        className="text-sm"
                        dangerouslySetInnerHTML={{
                          __html: plan.description.replaceAll(/\n/g, '<br />'),
                        }}
                      />
                    )}

                    <p className="font-bold text-center">{plan.amount} / mo</p>
                    <Button
                      type="button"
                      onClick={() => setPlan(plan)}
                      disabled={profile.subscription?.plan.id === plan.id}>
                      {profile.subscription?.plan.id === plan.id ? (
                        <span>Current Plan</span>
                      ) : (
                        <span>Subscribe to {plan.name}</span>
                      )}
                    </Button>
                  </div>
                ))
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
