import React from 'react';

import {Button} from '../components/Button';
import {
  CreateEntryModal,
  CreateEntryModalState,
} from '../components/CreateEntryModal';
import {useSignedInAuth} from '../context/AuthProvider';
import {PaginatedEntryList} from '../components/PaginatedEntryList';
import {createJoinRequest} from '../services/join_request';
import {useSphere, useSphereRole} from '../context/SphereProvider';
import {LoadingSpinner} from '../components/LoadingSpinner';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useSphereUsers} from '../services/sphere';
import {CollaboratorList} from '../components/CollaboratorList';
import {ComponentBlock} from '../components/ComponentBlock';
import {SphereNavigator} from '../components/SphereNavigator';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Scrollbars from 'react-custom-scrollbars';
import {JoinSphereModalState} from '../components/JoinSphereModal';
import {pluralize} from '../utils';

const AccessibleSphereScreen: React.FC<{sphere: AccessibleSphere}> = ({
  sphere,
}) => {
  const [
    createModalState,
    setCreateModalState,
  ] = React.useState<CreateEntryModalState>({
    open: false,
    sphereId: sphere.slug,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const {getRole, showJoinModal} = useSphereRole();
  const [joinRequest, setJoinRequest] = React.useState<JoinRequest | null>(
    null,
  );

  const {loading: usersLoading, users} = useSphereUsers(sphere.slug);

  return (
    <>
      <div className="flex flex-row lg:space-x-4 h-full lg:items-start lg:justify-between">
        <div className="flex flex-col h-full pt-20">
          <ComponentBlock className="hidden lg:block h-full">
            <CollaboratorList loading={usersLoading} profiles={users} />
          </ComponentBlock>
          <div className="h-10"></div>
        </div>

        <Scrollbars
          height={'100%'}
          className="flex-grow px-0 lg:max-w-3xl"
          renderTrackVertical={({style, ...props}) => (
            <div
              style={{
                ...style,
                right: 2,
                bottom: 2,
                top: 2,
                borderRadius: 3,
                paddingTop: '5rem',
              }}
              {...props}
            />
          )}
          renderView={() => (
            <div
              className="md:-mr-4 pt-20 pr-4"
              style={{
                position: 'absolute',
                inset: '0px',
                overflowY: 'scroll',
              }}
              id="scrollTarget"></div>
          )}>
          <div className="h-full space-y-6">
            {sphere.coverPhoto && (
              <div
                style={{
                  backgroundImage: `url(${sphere.coverPhoto})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: '100% auto',
                }}
                className="bg-gray-200 h-96 rounded-lg relative">
                <div className="absolute bottom-0 left-0 right-0 text-center bg-gray-200/90 text-3xl font-semibold px-4 py-2">
                  {sphere.name}
                </div>
              </div>
            )}

            <div
              className="flex flex-row space-x-2 bg-white border-l-8 border-dark-gray py-6 px-5 rounded-lg items-center cursor-pointer shadow-lg"
              onClick={() =>
                setCreateModalState({...createModalState, open: true})
              }>
              <div className="bg-dark-gray w-8 h-8 flex items-center justify-center rounded-full">
                <FontAwesomeIcon icon="plus" className="text-white" />
              </div>
              <span className="font-bold text-base">Create a new entry</span>
            </div>

            <PaginatedEntryList sphereId={sphere.slug} />
          </div>
        </Scrollbars>

        <SphereNavigator sphere={sphere} />
      </div>

      <CreateEntryModal
        state={createModalState}
        onClose={() => setCreateModalState({...createModalState, open: false})}
        onCommit={(entry) => {
          setCreateModalState({...createModalState, open: false});
          navigate(`/entries/${entry.id}`, {state: {background: location}});
        }}
      />

      <Outlet context={[sphere]} />
    </>
  );
};

const ExclusiveSphereScreen: React.FC<{sphere: AccessibleSphere}> = ({
  sphere,
}) => {
  const {getRole, showJoinModal, getJoinRequest} = useSphereRole();

  return (
    <>
      <div className="flex flex-row lg:space-x-4 h-full lg:items-start lg:justify-between">
        <div className="flex flex-col h-full pt-20">
          <ComponentBlock className="hidden lg:block h-full">
            <p>
              Join {sphere.memberCount}{' '}
              {pluralize('collaborator', sphere.memberCount)} in this exclusive
              sphere.
            </p>
          </ComponentBlock>
          <div className="h-10"></div>
        </div>

        <div className="flex-grow px-0 lg:max-w-3xl pt-20 items-center space-y-6">
          {sphere.coverPhoto && (
            <div
              style={{
                backgroundImage: `url(${sphere.coverPhoto})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: '100% auto',
              }}
              className="bg-gray-200 h-96 rounded-lg relative">
              <div className="absolute bottom-0 left-0 right-0 text-center bg-gray-200/90 text-3xl font-semibold px-4 py-2">
                {sphere.name}
              </div>
            </div>
          )}

          <p>Join this exclusive sphere to collaborate on this topic.</p>

          <Button
            type="button"
            style={
              getJoinRequest(sphere.id) !== undefined
                ? getJoinRequest(sphere.id)?.status === 'pending'
                  ? 'default'
                  : 'problem'
                : 'root'
            }
            disabled={getJoinRequest(sphere.id) !== undefined}
            onClick={async () => {
              showJoinModal(sphere.slug);
            }}>
            {getJoinRequest(sphere.id) !== undefined
              ? getJoinRequest(sphere.id)?.status === 'pending'
                ? 'Pending'
                : 'Rejected'
              : 'Join'}
          </Button>
        </div>

        <SphereNavigator sphere={sphere} />
      </div>

      <Outlet context={[sphere]} />
    </>
  );
};

const RestrictedSphereScreen: React.FC<{sphere: RestrictedSphere}> = ({
  sphere,
}) => {
  return (
    <>
      <div className="flex items-center justify-center pt-20">
        <p>You do not have access to this sphere.</p>
      </div>
    </>
  );
};

export const SphereScreen: React.FC = () => {
  const {sphere, loading} = useSphere()!;
  const {getRole} = useSphereRole();

  if (loading || sphere === null) {
    // TODO: loading spinner?
    return (
      <div className="bg-white px-2 md:pr-10 py-20 space-y-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (!sphere.restricted) {
    if (sphere.exclusive && !getRole(sphere.id)) {
      return <ExclusiveSphereScreen sphere={sphere} />;
    } else {
      return <AccessibleSphereScreen sphere={sphere} />;
    }
  } else {
    return <RestrictedSphereScreen sphere={sphere} />;
  }
};
