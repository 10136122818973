import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {pluralize} from '../../utils';
import {EntryClasses} from '../../utils/entry';

interface EntryContentProps {
  entry: Entry;
  className?: string;
  onClick?: () => void;
}

export const EntryContent: React.FC<EntryContentProps> = ({
  entry,
  className,
  onClick,
}) => {
  return (
    <div
      className={`${
        EntryClasses[entry.type]
      } border-l-4 pt-4 pl-5 pr-5 pb-4 flex flex-col justify-between flex-grow ${className} shadow-sm`}
      style={{minWidth: '20rem'}}
      onClick={onClick}>
      <div className="mb-8 flex justify-between">
        <div className="flex flex-col flex-grow">
          <h3 className="text-gray-900 font-bold text-base">{entry.title}</h3>
          <Scrollbars
            className="flex-grow text-gray-900 text-md whitespace-normal"
            style={{height: '84px'}}>
            {entry.content}
          </Scrollbars>
        </div>
      </div>

      <div className="flex items-center space-x-6">
        <div className="inline-flex items-center border border-gray-300 p-2 space-x-2 divide-x divide-gray-300 bg-white rounded-md text-xs text-gray-600">
          <div className="cursor-pointer">
            <FontAwesomeIcon icon="chevron-up" />
            <span className="ml-1">{entry.upvoteCount}</span>
          </div>

          <div className="pl-2">
            <FontAwesomeIcon icon="chevron-down" />
            <span className="ml-1">{entry.downvoteCount}</span>
          </div>
        </div>

        <div className="text-xs text-gray-500">
          <FontAwesomeIcon icon="eye" />
          <span className="ml-1">
            {entry.viewCount} {pluralize('view', entry.viewCount)}
          </span>
        </div>
      </div>
    </div>
  );
};
