import React from 'react';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAuth} from '../context/AuthProvider';
import {Button} from './Button';
import {InputField} from './Inputs';
import {Alert} from './Alert';

interface SignInFormProps {
  initialEmail?: string;
}

interface SignInFormValues {
  email: string;
  password: string;
}

export const SignInForm: React.FC<SignInFormProps> = ({initialEmail}) => {
  const [error, setError] = React.useState<String | null>(null);
  const {signInEmail} = useAuth();
  const navigate = useNavigate();

  const initialValues: SignInFormValues = {
    email: initialEmail || '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          await signInEmail(values.email, values.password);
          navigate('/');
        } catch (error) {
          setError('Invalid email address or password.');
        }
      }}>
      <Form className="flex flex-col space-y-4">
        {error && <Alert type="error">{error}</Alert>}

        <InputField
          label="Email Address or Username"
          name="email"
          required
          placeholder
          type="email"
        />

        <InputField
          label="Password"
          name="password"
          required
          placeholder
          type="password"
        />

        <Button style="solution" type="submit">
          Sign In
        </Button>
      </Form>
    </Formik>
  );
};
