import React from 'react';

export const ComponentBlock: React.FC<{className?: string}> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`shadow-lg bg-white border border-gray-950 rounded-lg p-8 lg:w-96 ${className}`}>
      {children}
    </div>
  );
};
