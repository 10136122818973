import React from 'react';

interface SectionProps {
  className?: string;
}

export const Section: React.FC<SectionProps> = ({className, children}) => {
  return (
    <div
      className={`bg-white border border-gray-200 shadow-lg p-8 rounded-lg shadow-lg ${className}`}>
      {children}
    </div>
  );
};
