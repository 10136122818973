import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import Scrollbars from 'react-custom-scrollbars';

import globeIcon from '../assets/img/globe.svg';
import {Button} from './Button';
import {createJoinRequest} from '../services/join_request';
import {useSignedInAuth} from '../context/AuthProvider';
import {SubscribeModal} from './SubscribeModal';

export interface JoinSphereModalState {
  open: boolean;
  sphere: Sphere;
}

interface JoinSphereModalProps {
  state: JoinSphereModalState;
  onClose: () => void;
  onCommit: (joinRequest: JoinRequest) => void;
}

export const JoinSphereModal: React.FC<JoinSphereModalProps> = ({
  state,
  onClose,
  onCommit,
}) => {
  const {checkMeteredAction} = useSignedInAuth();
  const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);

  return (
    <Transition show={state.open} as={Fragment}>
      <Dialog onClose={() => onClose()} className="fixed z-10 inset-0">
        <Scrollbars>
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="w-full relative bg-white rounded-lg max-w-lg w-full mx-4 sm:mx-auto">
                <SubscribeModal
                  show={showSubscribeModal}
                  onClose={() => setShowSubscribeModal(false)}
                />

                <div className="flex items-start space-x-4 pt-6 px-6 pb-4">
                  <img src={globeIcon} />

                  <div className="flex-grow">
                    <Dialog.Title className="text-xl leading-10 font-medium text-gray-500">
                      Join This Sphere To Participate
                    </Dialog.Title>

                    <p className="text-gray-900 text-md">
                      If you would like to participant in this sphere you must
                      first join.
                    </p>
                  </div>
                </div>

                <div className="flex justify-end rounded-b-lg bg-gray-50 py-2 px-6 space-x-3">
                  <Button
                    type="button"
                    style="default"
                    onClick={() => onClose()}>
                    Close
                  </Button>

                  <Button
                    type="button"
                    style="root"
                    onClick={async () => {
                      if (
                        state.sphere.exclusive &&
                        !checkMeteredAction('joinExclusiveSphere')
                      ) {
                        setShowSubscribeModal(true);
                      } else {
                        try {
                          const response = await createJoinRequest(
                            state.sphere.slug,
                          );
                          if (response.success) {
                            onCommit(response.joinRequest);
                          } else {
                            // TODO: errors
                            console.log(response.errors);
                          }
                        } catch (e) {
                          // TODO: errors
                          console.log(e);
                        }
                      }
                    }}>
                    Join Sphere
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Scrollbars>
      </Dialog>
    </Transition>
  );
};
