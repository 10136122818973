import React from 'react';
import {listEntries} from '../services/entry';

export const usePaginatedEntries = (
  sphereId?: string,
  terminalGoalId?: Id,
  includePublic?: boolean,
) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [entries, setEntries] = React.useState<Entry[]>([]);
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);

  const loadEntries = async (page: number) => {
    try {
      const response = await listEntries(
        page,
        sphereId,
        terminalGoalId,
        includePublic,
      );
      if (response.success) {
        setCurrentPage(response.currentPage);
        setCount(response.count);
        setPages(response.pages);

        if (initialLoading) {
          setEntries(response.entries);
        } else {
          setEntries([...entries, ...response.entries]);
        }
      } else {
        // TODO: gracefully handle errors
      }
    } catch (e) {
      // TODO: gracefully handle errors
    }
    setInitialLoading(false);
  };

  React.useEffect(() => {
    setCurrentPage(1);
    setPages(0);
    setCount(0);
    setEntries([]);
    setInitialLoading(true);
  }, [includePublic]);

  React.useEffect(() => {
    if (initialLoading) {
      loadEntries(1);
    }
  }, [initialLoading]);

  return {
    loadEntries,
    entries,
    count,
    pages,
    currentPage,
    initialLoading,
  };
};
