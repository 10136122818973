import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Formik} from 'formik';

import logo from '../assets/img/logo-icon.png';
import {useAuth} from '../context/AuthProvider';
import {InputField} from '../components/Inputs';
import {Button} from '../components/Button';

export const ForgotPasswordScreen: React.FC = () => {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const {resetPassword, signedIn} = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (signedIn) {
      navigate('/');
    }
  }, [signedIn]);

  return (
    <div className="max-w-md w-full space-y-8">
      <div>
        <img className="mx-auto h-24 w-auto" src={logo} alt="enolve" />

        <h1 className="mb-2 text-center text-2xl font-bold text-gray-900">
          Reset your password
        </h1>

        <p className="text-center text-sm text-gray-600">
          Or{' '}
          <Link
            to="/"
            className="font-medium text-indigo-600 hover:text-indigo-500">
            sign in to your account
          </Link>
        </p>
      </div>

      <div className="border border-gray-200 shadow-lg p-8 rounded-lg">
        {submitted ? (
          <p>Check your email to reset your password.</p>
        ) : (
          <Formik
            initialValues={{email: ''}}
            onSubmit={async (values) => {
              try {
                await resetPassword(values.email);
              } catch (error) {}

              setSubmitted(true);
            }}>
            <Form className="flex flex-col space-y-4">
              <InputField
                label="Email Address"
                name="email"
                required
                type="email"
              />

              <Button type="submit">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <FontAwesomeIcon icon="envelope" />
                </span>
                Reset Password
              </Button>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};
