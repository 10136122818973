import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Formik, Form} from 'formik';
import axios from 'axios';

import {Alert} from './Alert';
import {Select, SlugInput, SubmitButton, TextArea} from './Inputs';
import {createSphere} from '../services/sphere';
import {useSignedInAuth} from '../context/AuthProvider';
import {ChangeToggle} from './Inputs/Toggle';
import {Modal} from './Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from './Button';
import {SubscribeModal} from './SubscribeModal';

interface CreateSphereValues {
  name: string;
  description: string;
  parentId?: Id;
  public: boolean;
  exclusive: boolean;
}

const initialValues: CreateSphereValues = {
  name: '',
  description: '',
  public: true,
  exclusive: false,
};

export const CreateSphereModal: React.FC = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
  const {reloadProfile, checkMeteredAction} = useSignedInAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {parentName?: string; parentId?: Id};

  const submitForm = async (values: CreateSphereValues) => {
    const action = values.exclusive
      ? 'createExclusiveSphere'
      : 'createPersonalSphere';

    if (values.public || checkMeteredAction(action)) {
      try {
        if (state.parentId !== null) {
          values.parentId = state.parentId;
        }

        const response = await createSphere(values);
        if (response.success) {
          reloadProfile();
          navigate(`/spheres/${response.sphere.slug}`);
        } else {
          setError(response.errors[0].messages[0]);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          console.log(error.response.data);
          setError(error.response.data.errors.messages[0]);
        } else {
          setError('Something went wrong. Please try again later.');
        }
      }
    } else {
      setShowSubscribeModal(true);
    }
  };

  return (
    <Modal dialogClassName="md:py-20">
      <SubscribeModal
        show={showSubscribeModal}
        onClose={() => setShowSubscribeModal(false)}
      />

      <div className="flex flex-row space-x-2 justify-between items-center mb-4">
        <FontAwesomeIcon
          icon="arrow-left"
          className={`cursor-pointer text-lg`}
          onClick={() => {
            location.key !== 'default' ? navigate(-1) : navigate('/');
          }}
        />

        <h2 className="text-xl">Create A Sphere</h2>

        <div></div>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await submitForm(values);
        }}>
        {({values, setFieldValue}) => (
          <Form className="flex flex-col space-y-4">
            {error && <Alert type="error">{error}</Alert>}

            {state.parentName && (
              <Select name="parent" label="Parent" disabled>
                <option>{state.parentName}</option>
              </Select>
            )}

            <SlugInput label="Name" name="name" required />

            <TextArea label="Description" name="description" />

            <div className="flex space-x-2">
              <ChangeToggle
                value={values.public}
                onChange={(value) => setFieldValue('public', value)}
                disabled={values.exclusive}
              />
              <label>Public</label>
            </div>

            <div className="flex space-x-2">
              <ChangeToggle
                value={values.exclusive}
                onChange={(value) => {
                  setFieldValue('exclusive', value);
                  if (value) {
                    setFieldValue('public', true);
                  }
                }}
              />
              <label>Exclusive</label>
            </div>

            <div className="flex justify-end">
              <Button style="root" type="submit">
                Create Sphere
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
