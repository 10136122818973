import React from 'react';

import {Nav} from './Nav';
import {MobileFooter} from './MobileFooter';
import {Outlet} from 'react-router-dom';
import {Toaster} from 'react-hot-toast';

export const UnauthenticatedLayout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
      <Outlet />
    </div>
  );
};

export const Layout: React.FC = () => {
  return (
    <>
      <Toaster />
      <div className="bg-light-gray flex flex-col h-screen bg-white">
        <Nav />

        <div className="flex flex-col flex-1 h-full w-full px-2 md:px-4 justify-between">
          <Outlet />
        </div>

        <MobileFooter />
      </div>
    </>
  );
};
