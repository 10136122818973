import React from 'react';

import {useNav} from '../context/NavProvider';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useSignedInAuth} from '../context/AuthProvider';

import globe from '../assets/img/globe-alt.svg';
import contacts from '../assets/img/contacts.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSphere} from '../context/SphereProvider';
import {CreateEntryModal, CreateEntryModalState} from './CreateEntryModal';
import {ButtonLink} from './Button';

export const MobileFooter: React.FC = () => {
  const {navigatorOpen, navigatorOpenable, setNavigatorOpen} = useNav();
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const sphereProvider = useSphere();

  const [
    createModalState,
    setCreateModalState,
  ] = React.useState<CreateEntryModalState>({
    open: false,
    sphereId: sphereProvider?.sphere?.slug,
  });

  React.useEffect(() => {
    setCreateModalState({
      ...createModalState,
      sphereId: sphereProvider?.sphere?.slug,
    });
  }, [sphereProvider]);

  return (
    <div
      className="flex flex-col lg:hidden fixed bottom-0 bg-white rounded-t-xl w-full"
      style={{
        boxShadow:
          '0 -1px 3px 0 rgb(0 0 0 / 0.1), 0 -1px 2px -1px rgb(0 0 0 / 0.1)',
      }}>
      {navigatorOpen && (
        <div className="border-b py-3 px-10">
          <ButtonLink
            theme="root"
            to="/spheres/new"
            state={{
              parentId: sphereProvider?.sphere?.id,
              parentName: sphereProvider?.sphere?.name,
              background: location,
            }}>
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon="globe" />
              <span>Create Your Own Sphere</span>
            </div>
          </ButtonLink>
        </div>
      )}
      <div
        className={`flex flex-row justify-between items-center py-3 px-10 text-gray-400 font-bold text-xs`}>
        <Link to="/notifications" className="flex flex-col space-y-1">
          <FontAwesomeIcon icon="bell" className="h-8" />
          <span>Updates</span>
        </Link>

        <Link to="/" className="flex flex-col space-y-1">
          <FontAwesomeIcon icon="users" className="h-8" />
          <span>Contacts</span>
        </Link>

        <Link
          to="/"
          className="flex flex-col space-y-1"
          onClick={() => setNavigatorOpen(false)}>
          <FontAwesomeIcon icon="bars" className="h-8" />
          <span>Home</span>
        </Link>

        <a
          className="flex flex-col space-y-1"
          onClick={() => setNavigatorOpen(true)}>
          <img src={globe} alt="Spheres" className="h-8" />
          <span>Spheres</span>
        </a>

        <a
          className="flex flex-col space-y-1 items-center cursor-pointer"
          onClick={() =>
            setCreateModalState({...createModalState, open: true})
          }>
          <FontAwesomeIcon icon="plus" className="h-8" />
          <span>Create</span>
        </a>
      </div>

      <CreateEntryModal
        state={createModalState}
        onClose={() => setCreateModalState({...createModalState, open: false})}
        onCommit={(entry) => {
          setCreateModalState({...createModalState, open: false});
          navigate(`/entries/${entry.id}`, {state: {background: location}});
        }}
      />
    </div>
  );
};
