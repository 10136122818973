import React from 'react';
import {FormikProps, withFormik} from 'formik';
import axios, {AxiosError} from 'axios';

import {Alert} from '../components/Alert';
import {updateSphere} from '../services/sphere';
import {InputField, Select, SubmitButton, TextArea} from './Inputs';
import {CoverPhotoInput} from './Inputs/CoverPhotoInput';
import {IconInput} from './Inputs/IconInput';

interface UpdateSphereValues {
  name: string;
  description: string | null;
  coverPhotoSrc: string | null;
  iconSrc: string | null;
  entryDisplayBehavior:
    | 'display_only_self'
    | 'display_direct'
    | 'display_nested';
}

const EditSphereFormInner: React.FC<FormikProps<UpdateSphereValues>> = (
  props,
) => {
  const {status, handleSubmit, values} = props;

  return (
    <div>
      {!!status && <Alert type={status.type}>{status.message}</Alert>}

      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <InputField
          label="Name"
          name="name"
          required
          type="text"
          disabled={true}
        />

        <TextArea label="Description" name="description" />

        <CoverPhotoInput coverPhoto={values.coverPhotoSrc} name="coverPhoto" />

        <IconInput icon={values.iconSrc} name="icon" />

        <Select label="Entry Display Behavior" name="entryDisplayBehavior">
          <option value="display_only_self">Only This Sphere</option>
          <option value="display_direct">Public Child Spheres</option>
          <option value="display_nested" disabled>
            All Nested Public Child Spheres (coming soon)
          </option>
        </Select>

        <div className="flex justify-end">
          <SubmitButton>Update Sphere</SubmitButton>
        </div>
      </form>
    </div>
  );
};

interface EditSphereFormProps {
  sphere: AccessibleSphere;
}

export const EditSphereForm = withFormik<
  EditSphereFormProps,
  UpdateSphereValues
>({
  mapPropsToValues: ({sphere}) => ({
    name: sphere.name,
    description: sphere.description,
    coverPhotoSrc: sphere.coverPhoto,
    iconSrc: sphere.icon,
    entryDisplayBehavior: sphere.entryDisplayBehavior,
  }),
  handleSubmit: async (values, {setStatus, props: {sphere}, setFieldError}) => {
    setStatus(null);

    try {
      const response = await updateSphere(sphere.id, values);
      if (response.success) {
        setStatus({
          type: 'success',
          message: 'Your sphere settings have been updated.',
        });
      } else {
        response.errors.forEach((error) => {
          setFieldError(error.field, error.messages[0]);
        });
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errors = (e as AxiosError<ErrorResponse>).response?.data.errors;
        errors?.forEach((error) => {
          setFieldError(error.field, error.messages[0]);
        });
      }
    }
  },
})(EditSphereFormInner);
