import React from 'react';

import {pluralize, uppercaseFirstLetter} from '../../utils';
import {EntryLabels} from '../../utils/entry';
import {Avatar} from '../Avatar';

interface EntryContentProps {
  entry: Entry;
}

export const EntryAuthor: React.FC<EntryContentProps> = ({entry}) => {
  return (
    <div className="flex items-center justify-between space-x-2 md:space-x-4">
      <div className="flex flex-col flex-grow">
        <div className="text-base text-gray-900">
          <span>{EntryLabels[entry.type]} published by</span>{' '}
          <span className="font-bold">{entry.author.username}</span>
        </div>
        <span className="text-sm text-gray-500">
          {entry.createdAt} - {entry.viewCount}{' '}
          {pluralize('view', entry.viewCount)}
        </span>
      </div>

      <Avatar size="md" profile={entry.author} />
    </div>
  );
};
