import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';

import logoBlack from '../assets/img/logo-black.svg';
import {fetchEntryConnection} from '../services/entry';
import {LoadingSpinner} from './LoadingSpinner';
import {FeedEntry} from './Entry';
import {Avatar} from './Avatar';
import {Modal} from './Modal';

type LinkedEntryConnection = {
  id: number;
  parent: Entry;
  child: Entry;
  user: Profile;
};

export const ConnectionScreen = () => {
  const [
    connection,
    setConnection,
  ] = React.useState<LinkedEntryConnection | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams<{id: string}>();

  React.useEffect(() => {
    setConnection(null);
    fetchEntryConnection(parseInt(id!)).then((res) => {
      if (res.success) {
        setConnection(res.entryConnection);
      }
    });
  }, [id]);

  if (connection === null) {
    return (
      <div className="bg-white px-2 md:pr-10 py-10 space-y-8">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex-col bg-white h-screen">
        <nav className="z-50">
          <div className="flex items-center justify-between h-16 px-2 md:px-4 space-x-2 sm:space-x-3 mb-6">
            <FontAwesomeIcon
              icon="arrow-left"
              className={`cursor-pointer text-lg`}
              onClick={() => {
                location.key !== 'default' ? navigate(-1) : navigate('/');
              }}
            />

            <Link to="/">
              <img
                className="h-10 w-auto m-auto"
                src={logoBlack}
                alt="enolve"
              />
            </Link>

            <div></div>
          </div>

          <div className="flex flex-col items-center pb-8 px-4 space-y-6 border-b">
            <h2 className="font-medium text-gray text-xl md:text-4xl">
              Connection Inspector
            </h2>

            <div className="flex items-center space-x-4 mb-2 mx-auto">
              <div className="flex flex-col text-gray-900 text-sm text-right">
                <div>
                  Connection made by{' '}
                  <span className="font-semibold">
                    {connection.user.username}
                  </span>
                </div>
              </div>

              <Avatar size="md" profile={connection.user} />
            </div>
          </div>
        </nav>

        <div className="flex flex-col md:px-8 py-5 space-y-6">
          <FeedEntry entry={connection.parent} />
          <FeedEntry entry={connection.child} />
        </div>
      </div>
    </>
  );
};

export const ConnectionModal: React.FC = () => {
  return (
    <Modal className="p-0">
      <ConnectionScreen />
    </Modal>
  );
};
