import React from 'react';

interface AlertProps {
  type: 'error' | 'success' | 'info';
}

const typeToColor = {
  error: 'bg-red-100 border-red-400 text-red-700',
  success: 'bg-green-100 border-green-400 text-green-700',
  info: 'bg-blue-100 border-blue-400 text-blue-700',
};

export const Alert: React.FC<AlertProps> = ({type, children}) => {
  return (
    <div
      className={`${typeToColor[type]} border mb-4 px-4 py-3 rounded`}
      role="alert">
      {children}
    </div>
  );
};
