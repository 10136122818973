import React from 'react';
import * as Yup from 'yup';

import {InputField, TextArea} from './Inputs';
import {Alert} from './Alert';
import {Button} from './Button';
import {Form, FormikProps, withFormik, WithFormikConfig} from 'formik';
import {AvatarInput} from './Inputs/AvatarInput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface ProfileFormValues {
  tagline: string;
  description: string;
  username: string;
  firstName: string;
  lastName: string;
}

interface ProfileFormProps {
  submitText: string;
  error: string | null;
  initialValues: ProfileFormValues;
  profile: CurrentProfile | null;
}

const Inner: React.FC<ProfileFormProps & FormikProps<ProfileFormValues>> = ({
  isSubmitting,
  error,
  submitText,
  profile,
}) => {
  return (
    <Form className="flex flex-col space-y-4">
      {error && <Alert type="error">{error}</Alert>}

      <InputField
        label="Username"
        name="username"
        required
        placeholder
        type="text"
      />

      <div className="relative flex flex-col justify-end p-4 bg-gray-50 h-80 space-y-4">
        <AvatarInput name="photo" />

        <div className="flex flex-row space-x-4">
          <InputField
            label="First Name"
            name="firstName"
            required
            placeholder
            type="text"
          />

          <InputField
            label="Last Name"
            name="lastName"
            required
            placeholder
            type="text"
          />
        </div>

        <InputField label="Tagline" name="tagline" placeholder type="text" />

        <FontAwesomeIcon
          icon="edit"
          className="w-10 absolute top-0 right-0 cursor-pointer pointer-events-none"
        />
      </div>

      <TextArea label="Bio / Desription" name="description" placeholder />

      <div className="flex justify-end">
        <Button disabled={isSubmitting} type="submit" style="root">
          {submitText}
        </Button>
      </div>
    </Form>
  );
};

export const ProfileSchema = Yup.object().shape({
  tagline: Yup.string().nullable(),
  description: Yup.string().nullable(),
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  username: Yup.string(),
});

export const ProfileForm = (
  config: WithFormikConfig<ProfileFormProps, ProfileFormValues>,
) =>
  withFormik<ProfileFormProps, ProfileFormValues>({
    ...config,
    validationSchema: ProfileSchema,
  })(Inner);
