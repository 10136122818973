import React from 'react';
import {debounce} from 'lodash';

import terminalGoalsIcon from '../assets/img/terminal-goals-icon.svg';
import problemIcon from '../assets/img/problem-icon.svg';
import solutionIcon from '../assets/img/solution-icon.svg';
import {pluralize} from '.';
import {searchEntries} from '../services/entry';

interface EntrySearchProps {
  sphereSlug: string;
  types?: EntryType | EntryType[];
}

export const useEntrySearch = ({sphereSlug, types}: EntrySearchProps) => {
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState<Entry[]>([]);

  const search = React.useMemo(
    () =>
      debounce(async (parentId: Id, query: string) => {
        setLoading(true);
        setResults([]);

        const normalizedQuery = query.trim();
        if (normalizedQuery.length > 0) {
          try {
            const response = await searchEntries(
              sphereSlug,
              normalizedQuery,
              types,
            );
            if (response.success) {
              setResults(
                response.entries.filter((entry) => entry.id !== parentId),
              );
            }
          } catch (error) {
            // TODO: handle errors
            console.log(error);
            setResults([]);
          } finally {
            setLoading(false);
          }
        }
      }, 500),
    [types],
  );

  return {loading, results, search};
};

export const EntryIcons: {[key in EntryType]: string} = {
  solution: solutionIcon,
  problem: problemIcon,
  terminal_goal: terminalGoalsIcon,
};

export const EntryLabels: {[key in EntryType]: string} = {
  solution: 'Solution',
  problem: 'Problem',
  terminal_goal: 'Value Goal',
};

export const EntryCreateTitleTip: {[key in EntryType]: string} = {
  solution: 'Make your summary sound "actionable"',
  problem: 'Make your summary sound "problematic"',
  terminal_goal:
    'Value goals are desirable outcomes, often lofty and intangible',
};

export const EntryCreateTitlePlaceholder: {[key in EntryType]: string} = {
  solution: 'Write a concise title to summarize your solution...',
  problem: 'Write a concise title to summarize your problem...',
  terminal_goal: 'Write a concise title to summarize your value goal...',
};

export const EntryCreateBodyTip: {[key in EntryType]: string} = {
  solution:
    'Good solutions are S.M.A.R.T: specific, measurable, actionable, and can be completed in a realistic timeframe.',
  problem: 'Big Problems can be broken down into smaler ones.',
  terminal_goal:
    'Value goals are desirable outcomes, often lofty and intangible.',
};

export const EntryCreateBodyPlaceholder: {[key in EntryType]: string} = {
  solution: 'Describe your system or method in more detail',
  problem: 'Describe your difficulties in more detail',
  terminal_goal: 'Describe what you want or need in more detail',
};

export const EntryClasses: {[key in EntryType]: string} = {
  solution: 'bg-solution-light border-solution',
  problem: 'bg-problem-light border-problem',
  terminal_goal: 'bg-terminal-goal-light border-terminal-goal',
};

export const EntryBgDarkClasses: {[key in EntryType]: string} = {
  solution: 'bg-solution',
  problem: 'bg-problem',
  terminal_goal: 'bg-terminal-goal',
};

export const EntryBorders: {[key in EntryType]: string} = {
  solution: 'border-solution',
  problem: 'border-problem',
  terminal_goal: 'border-terminal-goal',
};

export const EntryChildrenLabels: {
  [key in EntryType]: {
    [key in EntryType | 'root_cause' | 'root_cause_problem']: (
      count: number,
    ) => string;
  };
} = {
  solution: {
    solution: (count) => '',
    problem: (count) => `${count} ${pluralize('Downside', count)}`,
    terminal_goal: (count) => `${count} ${pluralize('Value Goal', count)}`,
    root_cause: (count) => '',
    root_cause_problem: (count) => '',
  },
  problem: {
    solution: (count) => `${count} ${pluralize('Solution', count)}`,
    problem: (count) => `${count} ${pluralize('Downside', count)}`,
    terminal_goal: (count) => `${count} ${pluralize('Value Goal', count)}`,
    root_cause: (count) => '',
    root_cause_problem: (count) => '',
  },
  terminal_goal: {
    solution: (count) => `${count} ${pluralize('Solution', count)}`,
    problem: (count) => `${count} ${pluralize('Obstacle', count)}`,
    terminal_goal: (count) => `${count} ${pluralize('Specific Need', count)}`,
    root_cause: (count) => `${count} ${pluralize('Greater Value', count)}`,
    root_cause_problem: (count) => `${count} ${pluralize('Downside', count)}`,
  },
};

export const EntryChildrenGuidingQuestions: {
  [key in EntryType]: {
    [key in EntryType | 'root_cause' | 'root_cause_problem']: string | null;
  };
} = {
  solution: {
    solution: null,
    terminal_goal: 'What can be achieved with this solution?',
    problem: 'What are the issues & criticisms of this solution?',
    root_cause: null,
    root_cause_problem: null,
  },
  problem: {
    solution: null,
    terminal_goal: 'What can be achieved by solving this problem?',
    problem: 'What bad things happen if this problem persists?',
    root_cause: 'What created this problem in the first place?',
    root_cause_problem: null,
  },
  terminal_goal: {
    solution: 'How to achieve this value goal?',
    terminal_goal: 'Value goals can be broken down into smaller goals.',
    problem: 'What prevents us from achieving this goal?',
    root_cause: 'What higher, abstract values inform this goal?',
    root_cause_problem: 'What are the issues & criticisms of this goal?',
  },
};

export const EntryChildrenCreateLabels: {
  [key in EntryType]: {
    [key in EntryType | 'root_cause' | 'root_cause_problem']: string;
  };
} = {
  solution: {
    solution: 'ERROR',
    problem: 'Negative Consequence or Issue',
    terminal_goal: 'Value Goal',
    root_cause: 'ERROR',
    root_cause_problem: 'ERROR',
  },
  problem: {
    solution: 'Solution',
    problem: 'Negative Consequence',
    terminal_goal: 'Value Goal',
    root_cause: 'ERROR',
    root_cause_problem: 'ERROR',
  },
  terminal_goal: {
    solution: 'Solution',
    problem: 'Problem',
    terminal_goal: 'Specific Need',
    root_cause: 'Greater Value',
    root_cause_problem: 'Downside',
  },
};
