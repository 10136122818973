import {Base} from './base';

interface CurrentProfileResponse {
  success: true;
  profile: CurrentProfile;
}

interface ProfileResponse {
  success: true;
  profile: Profile;
}

export interface ProfileInput {
  description: string;
  tagline: string;
}

export const fetchProfile = async (slug: string) =>
  (await Base.get<ProfileResponse>(`/profiles/${slug}`)).data;

export const fetchCurrentProfile = async () =>
  (await Base.get<CurrentProfileResponse>('/current/profile')).data;

export const createProfile = async (profile: ProfileInput) =>
  (
    await Base.post<CurrentProfileResponse>('current/profile', {
      profile,
    })
  ).data;

export const updateProfile = async (profile: ProfileInput) =>
  (
    await Base.patch<CurrentProfileResponse>('current/profile', {
      profile,
    })
  ).data;
