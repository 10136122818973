import firebase from 'firebase/app';

export const APP_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://app.enolve.com'
    : 'https://enolve-staging.web.app';
export const API_BASE_URL =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:5000'
    : process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://api.enolve.com'
    : 'https://enolve-api-staging.herokuapp.com';
export const NO_AVATAR_URL =
  'https://storage.googleapis.com/assets.enolve.com/user.svg';

export const FirebaseApp =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? firebase.initializeApp({
        apiKey: 'AIzaSyBaJ8a978QXMjmq6pAbuU-NEBj-88PvJM0',
        authDomain: 'enolve.firebaseapp.com',
        projectId: 'enolve',
        storageBucket: 'enolve.appspot.com',
        messagingSenderId: '147598920877',
        appId: '1:147598920877:web:8e3b39b26d7d28cd85ca24',
        measurementId: 'G-TLY8V32H6X',
      })
    : firebase.initializeApp({
        apiKey: 'AIzaSyD2OsRtupwQUC4n1lSTCTmBo2H2mpz4dqg',
        authDomain: 'enolve-staging.firebaseapp.com',
        projectId: 'enolve-staging',
        storageBucket: 'enolve-staging.appspot.com',
        messagingSenderId: '480175010293',
        appId: '1:480175010293:web:fa4017aa2d82d6499e70bc',
      });
