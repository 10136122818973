import {Base} from './base';

interface JoinRequestResponse {
  success: true;
  joinRequest: JoinRequest;
}

interface ApprovedJoinRequestResponse {
  success: true;
  joinRequest: ApprovedJoinRequest;
}

export const createJoinRequest = async (sphereSlug: string) =>
  (
    await Base.post<
      JoinRequestResponse | ApprovedJoinRequestResponse | ErrorResponse
    >(`/spheres/${sphereSlug}/join_requests`)
  ).data;
