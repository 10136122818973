import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Menu, Transition} from '@headlessui/react';
import React from 'react';
import {useNavigate} from 'react-router';

import {useSignedInAuth} from '../../context/AuthProvider';
import {useSphereRole} from '../../context/SphereProvider';
import {deleteEntry} from '../../services/entry';

interface EntryContextMenuProps {
  entry: Entry;
  onEdit: () => void;
}

export const EntryContextMenu: React.FC<EntryContextMenuProps> = ({
  entry,
  onEdit,
}) => {
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const {getRole: sphereRole} = useSphereRole();

  const destroy = async () => {
    try {
      await deleteEntry(entry.sphereSlug, entry.id);
      navigate(`/spheres/${entry.sphereSlug}`);
    } catch (e) {
      // TODO: error handling
      alert('Error deleting entry.');
    }
  };

  if (
    sphereRole(entry.sphereId)?.canModerateContent ||
    (sphereRole(entry.sphereId)?.canModerateOwnContent &&
      profile.id === entry.author.id)
  ) {
    return (
      <Menu as="div" className="relative">
        <Menu.Button className="p-2 mr-2 cursor-pointer">
          <FontAwesomeIcon icon="ellipsis-v" />
        </Menu.Button>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {() => (
                  <button
                    className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                    onClick={() => {
                      onEdit();
                    }}>
                    <FontAwesomeIcon icon="edit" className="w-5 h-5 mr-2" />
                    Edit
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {() => (
                  <button
                    className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                    onClick={() => {
                      destroy();
                    }}>
                    <FontAwesomeIcon icon="times" className="w-5 h-5 mr-2" />
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  } else {
    return null;
  }
};
