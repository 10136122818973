import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Avatar} from './Avatar';
import {useSignedInAuth} from '../context/AuthProvider';

import logo from '../assets/img/logo-black.svg';
import {SearchBar} from './SearchBar';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const Nav = () => {
  const {signOut, profile} = useSignedInAuth();

  return (
    <nav className="bg-white shadow fixed left-0 right-0 top-0 z-40">
      <div className="flex items-center justify-between h-16 px-2 md:px-4">
        <div className="flex items-center md:static space-x-2 sm:space-x-3">
          {/* Profile dropdown */}
          <Menu as="div" className="relative z-50">
            {({open}) => (
              <>
                <div>
                  <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    <Avatar profile={profile} size="sm" />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items
                    static
                    className="flex flex-col origin-top-left absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({active}) => (
                        <Link
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          )}
                          to={`/p/${profile.username}`}>
                          Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({active}) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-left text-gray-700',
                          )}
                          onClick={async () => {
                            await signOut();
                          }}>
                          Sign Out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>

          <Link
            to="/notifications"
            className="relative items-center justify-center bg-root-dark text-white h-8 w-8 rounded-full hidden md:flex">
            <FontAwesomeIcon
              icon="bell"
              className="h-4 w-4"
              aria-hidden="true"
            />
          </Link>
        </div>

        <Link to="/" className="fixed md:absolute left-1/2 -translate-x-1/2">
          <img className="h-8 w-auto m-auto" src={logo} alt="enolve" />
        </Link>

        {/* <SearchBar /> */}
      </div>
    </nav>
  );
};
