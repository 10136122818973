import React from 'react';
import {Dialog, Transition} from '@headlessui/react';
import Scrollbars from 'react-custom-scrollbars';
import {Form, Formik} from 'formik';
import {Button} from './Button';
import {InputField, TextArea} from './Inputs';
import {Alert} from './Alert';
import axios from 'axios';
import {createPlan} from '../services/plan';

interface CreatePlanModalProps {
  open: boolean;
  onClose: () => void;
  onCommit: (plan: Plan) => void;
}

interface PlanFormValues {
  name: string;
  description: string;
  amount: string;
}

const initialValues: PlanFormValues = {
  name: '',
  description: '',
  amount: '',
};

export const CreatePlanModal: React.FC<CreatePlanModalProps> = ({
  open,
  onClose,
  onCommit,
}) => {
  const [error, setError] = React.useState<string | null>(null);

  const handleSubmit = async (values: PlanFormValues) => {
    setError(null);

    try {
      const response = await createPlan(values);
      if (response.success) {
        onCommit(response.plan);
      } else {
        // TODO: better error handling
        console.log(response.errors);
        setError(response.errors[0].messages[0]);
      }
    } catch (error) {
      // TODO: better error handling
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.errors.messages[0]);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog onClose={() => onClose()} className="fixed z-10 inset-0">
        <Scrollbars>
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="w-full relative bg-white rounded-lg max-w-lg w-full mx-4 sm:mx-auto">
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  <Form>
                    <div className="flex items-start space-x-4 pt-6 px-6 pb-4">
                      <div className="flex-grow">
                        <Dialog.Title className="text-lg mb-2">
                          New Plan
                        </Dialog.Title>

                        {error !== null && <Alert type="error">{error}</Alert>}

                        <InputField label="Name" name="name" type="text" />
                        <TextArea label="Description" name="description" />

                        <div className="flex flex-row items-center mt-4 space-x-2">
                          <span>$</span>
                          <InputField
                            placeholder
                            label="Amount (37.00)"
                            name="amount"
                            type="text"
                            containerClassName="flex-grow"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end rounded-b-lg bg-gray-50 py-2 px-6 space-x-3">
                      <Button
                        type="button"
                        style="default"
                        onClick={() => onClose()}>
                        Close
                      </Button>

                      <Button type="submit" style="root">
                        Create Plan
                      </Button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Scrollbars>
      </Dialog>
    </Transition>
  );
};
