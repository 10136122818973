import {Stripe, StripeElements} from '@stripe/stripe-js';
import {Base} from './base';

interface SubscriptionResponse {
  success: true;
  profile: CurrentProfile;
}

export const createSubscription = async (planId: Id, cardToken: string) =>
  (
    await Base.post<SubscriptionResponse | ErrorResponse>(
      '/current/subscription',
      {planId, cardToken},
    )
  ).data;

export const unsubscrtibe = async () =>
  (
    await Base.delete<SubscriptionResponse | ErrorResponse>(
      '/current/subscription',
    )
  ).data;

export const fetchProration = async (planId: Id) =>
  (
    await Base.get<{amountDue: number; success: true} | ErrorResponse>(
      `/current/subscription/proration?plan_id=${planId}`,
    )
  ).data;

export const updatePaymentMethod = async (cardToken: string) =>
  (
    await Base.patch<SubscriptionResponse | ErrorResponse>(
      '/current/payment_method',
      {cardToken},
    )
  ).data;

export const createPaymentMethodToken = async (
  stripe: Stripe,
  elements: StripeElements,
) => {
  const {error: submitError} = await elements.submit();
  if (submitError) {
    throw submitError;
  }

  const cardElement = elements.getElement('card');
  if (cardElement === null) {
    throw 'No card element';
  }

  const res = await stripe.createToken(cardElement);
  if (res.error) {
    throw res.error.message;
  }

  return res.token.id;
};
