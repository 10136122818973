import React from 'react';

interface AvatarProps {
  profile: Profile;
  src?: string;
  size: 'sm' | 'md' | 'lg';
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  profile,
  size,
  src,
  className,
}) => {
  const sizes = {
    sm: 'h-8 w-8',
    md: 'h-10 w-10',
    lg: 'h-12 w-12',
  };

  return (
    <>
      <img
        className={`${sizes[size]} rounded-full bg-white ${className || ''}`}
        src={src || profile?.photo}
        alt="Profile Photo"
      />
    </>
  );
};
