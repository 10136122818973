import React from 'react';
import {useField} from 'formik';
import {DirectUpload} from '@rails/activestorage';

import {useSignedInAuth} from '../../context/AuthProvider';
import {API_BASE_URL, NO_AVATAR_URL} from '../../config';

interface AvatarInputProps {
  name: string;
}

export const AvatarInput: React.FC<AvatarInputProps> = ({name}) => {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const [field, meta, {setValue}] = useField(name);
  const {profile} = useSignedInAuth();
  const [src, setSrc] = React.useState(profile?.fullPhoto || NO_AVATAR_URL);

  // TODO: make this happen at the end of the form somehow so we don't just spam upload blobs
  const uploadBlob = () => {
    if (fileInput.current === null || fileInput.current.files === null) {
      return;
    }

    const file = fileInput.current.files[0];
    setSrc(URL.createObjectURL(file));

    const upload = new DirectUpload(
      file,
      `${API_BASE_URL}/rails/active_storage/direct_uploads`,
    );
    upload.create((error, blob) => {
      if (error === null) {
        setValue(blob.signed_id);
      } else {
        // TODO: handle errors
      }
    });
  };

  return (
    <>
      <button
        className="absolute flex inset-0 items-center justify-center"
        type="button"
        onClick={() => {
          if (fileInput.current !== null) {
            fileInput.current.click();
          }
        }}>
        {src === NO_AVATAR_URL ? (
          <img src={NO_AVATAR_URL} className="w-12 h-12" />
        ) : (
          <img src={src} className="max-w-full h-full" />
        )}
      </button>

      <input
        style={{display: 'none'}}
        type="file"
        ref={fileInput}
        onChange={(event) => {
          uploadBlob();
        }}
      />
    </>
  );
};
