import {Base} from './base';

type FollowResponse = {
  success: boolean;
  profile: CurrentProfile;
};

export const followProfile = async (profileId: Id) =>
  (await Base.post<FollowResponse>(`/profiles/${profileId}/followings`)).data;

export const unfollowProfile = async (profileId: Id, followId: Id) =>
  (
    await Base.delete<FollowResponse>(
      `/profiles/${profileId}/followings/${followId}`,
    )
  ).data;

export const isFollowing = (currentProfile: CurrentProfile, profile: Profile) =>
  currentProfile.followings.find((f) => f.id === profile.id) !== undefined;
