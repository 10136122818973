import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {useLocation, useNavigate} from 'react-router-dom';
import {SubscribeModal} from './SubscribeModal';

interface ModalProps {
  className?: string;
  dialogClassName?: string;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  dialogClassName,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as {background?: string};

  React.useEffect(() => {
    if (!isOpen) {
      if (state.background) {
        navigate(state.background);
      }
    }
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => setIsOpen(false)}
        className={`fixed z-40 inset-0 ${dialogClassName}`}>
        <div
          className="fixed inset-0 bg-black/30"
          aria-hidden="true"
          onClick={() => setIsOpen(false)}
        />

        <div className="flex md:items-center h-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div
              className="flex flex-col w-full h-full relative bg-white md:max-w-4xl md:mx-auto"
              style={{boxShadow: '0 5px 15px 5px rgba(0,0,0,.25)'}}>
              <Scrollbars height={'100%'}>
                <div className={`${className ? className : 'p-6'}`}>
                  {children}
                </div>
              </Scrollbars>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
