import React from 'react';
import {Link, useParams} from 'react-router-dom';

import {fetchProfile} from '../services/profile';
import {LoadingSpinner} from '../components/LoadingSpinner';
import {useSignedInAuth} from '../context/AuthProvider';
import {NO_AVATAR_URL} from '../config';
import {Section} from '../components/Section';
import {Button} from '../components/Button';
import {followProfile, unfollowProfile} from '../services/follow';

export const ProfileScreen: React.FC = () => {
  const [profile, setProfile] = React.useState<Profile | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const {
    profile: currentProfile,
    setProfile: setCurrentProfile,
  } = useSignedInAuth();
  const {pslug} = useParams<{pslug: string}>();

  React.useEffect(() => {
    setLoading(true);
    fetchProfile(pslug as string)
      .then((response) => {
        setProfile(response.profile);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [pslug]);

  if (loading || profile === null) {
    return (
      <div className="bg-white px-2 md:pr-10 py-20 space-y-8">
        <LoadingSpinner />
      </div>
    );
  }

  const following = currentProfile.followings.find((f) => f.id === profile.id);

  return (
    <div className="flex flex-col w-full max-w-md mx-auto pt-20">
      <Section>
        {profile.fullPhoto !== NO_AVATAR_URL && (
          <div className="flex flex-row justify-center mb-4">
            <img src={profile.fullPhoto} className="h-1/2" />
          </div>
        )}

        <div className="mb-4">
          <h2 className="text-xl">{profile.username}</h2>
          {currentProfile.id === profile.id && (
            <Link to="/profile/edit" className="text-root">
              Edit
            </Link>
          )}
        </div>

        <p className="text-lg">{profile.tagline}</p>
        <p className="mb-4">{profile.description}</p>

        {profile.id !== currentProfile.id && (
          <Button
            type="button"
            style={following !== undefined ? 'problem' : 'root'}
            onClick={async () => {
              try {
                if (following !== undefined) {
                  const response = await unfollowProfile(
                    profile.id,
                    following.followId,
                  );
                  setCurrentProfile(response.profile);
                } else {
                  const response = await followProfile(profile.id);
                  setCurrentProfile(response.profile);
                }
              } catch (err) {
                console.log(err);
              }
            }}>
            {following !== undefined ? 'Unfollow' : 'Follow'}
          </Button>
        )}
      </Section>
    </div>
  );
};
