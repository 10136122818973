import firebase from 'firebase/app';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Base} from '../services/base';
import * as ProfileService from '../services/profile';
import {APP_BASE_URL, FirebaseApp} from '../config';
import {AuthFormState} from '../screens/AuthScreen';
import {listPlans} from '../services/plan';

interface AuthContextData {
  signedIn: boolean;
  onboarded: boolean;
  firebaseUser: firebase.User | null;
  profile: CurrentProfile | null;
  createProfile: (profile: ProfileService.ProfileInput) => Promise<Profile>;
  updateProfile: (profile: ProfileService.ProfileInput) => Promise<Profile>;
  setProfile: (profile: CurrentProfile) => void;
  resetPassword: (email: string) => Promise<void>;
  signInEmail: (email: string, password: string) => Promise<void>;
  signInFacebook: () => Promise<void>;
  signInGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  setSphereRole: (sphereId: Id, role: SphereAccessRole | undefined) => void;
  plans: Plan[];
  showSubscriptionModal: () => void;
  hideSubscriptionModal: () => void;
}

interface SignedInContextData extends AuthContextData {
  signedIn: true;
  firebaseUser: firebase.User;
  profile: CurrentProfile;
  reloadProfile: () => Promise<void>;
  checkMeteredAction: (action: MeteredAction) => boolean;
}

type MeteredAction =
  | 'createPersonalSphere'
  | 'personalSphereCollaborators'
  | 'createExclusiveSphere'
  | 'joinExclusiveSphere';

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

let interceptorId: number | null = null;

export const AuthProvider: React.FC = ({children}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<firebase.User | null>(
    FirebaseApp.auth().currentUser,
  );
  const [profile, setProfile] = React.useState<CurrentProfile | null>(null);
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [showSubscriptionModal, setShowSubscriptionModal] = React.useState(
    false,
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    FirebaseApp.auth().onAuthStateChanged((user) => {
      setLoading(true);
      setUser(user);

      if (interceptorId !== null) {
        Base.interceptors.request.eject(interceptorId);
      }

      interceptorId = Base.interceptors.request.use(async (req) => {
        if (user !== null) {
          req.headers.Authorization = `Bearer ${await user.getIdToken()}`;
        }
        return req;
      });

      if (user) {
        ProfileService.fetchCurrentProfile()
          .then((data) => {
            if (data.success) {
              setProfile(data.profile);
            } else {
              setProfile(null);
            }
          })
          .catch(() => {
            setProfile(null);
            navigate('/auth/profile/create');
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setProfile(null);
        setLoading(false);
        navigate('/auth');
      }
    });
  }, [navigate]);

  const checkMeteredAction = React.useCallback(
    (action: MeteredAction) => {
      if (profile === null) {
        return false;
      }

      const usage = profile.subscription?.usage || {
        createPersonalSphere: 0,
        personalSphereCollaborators: 0,
        createExclusiveSphere: 0,
        joinExclusiveSphere: 0,
      };

      const plan: Plan = profile.subscription?.plan || {
        id: 0,
        amount: 0,
        name: 'Free',
        description: 'free',
        createPersonalSphere: 1,
        personalSphereCollaborators: 1,
        createExclusiveSphere: 0,
        joinExclusiveSphere: 0,
      };

      if (plan[action] !== null && usage[action] + 1 >= plan[action]!) {
        return false;
      } else {
        return true;
      }
    },
    [profile?.subscription, plans],
  );

  React.useEffect(() => {
    listPlans('enolve').then((response) => {
      if (response.success) {
        setPlans(response.plans);
      } else {
        // TODO: handle errors
      }
    });
  }, []);

  if (loading === true) {
    // TODO: maybe a loading spinner?
    return <div></div>;
  }

  const resetPassword = async (email: string) => {
    try {
      await FirebaseApp.auth().sendPasswordResetEmail(email, {
        url: `${APP_BASE_URL}`,
      });
    } catch (error) {
      throw error;
    }
  };

  const signUpEmail = async (email: string, password: string) => {
    try {
      await FirebaseApp.auth().createUserWithEmailAndPassword(email, password);
    } catch (error) {
      throw error;
    }
  };

  const signInFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');

    try {
      const result = await FirebaseApp.auth().signInWithPopup(provider);
      await FirebaseApp.auth().updateCurrentUser(result.user);
    } catch (error) {
      throw error;
    }
  };

  const signInGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('openid');

    try {
      const result = await FirebaseApp.auth().signInWithPopup(provider);
      await FirebaseApp.auth().updateCurrentUser(result.user);

      try {
        const response = await ProfileService.fetchCurrentProfile();
        setProfile(response.profile);
      } catch (error) {
        console.log(result);
        if (result.additionalUserInfo?.profile) {
          let {email, family_name, given_name, id, picture} = result
            .additionalUserInfo.profile as {
            email: string;
            family_name: string;
            given_name: string;
            id: string;
            picture: string;
          };

          navigate('/auth', {
            state: {
              action: AuthFormState.SignUp,
              initialValues: {
                firstName: given_name,
                lastName: family_name,
                email,
                password: '',
                username: '',
              },
            },
          });
        } else {
          navigate('/auth', {state: {action: AuthFormState.SignUp}});
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const signInEmail = async (email: string, password: string) => {
    try {
      const result = await FirebaseApp.auth().signInWithEmailAndPassword(
        email,
        password,
      );
      await firebase.auth().updateCurrentUser(result.user);
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    await FirebaseApp.auth().signOut();
  };

  const createProfile = async (profile: ProfileService.ProfileInput) => {
    const response = await ProfileService.createProfile(profile);
    setProfile(response.profile);
    return response.profile;
  };

  const updateProfile = async (profile: ProfileService.ProfileInput) => {
    const response = await ProfileService.updateProfile(profile);
    setProfile(response.profile);
    return response.profile;
  };

  const reloadProfile = async () => {
    setLoading(true);
    ProfileService.fetchCurrentProfile()
      .then((data) => {
        if (data.success) {
          setProfile(data.profile);
        } else {
          setProfile(null);
        }
      })
      .catch(() => {
        setProfile(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setSphereRole = (sphereId: Id, role: SphereAccessRole | undefined) => {
    if (profile === null) {
      return;
    }

    if (role === undefined) {
      setProfile({
        ...profile,
        spheres: [
          ...profile.spheres.filter((role) => role.resourceId !== sphereId),
        ],
      });
    } else {
      setProfile({
        ...profile,
        spheres: [...profile.spheres, role],
      });
    }
  };

  const value = {
    signedIn: user !== null,
    onboarded: profile !== null,
    firebaseUser: user,
    profile,
    reloadProfile,
    resetPassword,
    createProfile,
    updateProfile,
    signUpEmail,
    signInFacebook,
    signInGoogle,
    signInEmail,
    signOut,
    setSphereRole,
    setProfile,
    plans,
    showSubscriptionModal: () => {
      setShowSubscriptionModal(true);
    },
    hideSubscriptionModal: () => {
      setShowSubscriptionModal(false);
    },
    checkMeteredAction,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      {/* <SubscribeModal show={showSubscriptionModal} /> */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be within AuthProvider');
  }

  return context;
};

export const useSignedInAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useSignedInAuth must be within AuthProvider');
  } else if (context.signedIn === false) {
    throw new Error('useSignedInAuth used when not signed in');
  }

  return context as SignedInContextData;
};
