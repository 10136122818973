import debounce from 'debounce';
import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import {deleteRole, updateRole, UpdateRoleInput} from '../services/role';
import {Button} from './Button';
import {InputClassName} from './Inputs';
import {ChangeToggle} from './Inputs/Toggle';

interface EditableRoleList {
  sphere: AccessibleSphere;
}

export const EditableRoleList: React.FC<EditableRoleList> = ({sphere}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [roles, setRoles] = React.useState<AccessRole[]>(sphere.roles);
  const [selectedRole, setSelectedRole] = React.useState<AccessRole>(roles[0]);
  const [newSelectedRoleName, setNewSelectedRoleName] = React.useState<string>(
    roles[0].name,
  );

  React.useEffect(() => {
    setRoles(sphere.roles);

    let newSelectedRole: AccessRole | undefined = undefined;
    if (location.hash) {
      newSelectedRole = sphere.roles.find(
        (r) => `#${r.name}` === location.hash,
      );
    }

    if (newSelectedRole !== undefined) {
      setSelectedRole(newSelectedRole);
    } else {
      setSelectedRole(sphere.roles[0]);
    }
  }, [sphere.id]);

  React.useEffect(() => {
    if (roles.length !== sphere.roles.length) {
      setRoles(sphere.roles);
      setSelectedRole(sphere.roles[sphere.roles.length - 1]);
    }
  }, [sphere.roles.length]);

  React.useEffect(() => {
    if (`'#${selectedRole.name}` !== location.hash) {
      navigate(`#${selectedRole.name}`);
    }

    setNewSelectedRoleName(selectedRole.name);
  }, [selectedRole]);

  React.useEffect(() => {
    debouncedNameUpdate(newSelectedRoleName);
  }, [newSelectedRoleName]);

  const debouncedNameUpdate = React.useMemo(
    () =>
      debounce(async (name: string) => {
        if (!selectedRole.permanent) {
          await updateSphereRole({name});
        }
      }, 300),
    [],
  );

  const updateSphereRole = async (input: UpdateRoleInput) => {
    try {
      const response = await updateRole(sphere.slug, selectedRole.id, input);
      if (response.success) {
        const newRoles = roles.map((role) => {
          if (role.id === response.role.id) {
            return response.role;
          }

          return role;
        });
        setRoles(newRoles);

        const newSelectedRole = newRoles.find((r) => r.id === selectedRole.id);
        if (newSelectedRole !== undefined) {
          setSelectedRole(newSelectedRole);
        }
      } else {
        // TODO: add error handling
        console.log(response.errors);
      }
    } catch (e) {
      // TODO: add error handling
      console.log(e);
    }
  };

  const deleteSphereRole = async (id: Id) => {
    try {
      await deleteRole(sphere.slug, id);

      const newRoles = roles.filter((r) => r.id !== id);
      setRoles(newRoles);
      setSelectedRole(newRoles[newRoles.length - 1]);
    } catch (e) {
      // TODO: add error handling
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col space-y-4 md:flex-row md:space-y-0">
      <div className="flex flex-col divide-y w-full md:w-1/3">
        {roles.map((role, i) => (
          <div
            className={`flex justify-between p-2 cursor-pointer ${
              selectedRole.id == role.id && 'bg-root text-white'
            }`}
            key={`role-${i}`}
            onClick={() => setSelectedRole(role)}>
            <span>{role.name}</span>
            <span
              className={`${
                selectedRole?.id == role.id
                  ? 'text-gray-100 font-mono'
                  : 'text-gray-400 font-mono'
              }`}>
              ({sphere.roles.find((r) => r.id === role.id)?.usersCount})
            </span>
          </div>
        ))}
      </div>

      <div className="flex flex-col w-full md:w-2/3 px-4 divide-y -mt-2">
        {!selectedRole.permanent && (
          <div className="flex flex-col space-y-1 py-2">
            <h3 className="font-bold">Name</h3>
            <input
              className={`${InputClassName} w-full`}
              type="text"
              value={newSelectedRoleName}
              onChange={(evt) => {
                setNewSelectedRoleName(evt.target.value);
              }}
            />
          </div>
        )}

        <div className="flex flex-row justify-between items-start space-x-2 py-2">
          <div>
            <h3 className="font-bold">Manage Own Content</h3>
            <p>Allows members to edit and delete content they have posted.</p>
          </div>

          <ChangeToggle
            value={selectedRole.canModerateOwnContent}
            onChange={async (value) =>
              await updateSphereRole({canModerateOwnContent: value})
            }
            disabled={selectedRole.name === 'Visitor'}
          />
        </div>

        <div className="flex flex-row justify-between items-start space-x-2 py-2">
          <div>
            <h3 className="font-bold">Manage Content</h3>
            <p>Allows users to manage any content in the sphere.</p>
          </div>

          <ChangeToggle
            value={selectedRole.canModerateContent}
            onChange={async (value) =>
              await updateSphereRole({canModerateContent: value})
            }
            disabled={selectedRole.name === 'Visitor'}
          />
        </div>

        <div className="flex flex-row justify-between items-start space-x-2 py-2">
          <div>
            <h3 className="font-bold">Create Public Spheres</h3>
            <p>Allows users to create public subspheres.</p>
          </div>

          <ChangeToggle
            value={selectedRole.canCreatePublicSpheres}
            onChange={async (value) =>
              await updateSphereRole({canCreatePublicSpheres: value})
            }
            disabled={selectedRole.name === 'Visitor'}
          />
        </div>

        <div className="flex flex-row justify-between items-start space-x-2 py-2">
          <div>
            <h3 className="font-bold">Create Private Spheres</h3>
            <p>Allows users to create private subspheres.</p>
          </div>

          <ChangeToggle
            value={selectedRole.canCreatePrivateSpheres}
            onChange={async (value) =>
              await updateSphereRole({canCreatePrivateSpheres: value})
            }
            disabled={selectedRole.name === 'Visitor'}
          />
        </div>

        <div className="flex py-2 justify-end">
          {!selectedRole.permanent && (
            <Button
              type="button"
              style="problem"
              onClick={async () => {
                if (
                  sphere.roles.find((r) => r.id === selectedRole.id)
                    ?.usersCount !== 0
                ) {
                  window.alert(
                    'You can not delete a role with users assigned to it.',
                  );
                } else if (
                  window.confirm('Are you sure you want to delete this role?')
                ) {
                  await deleteSphereRole(selectedRole.id);
                }
              }}>
              Delete Role
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
