export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const pluralize = (str: string, count: number) => {
  if (count > 1 || count === 0) {
    if (str[str.length - 1] === 'y') {
      return str.substring(0, str.length - 1) + 'ies';
    } else {
      return str + 's';
    }
  }

  return str;
};

interface WindowWithGTag extends Window {
  gtag: (...args: any) => void;
}

const getWindowWithGTag = () => {
  return (window as unknown) as WindowWithGTag;
};

export const configureGTag = () => {
  getWindowWithGTag().gtag(
    'config',
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    {
      send_page_view: false,
    },
  );
};

export const trackPageView = () => {
  getWindowWithGTag().gtag('event', 'page_view', {
    page_location: window.location.origin + window.location.pathname,
    page_path: window.location.pathname,
    page_title: document.title,
  });
};

export const getSphereRole = (profile: Profile, sphereId: Id) => {
  return profile.spheres.find((sphereUser) => {
    return sphereUser.resourceId === sphereId;
  });
};
