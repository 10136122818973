import React from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import {useSignedInAuth} from '../context/AuthProvider';
import {getSphereRole} from '../utils';
import {ChangeToggle} from '../components/Inputs/Toggle';
import {updateSphere} from '../services/sphere';

export const ManageSubspheresScreen: React.FC = () => {
  const {profile} = useSignedInAuth();
  const navigate = useNavigate();
  const [sphere] = useOutletContext<[AccessibleSphere]>();

  const sphereRole = getSphereRole(profile, sphere.id);
  if (sphereRole?.name !== 'Owner' && sphereRole?.name !== 'Moderator') {
    navigate(`/spheres/${sphere.id}`);
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
              Sphere
            </th>
            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
              Featured
            </th>
          </tr>
        </thead>

        <tbody>
          {sphere.children.map((child) => (
            <tr key={sphere.id}>
              <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                {child.name}
              </td>
              <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                <ChangeToggle
                  value={child.featured}
                  onChange={async (value) =>
                    await updateSphere(child.id, {featured: value})
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
