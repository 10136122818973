import React from 'react';
import {ThreeDots} from 'react-loading-icons';

interface LoadingSpinnerProps {
  width?: string | number;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({width}) => {
  return (
    <div className="flex items-center justify-center">
      <ThreeDots fill="#f6f6f6" stroke="#9ca3af" width={width} />
    </div>
  );
};
