import React from 'react';

import {
  JoinSphereModal,
  JoinSphereModalState,
} from '../components/JoinSphereModal';
import {LoadingSpinner} from '../components/LoadingSpinner';
import {fetchSphere} from '../services/sphere';
import {getSphereRole} from '../utils';
import {useSignedInAuth} from './AuthProvider';
import {useParams} from 'react-router-dom';

interface SphereRoleContextData {
  getRole: (sphereId: Id) => SphereAccessRole | undefined;
  getJoinRequest: (sphereId: Id) => JoinRequest | undefined;
  showJoinModal: (sphereSlug: string) => void;
}

const SphereRoleContext = React.createContext<SphereRoleContextData>(
  {} as SphereRoleContextData,
);

export const SphereRoleProvider: React.FC<{
  onJoin?: (role: AccessRole) => void;
}> = ({children, onJoin}) => {
  const {profile, setSphereRole, setProfile} = useSignedInAuth();
  const sphereProvider = useSphere();

  const [
    joinModalState,
    setJoinModalState,
  ] = React.useState<JoinSphereModalState>({
    open: false,
    sphere: sphereProvider?.sphere!,
  });

  return (
    <SphereRoleContext.Provider
      value={{
        getRole: (sphereId) => getSphereRole(profile, sphereId),
        showJoinModal: (sphereSlug: string) => {
          setJoinModalState({
            ...joinModalState,
            sphere: sphereProvider?.sphere!,
            open: true,
          });
        },
        getJoinRequest: (sphereId) =>
          profile.joinRequests.find((jr) => jr.sphereId === sphereId),
      }}>
      {children}

      <JoinSphereModal
        state={joinModalState}
        onClose={() => {
          setJoinModalState({...joinModalState, open: false});
        }}
        onCommit={(joinRequest) => {
          const sphereRoles = profile.spheres;
          if (joinRequest.status === 'approved') {
            const role = (joinRequest as ApprovedJoinRequest).role;
            sphereRoles.push(role);
            if (onJoin !== undefined) {
              onJoin(role);
            }
          }
          setProfile({
            ...profile,
            spheres: sphereRoles,
            joinRequests: profile.joinRequests.concat([joinRequest]),
          });

          setJoinModalState({...joinModalState, open: false});
        }}
      />
    </SphereRoleContext.Provider>
  );
};

interface SphereContextData {
  sphere: AccessibleSphere | RestrictedSphere | null;
  loading: boolean;
}

const SphereContext = React.createContext<SphereContextData>(
  {} as SphereContextData,
);

export const SphereProvider: React.FC = ({children}) => {
  const [sphere, setSphere] = React.useState<
    AccessibleSphere | RestrictedSphere | null
  >(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const {slug} = useParams<{slug: string}>();

  React.useEffect(() => {
    if (slug !== undefined) {
      fetchSphere(slug)
        .then((response) => {
          setLoading(false);

          if (response.success) {
            setSphere(response.sphere);
          } else {
            // TOOD: handle errors
            console.log(response.errors);
            setSphere(null);
          }
        })
        .catch(() => {
          setSphere(null);
        });
    } else {
      setLoading(false);
      setSphere(null);
    }
  }, [slug]);

  if (loading) {
    return (
      <div className="py-20">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SphereContext.Provider
      value={{
        sphere,
        loading,
      }}>
      <SphereRoleProvider onJoin={() => setSphere(sphere)}>
        {children}
      </SphereRoleProvider>
    </SphereContext.Provider>
  );
};

export const useSphere = () => {
  const context = React.useContext(SphereContext);
  if (context === undefined) {
    return null;
  }

  return context;
};

export const useSphereRole = () => {
  const context = React.useContext(SphereRoleContext);
  if (context === undefined) {
    throw new Error('useSphereRole must be within SphereRoleProvider');
  }

  return context;
};
