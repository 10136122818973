import React from 'react';
import {useField, useFormikContext} from 'formik';
import slugify from 'slugify';

import {Button} from '../Button';
import {APP_BASE_URL} from '../../config';

interface InputFieldProps {
  label: string;
  name: string;
  type: string;
  placeholder?: boolean;
  required?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  className?: string;
}

export const InputClassName =
  'appearance-none resize-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm';

export const InputField: React.FC<InputFieldProps> = ({
  label,
  containerClassName,
  className,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  const htmlPlaceholder = placeholder ? label : undefined;

  return (
    <div className={containerClassName}>
      {!placeholder && (
        <label className="font-medium text-gray-900">{label}</label>
      )}
      <input
        {...field}
        {...props}
        placeholder={htmlPlaceholder}
        className={`${InputClassName} ${className}`}
      />

      {meta.touched && meta.error ? (
        <div className="text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const SelectClassName =
  'rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm';

interface SelectProps {
  label?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<SelectProps> = ({
  label,
  containerClassName,
  children,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  return (
    <div className={containerClassName}>
      <label className="font-medium text-gray-900">
        {label}

        <select {...field} {...props} className={SelectClassName}>
          {children}
        </select>
      </label>

      {meta.touched && meta.error ? (
        <div className="text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
};

interface TextAreaProps {
  label?: string;
  autofocus?: boolean;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: boolean;
  containerClassName?: string;
  className?: string;
  labelClassName?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  autofocus,
  label,
  containerClassName,
  className,
  labelClassName,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  return (
    <div className={containerClassName}>
      {label ? (
        <label className={`font-medium text-gray-900 ${labelClassName}`}>
          {!placeholder && label}

          <textarea
            {...field}
            {...props}
            autoFocus
            className={`${InputClassName} ${className}`}
            placeholder={placeholder ? label : undefined}
          />
        </label>
      ) : (
        <textarea
          {...field}
          {...props}
          className={`${InputClassName} ${className}`}
          placeholder={placeholder ? label : undefined}
        />
      )}

      {meta.touched && meta.error ? (
        <div className="text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
};

interface SubmitButtonProps {}

export const SubmitButton: React.FC<SubmitButtonProps> = ({children}) => {
  const {isSubmitting} = useFormikContext();

  return (
    <Button type="submit" disabled={isSubmitting}>
      {children}
    </Button>
  );
};

interface ToggleProps {
  containerClassName?: string;
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
  rightValue: string;
  name: string;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <div
      className={`flex justify-between items-center ${props.containerClassName}`}>
      <div
        className="cursor-pointer"
        onClick={() => helpers.setValue(props.leftValue)}>
        {props.leftLabel}
      </div>

      <div
        className="cursor-pointer"
        onClick={() => helpers.setValue(props.rightValue)}>
        {props.rightLabel}
      </div>
    </div>
  );
};

interface CheckBoxInputProps {
  className?: string;
  label: string;
  labelClasses?: string;
  name: string;
  value: string;
}

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  className,
  label,
  labelClasses,
  ...props
}) => {
  const [field, meta] = useField({
    type: 'checkbox',
    ...props,
  });

  return (
    <div className={className}>
      <label className={`flex items-center space-x-2 ${labelClasses}`}>
        <input type="checkbox" {...field} />
        <span>{label}</span>
      </label>
    </div>
  );
};

interface SlugInputProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  containerClassName?: string;
}

export const SlugInput: React.FC<SlugInputProps> = ({
  label,
  containerClassName,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  return (
    <div className={containerClassName}>
      <label className="font-medium text-gray-900">{label}</label>

      <div className="flex flex-col space-y-2">
        <input {...field} {...props} className={InputClassName} />

        <input
          disabled={true}
          className={InputClassName}
          value={`${APP_BASE_URL}/spheres/${slugify(field.value)}`}
        />
      </div>

      {meta.touched && meta.error ? (
        <div className="text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
};
