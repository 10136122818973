import React from 'react';
import {useField} from 'formik';
import {DirectUpload} from '@rails/activestorage';

import {Button} from '../Button';
import {API_BASE_URL} from '../../config';

interface IconInput {
  name: string;
  icon: string | null;
}

export const IconInput: React.FC<IconInput> = ({name, icon}) => {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const [field, meta, {setValue}] = useField(name);
  const [src, setSrc] = React.useState<string | null>(icon);

  // TODO: make this happen at the end of the form somehow so we don't just spam upload blobs
  const uploadBlob = () => {
    if (fileInput.current === null || fileInput.current.files === null) {
      return;
    }

    const file = fileInput.current.files[0];
    setSrc(URL.createObjectURL(file));

    const upload = new DirectUpload(
      file,
      `${API_BASE_URL}/rails/active_storage/direct_uploads`,
    );
    upload.create((error, blob) => {
      if (error === null) {
        setValue(blob.signed_id);
      } else {
        // TODO: handle errors
      }
    });
  };

  return (
    <div className="flex flex-col space-y-2 items-start">
      <Button
        type="button"
        onClick={() => {
          if (fileInput.current !== null) {
            fileInput.current.click();
          }
        }}>
        Upload Icon
      </Button>

      {src !== null && (
        <div
          style={{backgroundImage: `url(${src})`}}
          className="w-6 h-6 bg-cover bg-center"></div>
      )}

      <input
        style={{display: 'none'}}
        type="file"
        ref={fileInput}
        onChange={(event) => {
          uploadBlob();
        }}
      />
    </div>
  );
};
